import { Close, Save } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Autocomplete,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import { User, Version } from "../@types/service";

export const EditableConceptSetTable = (props: {
  conceptSets: {
    id?: number;
    name: string;
    version?: Version;
    availableVersions?: Version[];
    created?: Date;
    createdBy?: User;
  }[];
  saveFunction: (oldId: number, newId: number | undefined) => void;
}) => {
  const { conceptSets, saveFunction } = props;
  const [editing, setEditing] = useState<Map<number, boolean>>(new Map());
  const [versionUpdateMap, setVersionUpdateMap] = useState<Map<number, number>>(
    new Map(),
  );

  function toggleEditing(id: number | undefined) {
    if (id) {
      const copy = new Map(editing);
      const current = copy.get(id);
      if (current) {
        copy.set(id, false);
      } else {
        copy.set(id, true);
      }
      setEditing(copy);
    }
  }

  function setUpdateVersion(oldId: number, newId: number) {
    const copy = new Map<number, number>(versionUpdateMap);
    copy.set(oldId, newId);
    setVersionUpdateMap(copy);
  }

  return (
    <TableContainer style={{ paddingBottom: "2em" }}>
      <Table
        sx={{
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "rgba(224, 224, 224, 1)",
          borderRadius: 1,
        }}
      >
        <TableHead>
          <TableCell width={50} padding={"checkbox"} />
          <TableCell>name</TableCell>
          <TableCell size={"small"} padding={"none"}>
            version
          </TableCell>
          <TableCell size={"small"} padding={"none"} align={"left"} />
          <TableCell>created by</TableCell>
          <TableCell>created date</TableCell>
        </TableHead>
        <TableBody>
          {conceptSets?.sort((a, b) => {
              if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
              if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
              return 0;
            })
            .map(
              (row) =>
                row.id && (
                  <TableRow
                    key={row.id}
                    sx={{ "&:last-child td, &:last-child": { border: 0 } }}
                  >
                    <TableCell align="left" width={70}>
                      <IconButton
                        component={Link}
                        to={`/concept-sets/${row.id}`}
                        key={row.id + "icon-button"}
                      >
                        <VisibilityIcon
                          key={row.id + "visibility-icon"}
                          style={{ color: "#004494" }}
                        />
                      </IconButton>
                    </TableCell>
                    <TableCell align="left">{row.name}</TableCell>
                    <TableCell
                      size={"small"}
                      padding={"none"}
                      align="left"
                      style={{ color: row.version ? "inherit" : "#F29527" }}
                    >
                      {editing.get(row.id) ? (
                        <div style={{ marginRight: "1em" }}>
                          <Autocomplete
                            fullWidth
                            style={{ minWidth: "12em" }}
                            size={"small"}
                            options={
                              row.availableVersions &&
                              row.availableVersions.length > 0
                                ? row.availableVersions
                                : [
                                    {
                                      id: 0,
                                      parentId: 0,
                                      name: "No versions available",
                                      version: "No versions available",
                                      versionDescription:
                                        "No versions available",
                                      createdBy: "",
                                      created: new Date(),
                                      expression: { items: [] },
                                    },
                                  ]
                            }
                            getOptionLabel={(o) => o.version}
                            getOptionDisabled={() =>
                              !row.availableVersions ||
                              row.availableVersions.length === 0
                            }
                            onChange={(e, item) => {
                              if (item && row.id) {
                                setUpdateVersion(row.id, item.id);
                              }
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Select version" />
                            )}
                          />
                        </div>
                      ) : row.version ? (
                        row.version.version
                      ) : (
                        "Not specified"
                      )}
                    </TableCell>
                    <TableCell size={"small"} padding={"none"} align={"left"}>
                      {editing.get(row.id) ? (
                        <div className={"Icons-wrapper__align"}>
                          <IconButton
                            size={"small"}
                            onClick={() => toggleEditing(row.id)}
                            style={{ marginRight: "1em" }}
                          >
                            <Close color={"primary"} style={{ fontSize: 18 }} />
                          </IconButton>
                          <IconButton
                            size={"small"}
                            onClick={() => {
                              const newId =
                                row.id && versionUpdateMap.get(row.id);
                              if (row.id) {
                                saveFunction(row.id, newId);
                                toggleEditing(row.id);
                              }
                            }}
                          >
                            <Save color={"primary"} style={{ fontSize: 18 }} />
                          </IconButton>{" "}
                        </div>
                      ) : (
                        <IconButton
                          size={"small"}
                          style={{ marginRight: "5em" }}
                          onClick={() => toggleEditing(row.id)}
                        >
                          <EditIcon
                            color={"primary"}
                            style={{ fontSize: 18 }}
                          />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell align="left">
                      {row.version
                        ? row.version.createdBy
                        : row.createdBy?.name}
                    </TableCell>
                    <TableCell align="left">
                      {new Date(
                        row.version
                          ? row.version.created
                          : row.created
                          ? row.created
                          : new Date(),
                      ).toLocaleDateString()}
                    </TableCell>
                  </TableRow>
                ),
            )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
