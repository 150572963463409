import { Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { useContext, useState } from "react";
import ReactMarkdown from "react-markdown";

import { ClinicalDefinition } from "../../../@types/service";
import { AppContext, AppContextProps } from "../../../context/AppContext";
import { updatePhenotype } from "../../../service/PhenotypeService";

export default function ClinicalDescriptors(props: {
  phenotype: ClinicalDefinition;
  setPhenotype: (p: ClinicalDefinition) => void;
}) {
  const { phenotype, setPhenotype } = props;
  const [editing, setEditing] = useState(false);
  const { doAlert, deckClient } = useContext(AppContext) as AppContextProps;
  const [value, setValue] = useState(phenotype.clinicalDescriptors);

  function save() {
    if (deckClient) {
      phenotype.clinicalDescriptors = value;
      updatePhenotype(phenotype, deckClient)
        .then((r) => {
          setPhenotype(r);
          setValue(r.clinicalDescriptors);
          setEditing(false);
        })
        .catch(() => {
          doAlert("error", "failed to update phenotype");
          setEditing(false);
        });
    }
  }

  function cancel() {
    setValue(phenotype.clinicalDescriptors);
    setEditing(false);
  }

  return (
    <div>
      <div style={{ margin: "1em" }}>
        {editing ? (
          <div>
            <TextField
              multiline
              value={value}
              sx={{ width: "65vw", marginTop: "1em" }}
              onChange={(e) => setValue(e.target.value)}
            />
            <div>
              <Button
                variant={"outlined"}
                style={{ marginRight: "2em" }}
                onClick={save}
              >
                SAVE
              </Button>
              <Button
                style={{ margin: "1em" }}
                variant={"outlined"}
                onClick={cancel}
              >
                CANCEL
              </Button>
            </div>
          </div>
        ) : (
          <div>
            <ReactMarkdown>
              {phenotype.clinicalDescriptors
                ? phenotype.clinicalDescriptors
                : "No clinical descriptors provided"}
            </ReactMarkdown>
            <Button
              variant={"outlined"}
              style={{ marginRight: "2em" }}
              onClick={() => setEditing(true)}
            >
              EDIT
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
