import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";

interface TickProps {
  ticked: boolean;
  colored?: boolean;
}

function Tick(props: TickProps) {
  const { ticked, colored } = props;
  const color = colored ? "success" : "inherit";
  return ticked ? <CheckIcon color={color} /> : <CloseIcon />;
}

export default Tick;
