import React, { useContext, useState } from "react";

import { CohortDefinition } from "../../../@types/service";
import DateBlock from "../../../components/DateBlock";
import EditableOverviewField from "../../../components/EditableOverviewField";
import { AppContext, AppContextProps } from "../../../context/AppContext";
import { updateCohortDefinition } from "../../../service/CohortDefinitionService";

export default function Overview(props: { cd: CohortDefinition }) {
  const { cd } = props;
  const { doAlert, deckClient } = useContext(AppContext) as AppContextProps;
  const [cohortDefinition, setCohortDefinition] = useState(cd);

  function saveName(value: string | undefined) {
    saveField("name", value);
  }

  function saveDescription(value: string | undefined) {
    saveField("description", value);
  }

  function saveField(
    field: "name" | "description" | "url",
    newValue: string | undefined,
  ) {
    if (deckClient) {
      const copy = cohortDefinition;
      copy[field] = newValue ? newValue : "";
      updateCohortDefinition(copy, deckClient)
        .then(() => {
          doAlert("success", `Updated ${field}`);
          setCohortDefinition(copy);
        })
        .catch(() => doAlert("error", `Failed to update ${field}`));
    }
  }

  return (
    <div>
      <EditableOverviewField
        label={"ID"}
        originalValue={cohortDefinition.id?.toString()}
        persist={saveName}
        isEditable={true}
      />
      <EditableOverviewField
        label={"Name"}
        originalValue={cohortDefinition.name}
        persist={saveName}
        isEditable={true}
      />
      <EditableOverviewField
        label={"Description"}
        originalValue={cohortDefinition.description}
        persist={saveDescription}
        isEditable={true}
      />
      <div style={{ padding: "1em" }}>
        <b>Created by</b>
        <div style={{ paddingTop: "0.5em" }}>
          {cohortDefinition.createdBy?.name}
        </div>
      </div>
      <DateBlock label={"Created on"} date={cohortDefinition.created} />
      <DateBlock label={"Last modified"} date={cohortDefinition.modified} />
      <hr />
    </div>
  );
}
