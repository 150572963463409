import EditIcon from "@mui/icons-material/Edit";
import { Autocomplete, Button, IconButton, Tooltip } from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { useState } from "react";

export default function EditableListOverviewField(props: {
  isEditable: boolean;
  label: string;
  originalValue: string[];
  persist: (value: string[]) => void;
}) {
  const { label, persist, originalValue, isEditable } = props;
  const [value, setValue] = useState(originalValue);
  const [editing, setEditing] = useState(false);

  function save() {
    persist(value);
    setEditing(false);
  }

  function cancel() {
    setValue(originalValue);
    setEditing(false);
  }

  return (
    <div style={{ margin: "1em" }}>
      <div className={"Icons-wrapper__align"}>
        <b style={{ minWidth: "9%" }}>{label}</b>&emsp;
        {isEditable && (
          <Tooltip title={`edit ${label.toLowerCase()}`} placement={"top"}>
            <IconButton size={"small"} onClick={() => setEditing(true)}>
              <EditIcon fontSize={"small"} color={"primary"} />
            </IconButton>
          </Tooltip>
        )}
      </div>
      {editing ? (
        <div>
          <Autocomplete
            multiple
            options={originalValue ? originalValue : [""]}
            freeSolo
            value={value}
            onChange={(e, items) => {
              if (items) {
                setValue([...items]);
              }
            }}
            renderInput={(params) => <TextField {...params} />}
          />
          <div>
            <Button
              variant={"outlined"}
              style={{ marginRight: "2em" }}
              onClick={save}
            >
              SAVE
            </Button>
            <Button
              style={{ margin: "1em" }}
              variant={"outlined"}
              onClick={cancel}
            >
              CANCEL
            </Button>
          </div>
        </div>
      ) : (
        <div style={{ paddingTop: "0.5em" }}>{value?.join(", ")}</div>
      )}
    </div>
  );
}
