import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import CommentIcon from "@mui/icons-material/Comment";
import DifferenceIcon from "@mui/icons-material/Difference";
import EditIcon from "@mui/icons-material/Edit";
import MergeIcon from "@mui/icons-material/Merge";
import { Grid, Tooltip } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import React, { useContext, useEffect, useState } from "react";

import {
  ConceptSet,
  ConceptSetItem,
  ReviewConflict,
  Task,
} from "../../../@types/service";
import ButtonWithIcon from "../../../components/ButtonWithIcon";
import Tick from "../../../components/Tick";
import { AppContext, AppContextProps } from "../../../context/AppContext";
import {
  fetchReviewComparison,
  fetchReviews,
  mergeReviewIntoCurrent,
  updateField,
} from "../../../service/ConceptSetService";
import ReviewRequest from "../ReviewRequest";
import Comment from "./ConceptComment";

interface ReviewProps {
  conceptSet: ConceptSet;
}

export default function Reviews(props: ReviewProps) {
  const { conceptSet } = props;
  const [reviewRequestOpen, setReviewRequestOpen] = useState(false);
  const { doAlert, deckClient } = useContext(AppContext) as AppContextProps;
  const [pendingReviews, setPendingReviews] = useState<Task[]>([]);
  const [completedReviews, setCompletedReviews] = useState<Task[]>([]);
  const [checkedBoxes, setCheckedBoxes] = useState<number[]>([]);
  const [conflicts, setConflicts] = useState<ReviewConflict[]>([]);
  const [editing, setEditing] = useState<Map<number, boolean>>(new Map());
  const [writingComment, setWritingComment] = useState<boolean>(false);
  const [selectedConcept, setSelectedConcept] = useState<
    ConceptSetItem | undefined
  >(undefined);

  useEffect(() => {
    if (deckClient) {
      fetchReviews(conceptSet.id, deckClient)
        .then((r) => {
          const _pendingReviews = [...r.tasks].filter(
            (r) => r.status === "OPEN" || r.status === "UNDER_REVIEW",
          );
          setPendingReviews(_pendingReviews);
          const _completedReviews = [...r.tasks].filter(
            (r) => r.status === "CLOSED",
          );
          setCompletedReviews(_completedReviews);
        })
        .catch(() => doAlert("error", "failed to load reviews"));
    }
  }, [reviewRequestOpen]);

  function doReviewRequest() {
    setReviewRequestOpen(true);
  }

  const setFlavour = (
    item: ConceptSetItem,
    flavour: string,
    value: boolean,
  ) => {
    const _copy = conflicts.map((c) => {
      if (c.current.id === item.id) {
        c.current.flavours[flavour] = value;
      }
      return c;
    });
    setField(item, flavour, value, "flavours");
    setConflicts(_copy);
  };

  const setField = (
    item: ConceptSetItem,
    flavour: string,
    value: boolean,
    kind: "flags" | "flavours" | "tags",
  ) => {
    if (deckClient) {
      updateField(conceptSet.id, item.id, flavour, value, kind, deckClient)
        .then(() => {})
        .catch(() => {
          doAlert("error", `Failed to update ${item.concept.CONCEPT_NAME}`);
        });
    }
  };

  function toggleEditing(id: number) {
    const copy = new Map(editing);
    const current = copy.get(id);
    if (current) {
      copy.set(id, false);
    } else {
      copy.set(id, true);
    }
    setEditing(copy);
  }

  const createComment = (item: ConceptSetItem) => {
    setSelectedConcept(item);
    setWritingComment(true);
  };

  function compareReviews() {
    if (deckClient) {
      fetchReviewComparison(conceptSet.id, checkedBoxes, deckClient)
        .then((r) => setConflicts(r))
        .catch();
    }
  }

  function mergeReview() {
    if (deckClient && checkedBoxes.length === 1) {
      mergeReviewIntoCurrent(conceptSet.id, checkedBoxes[0], deckClient)
        .then(() =>
          doAlert(
            "success",
            "Successfully merged review into the main version",
          ),
        )
        .catch(() =>
          doAlert("error", "Something went wrong merging the review"),
        );
    }
  }

  function check(id: number) {
    const presentlyChecked = checkedBoxes.includes(id);
    if (presentlyChecked) {
      const copy = [...checkedBoxes].filter((i) => i !== id);
      setCheckedBoxes(copy);
    } else {
      const copy = [...checkedBoxes];
      copy.push(id);
      setCheckedBoxes(copy);
    }
  }

  function isChecked(id: number | undefined) {
    return id ? checkedBoxes.includes(id) : false;
  }

  return (
    <div style={{ padding: "1em" }}>
      {reviewRequestOpen && (
        <ReviewRequest
          open={reviewRequestOpen}
          setOpen={setReviewRequestOpen}
          conceptSet={conceptSet}
        />
      )}
      <div style={{ paddingTop: "1em", paddingLeft: "0.5em" }}>
        <ButtonWithIcon
          label={"Request Review"}
          icon={<AssignmentIndIcon />}
          onClick={doReviewRequest}
        />
      </div>
      {pendingReviews && pendingReviews.length > 0 && (
        <div style={{ paddingTop: "1.5em" }}>
          <hr />
          <h4>Pending Reviews</h4>
        </div>
      )}
      {pendingReviews?.map((t) => {
        return <div key={t.id + "open-task"}>{t.assignedTo.name}</div>;
      })}
      {completedReviews && completedReviews.length > 0 && (
        <div style={{ paddingTop: "1.5em" }}>
          <hr />
          <h4>Completed Reviews</h4>
        </div>
      )}
      {completedReviews?.map((t) => {
        return (
          <div className={"Icons-wrapper__align"} key={t.id + "completed-task"}>
            <Checkbox
              checked={isChecked(t.id)}
              onChange={() => t.id && check(t.id)}
              color={"primary"}
              className={"Icon"}
            />
            {t.assignedTo.name}
          </div>
        );
      })}
      {checkedBoxes.length > 0 && (
        <div
          style={{
            paddingTop: "1em",
            paddingBottom: "1em",
            paddingLeft: "0.5em",
          }}
        >
          <ButtonWithIcon
            label={"compare"}
            icon={<DifferenceIcon />}
            onClick={compareReviews}
          />
          {checkedBoxes.length === 1 && (
            <ButtonWithIcon
              label={"merge"}
              icon={<MergeIcon />}
              onClick={mergeReview}
              marginLeft={"1em"}
            />
          )}
        </div>
      )}

      {conflicts && conflicts.length > 0 && (
        <div style={{ paddingBottom: "2em" }}>
          {conflicts
            .sort((a, b) => {
              if (
                a.current.concept.CONCEPT_NAME.toLowerCase() <
                b.current.concept.CONCEPT_NAME.toLowerCase()
              )
                return -1;
              if (
                a.current.concept.CONCEPT_NAME.toLowerCase() >
                b.current.concept.CONCEPT_NAME.toLowerCase()
              )
                return 1;
              return 0;
            })
            .map((conflict) => {
              return (
                <div key={conflict.current.concept.CONCEPT_NAME}>
                  <hr />
                  <h4>{conflict.current.concept.CONCEPT_NAME}</h4>
                  <Grid container style={{ paddingBottom: "1em" }}>
                    <Grid item xs={4}>
                      Reviewer
                    </Grid>
                    {conceptSet.flavours.map((f) => (
                      <Grid
                        justifyContent={"center"}
                        item
                        xs={1}
                        key={"flavour-" + f + conflict.current.id}
                      >
                        {f}
                      </Grid>
                    ))}
                    <Grid item xs={3}>
                      comments
                    </Grid>
                  </Grid>
                  {conflict.conflicts.map((row) => {
                    return (
                      <Grid
                        key={row.item.id}
                        container
                        style={{ paddingBottom: "1em" }}
                      >
                        <Grid item xs={3}>
                          {row.user.name}
                        </Grid>
                        <Grid item xs={1} />
                        {conceptSet.flavours.map((f) => (
                          <Grid
                            item
                            xs={1}
                            key={"ticked-" + row.item.id + f}
                            justifyContent={"center"}
                          >
                            <Tick ticked={row.item.flavours[f]} />
                          </Grid>
                        ))}
                        <Grid item xs={3}>
                          <i>{row.comment}</i>
                        </Grid>
                      </Grid>
                    );
                  })}
                  <Grid
                    container
                    style={{
                      paddingBottom: "1em",
                      fontWeight: "bold",
                    }}
                  >
                    <Grid item xs={3}>
                      CURRENT
                    </Grid>
                    <Grid item xs={1}>
                      <Tooltip title={"Edit current"} placement={"top"}>
                        <EditIcon
                          color={"primary"}
                          className={"clickable"}
                          onClick={() => toggleEditing(conflict.current.id)}
                        />
                      </Tooltip>
                    </Grid>
                    {conceptSet.flavours.map((flavour) => (
                      <Grid
                        item
                        xs={1}
                        justifyContent={"center"}
                        key={"current-flavour-" + flavour + conflict.current.id}
                      >
                        {editing.get(conflict.current.id) ? (
                          <Checkbox
                            style={{ margin: 0 }}
                            onChange={(e) => {
                              setFlavour(
                                conflict.current,
                                flavour,
                                e.target.checked,
                              );
                            }}
                            size={"small"}
                            checked={conflict.current.flavours[flavour]}
                          />
                        ) : (
                          <Tick ticked={conflict.current.flavours[flavour]} />
                        )}
                      </Grid>
                    ))}
                    <Grid item xs={3}>
                      <IconButton
                        onClick={() => createComment(conflict.current)}
                      >
                        {conflict.current.hasComments ? (
                          <CommentIcon color={"primary"} />
                        ) : (
                          <AddCommentOutlinedIcon color={"primary"} />
                        )}
                      </IconButton>
                    </Grid>
                  </Grid>
                </div>
              );
            })}
        </div>
      )}
      {writingComment && selectedConcept && (
        <Comment
          open={writingComment}
          setOpen={setWritingComment}
          conceptSetId={conceptSet.id}
          item={selectedConcept}
        />
      )}
    </div>
  );
}
