import { Component } from "react"

import "./styles/LoadingOverlay.scss"

type Props = {
  isLoading: boolean
  text?: string;
} 

class App extends Component<Props, any> { 
  render() {
    return (
      <div
        className={
          "loader " + (this.props.isLoading ? "show-loading" : "hide-loading")
        }
      >
        <div className="sk-chase">
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
          <div className="sk-chase-dot"></div>
        </div>
        <span>{this.props.text ? this.props.text : "Loading"}</span>
      </div>
    )
  }
}

export default App
