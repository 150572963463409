import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import * as React from "react";

export default function LoadingButton(props: {
  onClick: () => void;
  loading: boolean;
  label: string;
}) {
  const { onClick, loading, label } = props;

  return (
    <Button variant="outlined" size="small" onClick={onClick}>
      {loading ? <CircularProgress size={25} /> : <div>{label}</div>}
    </Button>
  );
}
