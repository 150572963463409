import { DataGrid } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";

import { LogEntry } from "../../../@types/service";
import { AppContext, AppContextProps } from "../../../context/AppContext";
import { fetchConceptSetLogs } from "../../../service/ConceptSetService";

interface LogsProps {
  conceptSetId: number;
}

export default function Logs(props: LogsProps) {
  const { conceptSetId } = props;
  const localStoragekey = "ConceptSetsLogTable";
  const { doAlert, deckClient } = useContext(AppContext) as AppContextProps;
  const [rows, setRows] = useState<LogEntry[]>([]);
  const [columns, setColumns] = useState([
    {
      width: 185,
      field: "date",
      headerName: "date",
      type: "dateTime",
      minWidth: 100,
      //flex: 1,
      valueGetter: (params) => new Date(params.row.date),
    },
    {
      field: "user",
      headerName: "user",
      type: "string",
      minWidth: 350,
      //flex: 2,
      valueGetter: (params) => params.row.user.name,
    },
    {
      field: "action",
      headerName: "action",
      type: "string",
      minWidth: 400,
      //flex: 4,
    },
  ])

  useEffect(() => {
    if (deckClient) {
      fetchConceptSetLogs(conceptSetId, deckClient)
        .then((r) => {
          setRows(r);
        })
        .then(() => {
          document.querySelectorAll(".MuiDataGrid-main .MuiDataGrid-columnSeparator").forEach((o) => {
            (o as HTMLElement).classList.add("resizableColumn");
            (o as HTMLElement).onmousedown = (e) => {
              handleMouseDown(o, (e as any))
            }
          });

          const data = JSON.parse(localStorage.getItem(localStoragekey) || '{}')
          if (data) {
            for (const key in data) {
              const col = columns.map((cold) => {
                if (cold.field === key) {
                  cold.width = data[key];
                }
                return cold;
              });
              setColumns(col);
            };
          }
        })
        .catch(() => {
          doAlert("error", "Failed to load concept set logs");
        });
    }
  }, []);

  const handleMouseDown = (para: any, e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    let obj = JSON.parse(localStorage.getItem(localStoragekey) || "{}")
    let start = e.clientX;
    let oldWidth = 0

    document.onmousemove = (e: any) => {
      e.stopPropagation();
      if (start !== null) {
        let differenceX = 0;
        let end = start;
        end = e.clientX;
        const col = columns.map((cold) => {
          if (cold.field === para.parentElement.getAttribute("data-field")) {
            if (oldWidth === 0) {
              oldWidth = para.parentElement.clientWidth
            }
            differenceX = end - start;
            cold.width = oldWidth + differenceX;
          }
          obj = {
            ...obj,
            [cold.field]: cold.width
          }
          return cold;
        });

        setColumns(col);
      }
    };
    document.onmouseup = (e: any) => {
      e.stopPropagation();
      localStorage.setItem(localStoragekey, JSON.stringify(obj))
      document.onmousemove = (e: any) => {};
      document.onmouseup = (e: any) => {};
    };
  };

  return (
    <div className={"DataGrids-wrapper DataGrids-wrapper__tab"}>
      <DataGrid
        initialState={{
          sorting: {
            sortModel: [{ field: "date", sort: "desc" }],
          },
        }}
        rows={rows}
        columns={
          columns.map((column) => ({
            ...column,
            renderHeader: (params) => (
              <>
                <div>
                  {params.colDef.field}
                </div>
              </>
            ),
          })) as any
        }
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row'
        }
      />
    </div>
  );
}
