import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import * as React from "react";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

import { AppContext, AppContextProps } from "../context/AppContext";
import {
  fetchCodes,
  fetchDomains,
  fetchVocabularies,
  fetchVocabVersion,
} from "../service/CodeListGeneratorService";
import JSONContent from "./JSONContent";
import LoadingButton from "./LoadingButton";
import { CodeListGeneratorQuery } from "../@types/code-list-generator";

export default function CodeListGenerator(props: {
  parent?: number;
  setMetadata: Dispatch<
    SetStateAction<{
      version: string | undefined;
      name: string;
      description: string;
      vocabularyVersion: string;
      codeListGenerator: CodeListGeneratorQuery | undefined;
    }>
  >;
  setRequestData: Dispatch<SetStateAction<string>>;
}) {
  const { setMetadata, setRequestData, parent } = props;
  const { doAlert, codeListGeneratorClient } = useContext(
    AppContext,
  ) as AppContextProps;
  const [name, setName] = useState("");
  const [version, setVersion] = useState<string | undefined>(undefined);
  const [description, setDescription] = useState("");
  const [domainOpts, setDomainOpts] = useState<string[]>([]);
  const [vocabVersion, setVocabVersion] = useState("Loading ...");
  const [vocabOpts, setVocabOpts] = useState<string[]>([]);
  const [codeList, setCodeList] = useState<Object | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [clgRequest, setClgRequest] = useState<CodeListGeneratorQuery>({
    keywords: [],
    domains: [],
    vocabs: [],
    exclude: [],
    synonyms: true,
  });

  const client = codeListGeneratorClient;

  useEffect(() => {
    if (client) {
      fetchVocabVersion(client)
        .then((r) => {
          setVocabVersion(r[0]);
          fetchVocabularies(client).then((r) => setVocabOpts(r.sort()));
          fetchDomains(client).then((r) => setDomainOpts(r.sort()));
        })
        .catch(() => {
          doAlert("error", "Failed to connect to CodeListGenerator");
        });
    }
  }, []);

  useEffect(() => {
    setMetadata({
      version: version,
      name: name,
      description: description,
      vocabularyVersion: vocabVersion,
      codeListGenerator: clgRequest,
    });
  }, [description, vocabVersion, clgRequest]);

  useEffect(() => {
    setRequestData(
      JSON.stringify({ name: name, expression: { items: codeList } }),
    );
  }, [name, codeList]);

  function getCodes() {
    if (clgRequest.keywords.length === 0) {
      doAlert(
        "error",
        "At least one keyword is required, if you have typed something in that field hit enter to make it stick",
      );
    } else if (clgRequest.domains.length === 0) {
      doAlert("error", "At least one domain is required");
    } else if (client && clgRequest) {
      setLoading(true);
      setCodeList(undefined);
      fetchCodes(clgRequest, client)
        .then((r) => {
          const formatted = r
            .sort((a, b) => {
              if (a.concept_name.toLowerCase() < b.concept_name.toLowerCase()) {
                return -1;
              }
              if (a.concept_name.toLowerCase() > b.concept_name.toLowerCase()) {
                return 1;
              }
              return 0;
            })
            .map((c) => {
              return {
                concept: {
                  CONCEPT_ID: c.concept_id,
                  CONCEPT_NAME: c.concept_name,
                  DOMAIN_ID: c.domain_id,
                  VOCABULARY_ID: c.vocabulary_id,
                  CONCEPT_CLASS_ID: c.concept_class_id,
                  found_from: c.found_from,
                },
              };
            });
          setCodeList(formatted);
          setLoading(false);
        })
        .catch(() => {
          doAlert(
            "error",
            "Failed to generate concept set with code list generator",
          );
          setLoading(false);
        });
    }
  }

  function setSynonyms(s: boolean) {
    setClgRequest({ ...clgRequest, synonyms: s });
  }

  return (
    <div>
      {parent && (
        <TextField
          style={{ marginBottom: "1em" }}
          multiline
          label={"Version"}
          variant="outlined"
          fullWidth
          onChange={(e) => setVersion(e.target.value)}
        />
      )}
      <TextField
        style={{ marginBottom: "1em" }}
        multiline
        label={"Name"}
        variant="outlined"
        fullWidth
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        style={{ marginBottom: "1em" }}
        multiline
        label={"Description"}
        variant="outlined"
        fullWidth
        onChange={(e) => {
          setDescription(e.target.value);
        }}
      />
      <TextField
        style={{ marginBottom: "1em" }}
        value={vocabVersion}
        label={"Vocabulary version"}
        variant="outlined"
        fullWidth
        disabled
      />
      <Autocomplete
        id={"keywords"}
        multiple
        options={[]}
        freeSolo
        value={clgRequest.keywords}
        onChange={(e, items) => {
          if (items) {
            setClgRequest({ ...clgRequest, keywords: [...items] });
          }
        }}
        renderInput={(params) => <TextField {...params} label="Keywords" />}
      />
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checked={clgRequest.synonyms}
              onClick={() => setSynonyms(!clgRequest.synonyms)}
            />
          }
          label="Include synonyms search"
        />
      </FormGroup>
      <Autocomplete
        id={"domain"}
        style={{ marginBottom: "1em", marginTop: "1em" }}
        multiple
        options={domainOpts}
        value={clgRequest.domains}
        onChange={(e, items) => {
          if (items) {
            setClgRequest({ ...clgRequest, domains: [...items] });
          }
        }}
        renderInput={(params) => <TextField {...params} label="Domains" />}
      />
      <Autocomplete
        id={"vocab"}
        style={{ marginBottom: "1em" }}
        multiple
        options={vocabOpts}
        value={clgRequest.vocabs}
        onChange={(e, items) => {
          if (items) {
            setClgRequest({ ...clgRequest, vocabs: [...items] });
          }
        }}
        renderInput={(params) => <TextField {...params} label="Vocabularies" />}
      />
      <Autocomplete
        id={"excludes"}
        style={{ marginBottom: "1em" }}
        multiple
        options={[]}
        freeSolo
        value={clgRequest.exclude}
        onChange={(e, items) => {
          if (items) {
            setClgRequest({ ...clgRequest, exclude: [...items] });
          }
        }}
        renderInput={(params) => <TextField {...params} label="Exclude" />}
      />
      <LoadingButton
        onClick={getCodes}
        loading={loading}
        label={"GENERATE CONCEPT SET"}
      />
      {codeList && <JSONContent data={codeList} />}
    </div>
  );
}
