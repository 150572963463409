import { FilterAlt, MoreVert } from "@mui/icons-material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import * as React from "react";

export const Faq = () => {
  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 3, background: "white", color: "black" }}
    >
      <h3 className={"blue"}>
        Clinical Definitions, Cohort Definitions, Concept Sets? What are you
        talking about?
      </h3>
      Within the context of DARWIN EU a <b>Clinical Definition</b> is understood
      to be a detailed human readable description of something that can be
      meaningfully observed in health data, like a condition or drug use.
      Clinical Definitions in turn may be associated with multiple{" "}
      <i>Cohort Definitions</i> and/or <i>Concept Sets</i>.{" "}
      <b>Cohort Definitions</b> are machine readable definitions of cohorts that
      include a series of criteria that need to be met to be included of a
      cohort and are formatted in line with the OHDSI standard. A{" "}
      <b>Concept Set</b>, sometimes also known as a Code List, is a group of
      concepts that logically belong together and may be used within a{" "}
      <i>Cohort Definition</i> or belong to a <i>Phenotype</i>.
      <h3 className={"blue"}>How can I filter items in the table?</h3>
      When you hover on a table header you should see an icon like this{" "}
      <IconButton>
        <MoreVert />
      </IconButton>{" "}
      when you click this some options will appear, choose{" "}
      <IconButton>
        <FilterAlt />
      </IconButton>
      filter.
      <h3 className={"blue"}>Is there an API?</h3>
      Yes, you can interact with DECK through a dedicated API, there is basic
      documentation available.
      <h3 className={"blue"}>Other</h3>
      If you have other questions or would like to report an issue get in touch
      with s.c.denotter@erasmusmc.nl
    </Box>
  );
};
