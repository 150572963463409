import AddIcon from "@mui/icons-material/Add";
import { Autocomplete } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import React, { useContext, useEffect, useState } from "react";

import { ConceptSet } from "../../../@types/service";
import { EditableConceptSetTable } from "../../../components/EditableConceptSetTable";
import { AppContext, AppContextProps } from "../../../context/AppContext";
import { fetchConceptSets } from "../../../service/ConceptSetService";
import {
  addConceptSet,
  assignConceptSetVersion,
  fetchConceptSetsForPhenotype,
} from "../../../service/PhenotypeService";

export default function ConceptSets(props: { phenotypeId: number }) {
  const { phenotypeId } = props;
  const { doAlert, deckClient } = useContext(AppContext) as AppContextProps;
  const [conceptSetOptions, setConceptSetOptions] = useState<ConceptSet[]>([]);
  const [associatedConceptSets, setAssociatedConceptSets] = useState<
    ConceptSet[]
  >([]);
  const [newConceptSet, setNewConceptSet] = useState<ConceptSet | null>(null);
  const client = deckClient;

  useEffect(() => {
    doRefreshData();
  }, []);

  function doRefreshData() {
    if (client) {
      fetchConceptSetsForPhenotype(phenotypeId, client)
        .then((resp) => setAssociatedConceptSets(resp))
        .catch(() => doAlert("error", "Failed to load concept sets"));
      fetchConceptSets(client)
        .then((resp) => {
          const filtered = [...resp].filter(
            (c: ConceptSet) =>
              ![...associatedConceptSets].map((a) => a.name).includes(c.name),
          );
          setConceptSetOptions(filtered);
        })
        .catch(() => doAlert("error", "Failed to load concept sets"));
    }
  }

  function saveVersion(oldId: number, newId: number | undefined) {
    if (client && newId) {
      assignConceptSetVersion(phenotypeId, oldId, newId, client)
        .then(() => doRefreshData())
        .catch(() => doAlert("error", "Failed to set concept set version"));
    }
  }

  function addConceptSetToPhenotype() {
    if (newConceptSet && client) {
      addConceptSet(phenotypeId, newConceptSet, client)
        .then(() => {
          setNewConceptSet(null);
          doRefreshData();
        })
        .catch(() =>
          doAlert("error", "Failed to add concept set to phenotype"),
        );
    }
  }

  return (
    <div className={"DataGrids-wrapper DataGrids-wrapper__tab"}>
      <EditableConceptSetTable
        conceptSets={associatedConceptSets}
        saveFunction={saveVersion}
      />
      <div className={"Icons-wrapper__align"}>
        <Autocomplete
          options={conceptSetOptions}
          getOptionLabel={(option) => option?.name}
          value={newConceptSet}
          onChange={(e, value) => {
            setNewConceptSet(value);
          }}
          sx={{ width: "30vw" }}
          renderInput={(params) => (
            <TextField {...params} label="Add concept set" />
          )}
        />
        <IconButton onClick={addConceptSetToPhenotype}>
          <AddIcon color={"primary"} />
        </IconButton>
      </div>
    </div>
  );
}
