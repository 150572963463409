import { Autocomplete } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

import { ConceptSet, Task, User } from "../../@types/service";
import { AppContext, AppContextProps } from "../../context/AppContext";
import { fetchAllUser, postTask } from "../../service/UserService";

interface ReviewRequestProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  conceptSet: ConceptSet;
}

export default function ReviewRequest(props: ReviewRequestProps) {
  const { doAlert, deckClient } = useContext(AppContext) as AppContextProps;
  const { setOpen, open, conceptSet } = props;
  const [email, setEmail] = useState<string[]>([]);
  const [message, setMessage] = useState<string>("");
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const client = deckClient;

  useEffect(() => {
    if (client) {
      fetchAllUser(client).then((s) => {
        setAllUsers(s);
      });
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const post = () => {
    if (email && email.length > 0 && client) {
      let tasks: Task[] = [];
      email.map((e) =>
        tasks.push({
          parentConceptSetId: conceptSet.id,
          assignedTo: { email: e },
          conceptSetId: conceptSet.id,
          conceptSetName: conceptSet.name,
          status: "OPEN",
        }),
      );
      postTask(tasks, client)
        .then(() => {
          const mails = email.join(", ");
          doAlert("success", `Requests created for ${mails}`);
          handleClose();
        })
        .catch((err) => {
          const r = err.response;
          doAlert(
            "error",
            r.status === 400
              ? r.data.message
              : "Something went wrong, please get in touch",
          );
        });
    } else {
      doAlert("error", "At least one email is required");
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"sm"}>
        <DialogTitle>
          Request review for <em>{conceptSet.name}</em>
        </DialogTitle>
        <DialogContent>
          <div style={{ padding: "1em" }}>
            <Autocomplete
              id={"request-email"}
              multiple
              options={allUsers.map((u) => u.email)}
              freeSolo
              value={email}
              onChange={(_e, items) => {
                if (items) {
                  setEmail([...items]);
                }
              }}
              renderInput={(params) => <TextField {...params} label="Email" />}
            />
          </div>
          <div style={{ padding: "1em" }}>
            <TextField
              value={message}
              fullWidth
              multiline
              label="Message"
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "space-between", paddingLeft: "1em" }}
        >
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={post}>Send</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
