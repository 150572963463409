import EditIcon from "@mui/icons-material/Edit";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Autocomplete,
  Button,
  Checkbox,
  IconButton,
  Tooltip,
  createFilterOptions,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import React, {
  ChangeEvent,
  Dispatch,
  EventHandler,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { AppContext, AppContextProps } from "./../context/AppContext";
import { Tag } from "../@types/service";
import { saveTag } from "../service/TagService";
import { useAuth } from "react-oidc-context";

import "./styles/Tags.scss";

export default function Tags(props: {
  open: boolean;
  popup: boolean;
  isEditable: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  label: string;
  originalValues: () => any[];
  availableValues: () => any[];
  persist: (value: any[], id: number) => void;
  id: number;
}) {
  const { doAlert, setTitle, deckClient, currentUser } = useContext(
    AppContext,
  ) as AppContextProps;

  const auth = useAuth();
  const {
    label,
    persist,
    originalValues,
    availableValues,
    setOpen,
    open,
    popup,
    isEditable,
    id
  } = props;
  const [initialized, setInitialized] = useState(false);
  const [values, setValues] = useState<any[]>(originalValues());
  const [allValues, setAllValues] = useState<any[]>(availableValues());
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  function save() {
    persist(values, id);
    setOpen(false);
    setEditing(false);
  }

  function close() {
    setOpen(false);
    setEditing(false);
  }

  function handleClose() {
    setOpen(false);
  }

  useEffect(() => {
    setValues(props.originalValues());
    setAllValues(props.availableValues());
  }, [allValues, values, props.availableValues, props.originalValues, id, open, editing]);

  return (
    <div>
      {popup === true ? (
        <Dialog
          open={open}
          onClose={handleClose}
          fullWidth
          maxWidth={"sm"}
          PaperProps={{ sx: { minHeight: "20vh" } }}
        >
          <DialogTitle>Tags</DialogTitle>
          <DialogContent>
            <div style={{ margin: "0px" }}>
              <div className={"Icons-wrapper__align"}>
                <b style={{ marginBottom: "10px" }}>{label}</b>
              </div>
              {
                <div>
                  <Autocomplete
                    multiple
                    disableCloseOnSelect
                    style={{ width: "100%" }}
                    clearOnBlur={false}
                    value={values}
                    options={allValues}
                    getOptionLabel={(option) => option.name ? option.name : "" }
                    isOptionEqualToValue={(option, val) => option?.name === val?.name}
                    filterOptions={(options, params) => {
                      const filter = createFilterOptions();
                      const filtered = filter(options, params);
                      return [...filtered];
                    }}
                    onChange={(_event, newValue: any) => {
                      if (newValue) {
                        persist(newValue, id);
                      }
                    }}
                    renderOption={(props, option, { selected }) => {
                      return (
                        <li {...props} key={props.id}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      );
                    }}
                    renderInput={(params) => (
                      <>
                        <div className="custom-tags-container">
                          <TextField
                            {...params}
                            variant="outlined"
                            label="Tags"
                            placeholder="Tags..."
                            fullWidth
                            value={() => values}
                            onKeyDown={(e) => { }}
                            onBlur={(e: any) => {
                              if (e.nativeEvent.target)
                                e.nativeEvent.target.value = "";
                            }}
                            inputRef={inputRef}
                            InputProps={{
                              ...params.InputProps,
                              onKeyDown: (
                                e: React.KeyboardEvent<HTMLInputElement>,
                              ) => {
                                if (e.key.toUpperCase() !== "ENTER") return;
                                if (e.currentTarget.value) {
                                  const newTag = e.currentTarget.value.trim() ?? "";
                                  if (newTag !== "") {
                                    persist([...values, { name: newTag }], id);
                                  }
                                }
                              },
                            }}
                            inputProps={{
                              ...params.inputProps,
                            }}
                          />

                          <IconButton
                            onClick={(e) => {
                              if (inputRef.current) {
                                const newTag = inputRef.current.value.trim() ?? "";
                                if (newTag !== "") {
                                  persist([...values, { name: newTag }], id);
                                }
                              }
                            }}
                          >
                            <AddIcon color={"primary"} />
                          </IconButton>
                        </div>
                      </>
                    )}
                  />
                </div>
              }
            </div>
          </DialogContent>
          <DialogActions
            style={{ paddingLeft: "1em", justifyContent: "flex-start" }}
          >
            <Button onClick={close}>CLOSE</Button>
          </DialogActions>
        </Dialog>
      ) : (
        <div style={{ margin: "1em" }}>
          <div className={"Icons-wrapper__align"}>
            <b style={{ minWidth: "9%" }}>{label}</b>&emsp;
            {isEditable && (
              <Tooltip title={`edit ${label.toLowerCase()}`} placement={"top"}>
                <IconButton size={"small"} onClick={() => setEditing(true)}>
                  <EditIcon fontSize={"small"} color={"primary"} />
                </IconButton>
              </Tooltip>
            )}
          </div>
          {editing ? (
            <div>
              <div>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  style={{ width: 500 }}
                  clearOnBlur={false}
                  value={values}
                  options={allValues}
                  getOptionLabel={(option) => (option.name ? option.name : "")}
                  isOptionEqualToValue={(option, val) => option === val}
                  filterOptions={(options, params) => {
                    const filter = createFilterOptions();
                    const filtered = filter(options, params);
                    return [...filtered];
                  }}
                  onChange={(_event, newValue: any) => {
                    if (newValue) {
                      persist(newValue, id);
                    }
                  }}
                  renderOption={(props, option, { selected }) => {
                    return (
                      <li {...props} key={props.id}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <>
                      <div className="custom-tags-container" style={{ marginTop: "10px" }}>
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Tags"
                          placeholder="Tags..."
                          fullWidth
                          value={() => values}
                          onKeyDown={(e) => { }}
                          onBlur={(e: any) => {
                            if (e.nativeEvent.target)
                              e.nativeEvent.target.value = "";
                          }}
                          inputRef={inputRef}
                          InputProps={{
                            ...params.InputProps,
                            onKeyDown: (
                              e: React.KeyboardEvent<HTMLInputElement>,
                            ) => {
                              if (e.key.toUpperCase() !== "ENTER") return;
                              if (e.currentTarget.value) {
                                const newTag = e.currentTarget.value.trim() ?? "";
                                if (newTag !== "") {
                                  persist([...values, { name: newTag }], id);
                                }
                              }
                            },
                          }}
                          inputProps={{
                            ...params.inputProps,
                          }}
                        />

                        <IconButton
                          onClick={(e) => {
                            if (inputRef.current) {
                              const newTag = inputRef.current.value.trim() ?? "";
                              if (newTag !== "") {
                                persist([...values, { name: newTag }], id);
                              }
                            }
                          }}
                        >
                          <AddIcon color={"primary"} />
                        </IconButton>
                      </div>
                    </>
                  )}
                />
              </div>

              <div>
                <Button
                  style={{ marginTop: "1em" }}
                  variant={"outlined"}
                  onClick={close}
                >
                  CLOSE
                </Button>
              </div>
            </div>
          ) : (
            <div style={{ paddingTop: "0.5em" }}>
              {values?.map((value) => value.name).join(", ")}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
