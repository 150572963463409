import TelegramIcon from "@mui/icons-material/Telegram";
import parse from "html-react-parser";
import moment from "moment/moment";
import React, { useContext, useEffect, useState } from "react";
import ReactQuill from "react-quill";

import { Comment } from "../../../@types/service";
import ButtonWithIcon from "../../../components/ButtonWithIcon";
import { AppContext, AppContextProps } from "../../../context/AppContext";
import { fetchComments, postComment } from "../../../service/PhenotypeService";

export default function Discussion(props: { phenotypeId: number }) {
  const { phenotypeId } = props;
  const { doAlert, deckClient } = useContext(AppContext) as AppContextProps;
  const [comments, setComments] = useState<Comment[]>([]);
  const [comment, setComment] = useState("");
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (deckClient) {
      fetchComments(phenotypeId, deckClient)
        .then((r) => setComments(r))
        .catch(() => doAlert("warning", "discussion"));
    }
  }, [refresh]);

  function save() {
    if (deckClient) {
      postComment(phenotypeId, comment, deckClient)
        .then(() => {
          setRefresh(!refresh);
          setComment("");
        })
        .catch(() => {
          doAlert("error", "Failed to post comment");
        });
    }
  }

  return (
    <div style={{ padding: "1em" }}>
      <div style={{ padding: "1em", width: "60vw" }}>
        {comments.map((com) => (
          <div key={"comment-" + com.id}>
            {moment(com.created).fromNow() +
              " " +
              com.createdBy.name +
              " wrote:"}
            {parse(com.comment)}
            <hr />
          </div>
        ))}
        <ReactQuill
          value={comment}
          onChange={setComment}
          style={{ marginTop: "2em" }}
        />
        <div
          style={{ display: "flex", paddingTop: "2em", paddingBottom: "2em" }}
        >
          <div
            style={{
              justifyContent: "flex-end",
              marginLeft: "auto",
            }}
          >
            <ButtonWithIcon
              label={"POST"}
              icon={<TelegramIcon />}
              onClick={save}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
