import { User } from "oidc-client-ts";
import React from "react";
import ReactDOM from "react-dom/client";
import { AuthProvider } from "react-oidc-context";

import App from "./App";
import AppContextProvider from "./context/AppContext";
import "./index.scss";

const oidcConfig = {
  authority: window._env_.OIDC_AUTHORITY,
  client_id: window._env_.OIDC_CLIENT_ID,
  redirect_uri: window._env_.OIDC_REDIRECT_URI,
  onSigninCallback: (_user: User | void): void => {
    window.history.replaceState({}, document.title, window.location.pathname);
  },
  post_logout_redirect_uri: "https://darwin-eu.org",
  extraQueryParams: { domain_hint: "darwin-eu.org" },
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <AuthProvider {...oidcConfig}>
    <AppContextProvider>
      <App />
    </AppContextProvider>
  </AuthProvider>,
);
