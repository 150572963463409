import { Autocomplete } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import * as React from "react";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from "react";

import { AtlasListing } from "../../@types/atlas";
import { PortalComponentType } from "../../@types/portal-component";
import "../../App.css";
import CheckDocsTab from "../../components/CheckDocsTab";
import CodeListGenerator from "../../components/CodeListGenerator";
import JSONContent from "../../components/JSONContent";
import { TabPanel, a11yProps } from "../../components/TabPanel";
import { AppContext, AppContextProps } from "../../context/AppContext";
import {
  fetchAtlasConceptSet,
  fetchAtlasConceptSets,
} from "../../service/AtlasService";
import { saveConceptSet } from "../../service/ConceptSetService";
import { filterOptions } from "../../service/FilterService";
import { CodeListGeneratorQuery } from "../../@types/code-list-generator";
import { FileDrop } from "../../components/FileDrop";
import { fetchDomains, fetchVocabVersion, fetchVocabularies } from "../../service/CodeListGeneratorService";

let jsonminify = require("jsonminify");

export default function New(props: {
  parent?: number;
  open: boolean;
  setFieldValue?: any;
  errors?: any;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) {
  const { open, setOpen, parent, setFieldValue, errors } = props;
  const { doAlert, deckClient, enabledModules, atlasClient, codeListGeneratorClient } = useContext(
    AppContext,
  ) as AppContextProps;
  const [filename, setFilename] = useState<string | undefined>(undefined);
  const [jsonObject, setJsonObject] = useState<Object | undefined>(undefined);
  const [requestData, setRequestData] = useState<string>("");
  const [selectedTab, setSelectedTab] = useState(0);
  const [domainOpts, setDomainOpts] = useState<string[]>([]);
  const [vocabVersion, setVocabVersionForImport] = useState("Loading ...");
  const [vocabOpts, setVocabOpts] = useState<string[]>([]);
  const [atlasOptions, setAtlasOptions] = useState<AtlasListing[]>([]);
  const [metadata, setMetadata] = useState({
    version: parent ? "" : undefined,
    name: "",
    description: "",
    vocabularyVersion: "",
    codeListGenerator: undefined as undefined | CodeListGeneratorQuery,
  });

  const clgClient = codeListGeneratorClient;
  
  useEffect(() => {
    if (atlasClient) {
      if (enabledModules.includes(PortalComponentType.ATLAS)) {
        fetchAtlasConceptSets(atlasClient)
          .then((r) => setAtlasOptions(r))
          .catch();
      }
    }

    if (clgClient) {
      fetchVocabVersion(clgClient)
        .then((r) => {
          setVocabVersionForImport(r[0]);
          setVocabVersion(r[0]);
          fetchVocabularies(clgClient).then((r) => setVocabOpts(r.sort()));
          fetchDomains(clgClient).then((r) => setDomainOpts(r.sort()));
        })
        .catch(() => {
          doAlert("error", "Failed to connect to CodeListGenerator");
        });
    }
  }, []);

  const switchTab = (event: SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    setJsonObject(undefined);
    setRequestData("");
  };

  function handleClose() {
    setOpen(false);
  }

  function setName(d: string) {
    const _md = metadata;
    _md.name = d;
    setMetadata(_md);
  }

  function setDescription(d: string) {
    const _md = metadata;
    _md.description = d;
    setMetadata(_md);
  }

  function setVersion(v: string) {
    const _md = metadata;
    _md.version = v;
    setMetadata(_md);
  }

  function setVocabVersion(vv: string) {
    const _md = metadata;
    _md.vocabularyVersion = vv;
    setMetadata(_md);
  }

  function save() {
    if (deckClient) {
      try {
        saveConceptSet(metadata, requestData, deckClient, parent)
          .then(() => {
            doAlert("success", "Imported concept set");
            handleClose();
          })
          .catch(() => doAlert("error", "Failed to import concept set"));
      } catch (err) {
        console.error("Something went bad saving the cohort");
      }
    }
  }

  function selectAtlasOption(selected: AtlasListing | null) {
    if (selected && atlasClient) {
      fetchAtlasConceptSet(selected.id, atlasClient)
        .then((r) => {
          setRequestData(
            JSON.stringify({ name: selected.name, expression: r }),
          );
          setJsonObject(r.items);
        })
        .catch(() =>
          doAlert("error", `Failed to retrieve ${selected.name} from Atlas`),
        );
    } else {
      setRequestData("");
      setJsonObject(undefined);
    }
  }

  const handleFileUpload = (fileArray: File[]) => {

    const file = fileArray[0]
    const { name } = file;
    setFilename(name);

    const reader = new FileReader();
    reader.onload = (evt) => {
      if (!evt?.target?.result) {
        return;
      }
      try {
        const expression = evt.target.result;
        const records = JSON.parse(expression as string);
        setJsonObject(records);
        setRequestData(JSON.stringify({ name: name, expression: records }));
      } catch (e) {
        alert("Selected file does not contain valid JSON");
        setRequestData("");
        setFilename(undefined);
        setJsonObject(undefined);
      }
    };
    reader.readAsBinaryString(file);
  };
  
  const handleFileUploadContent = (fileContent: String) => {
    try {
      const expression = fileContent;
      const records = JSON.parse(expression as string);
      setJsonObject(records);
      setRequestData(JSON.stringify({ name: metadata.name, expression: records }));
    } catch (e) {
      setFilename(undefined);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: { minHeight: "80vh", minWidth: "50vw" } }}
        fullWidth
        maxWidth={"sm"}
      >
        <DialogTitle>Import Concept Set</DialogTitle>
        <Tabs value={selectedTab} onChange={switchTab} aria-label="basic tabs">
          <Tab label="JSON" {...a11yProps(0)} />
          <Tab label="ATLAS" {...a11yProps(1)} />
          <Tab label="GENERATE" {...a11yProps(2)} />
          <Tab label="API" {...a11yProps(3)} />
        </Tabs>{" "}
        <DialogContent>
          <TabPanel value={selectedTab} index={0}>
            {parent && (
              <TextField
                style={{ marginBottom: "1em" }}
                multiline
                label={"Version"}
                variant="outlined"
                fullWidth
                onChange={(e) => setVersion(e.target.value)}
              />
            )}
            <TextField
              style={{ marginBottom: "1em" }}
              multiline
              label={"Name"}
              variant="outlined"
              fullWidth
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              style={{ marginBottom: "1em" }}
              multiline
              label={"Description"}
              variant="outlined"
              fullWidth
              onChange={(e) => setDescription(e.target.value)}
            />
            <TextField
              style={{ marginBottom: "1em" }}
              value={vocabVersion}
              label={"Vocabulary version"}
              variant="outlined"
              fullWidth
              onChange={(e) => setVocabVersion(e.target.value)}
            />
            <FileDrop {...props } setFieldValue={(e, file) => handleFileUpload(file)} errors={errors} />
            <TextField
              style={{ marginTop: "0px", marginBottom: "1em" }}
              label={"Or paste as text..."}
              fullWidth
              variant="filled"
              size="small"
              onChange={(e) => handleFileUploadContent(e.target.value)}
            />
            {jsonObject && (
              <div style={{marginTop: "-1em"}}>
                <JSONContent data={jsonObject} />
              </div>
            )}
          </TabPanel>
          <TabPanel value={selectedTab} index={1}>
            {enabledModules.includes(PortalComponentType.ATLAS) ? (
              <div>
                {parent && (
                  <TextField
                    style={{ marginBottom: "1em" }}
                    multiline
                    label={"Version"}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => setVersion(e.target.value)}
                  />
                )}
                <TextField
                  multiline
                  style={{ marginBottom: "1em" }}
                  label={"Description"}
                  variant="outlined"
                  fullWidth
                  onChange={(e) => setDescription(e.target.value)}
                />
                <TextField
                  style={{ marginBottom: "1em" }}
                  label={"Vocabulary version"}
                  variant="outlined"
                  fullWidth
                  onChange={(e) => setVocabVersion(e.target.value)}
                />
                <Autocomplete
                  filterOptions={filterOptions}
                  options={atlasOptions}
                  getOptionLabel={(o) => o.name}
                  onChange={(e, item) => {
                    selectAtlasOption(item);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Search Atlas" />
                  )}
                ></Autocomplete>
                {jsonObject && <JSONContent data={jsonObject} />}
              </div>
            ) : (
              <div>Enable Atlas in settings to import from Atlas</div>
            )}
          </TabPanel>
          <TabPanel value={selectedTab} index={2}>
            <CodeListGenerator
              parent={parent}
              setMetadata={setMetadata}
              setRequestData={setRequestData}
            />
          </TabPanel>
          <TabPanel value={selectedTab} index={3}>
            <CheckDocsTab />
          </TabPanel>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "space-between", paddingLeft: "1em" }}
        >
          <Button onClick={handleClose}>Close</Button>
          {selectedTab !== 3 && <Button onClick={save}>Save</Button>}
        </DialogActions>
      </Dialog>
    </div>
  );
}
