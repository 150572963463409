import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import moment from "moment";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

import { Comment, ConceptSetItem } from "../../../@types/service";
import { AppContext, AppContextProps } from "../../../context/AppContext";
import {
  fetchConceptSetItemComments,
  postComment,
} from "../../../service/ConceptSetService";

interface CreateCommentProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  item: ConceptSetItem;
  conceptSetId: number;
}

export default function ConceptComment(props: CreateCommentProps) {
  const { doAlert, deckClient } = useContext(AppContext) as AppContextProps;
  const { setOpen, open, item, conceptSetId } = props;
  const [comments, setComments] = useState<Comment[]>([]);
  const [text, setText] = useState<string>("");

  function handleClose() {
    setOpen(false);
  }

  useEffect(() => {
    updateComments();
  }, []);

  function updateComments() {
    if (deckClient) {
      fetchConceptSetItemComments(conceptSetId, item.id, deckClient).then(
        (r) => {
          setComments(r);
        },
      );
    }
  }

  function post() {
    if (text && deckClient) {
      if (item) {
        postComment(conceptSetId, item.id, text, deckClient)
          .then(() => {
            updateComments();
            setText("");
            doAlert("success", "Comment added");
            handleClose();
          })
          .catch(() =>
            doAlert(
              "error",
              "Something went wrong posting your comment, please report this issue",
            ),
          );
      }
    } else {
      doAlert("error", "write something before saving please");
    }
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"sm"}>
        <DialogTitle>
          Comments for {item?.concept.CONCEPT_NAME} (
          {item?.concept.VOCABULARY_ID})
        </DialogTitle>
        <DialogContent>
          {comments.map((com) => {
            return (
              <div style={{ padding: "1em" }} key={com.id + "-div"}>
                <TextField
                  key={com.id + "-text-field"}
                  fullWidth
                  multiline
                  label={
                    moment(com.created).fromNow() +
                    " " +
                    com.createdBy.name +
                    " wrote:"
                  }
                  inputProps={{ readOnly: true }}
                  value={com.comment}
                />
              </div>
            );
          })}
          <div style={{ padding: "1em" }}>
            <TextField
              value={text}
              fullWidth
              multiline
              label="Write a comment"
              onChange={(e) => setText(e.target.value)}
            />
          </div>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "space-between", paddingLeft: "1em" }}
        >
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={post}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
