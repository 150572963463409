import { Autocomplete } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import * as React from "react";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from "react";

import { AtlasListing } from "../../@types/atlas";
import { PortalComponentType } from "../../@types/portal-component";
import { ClinicalDefinition, CohortDefinition } from "../../@types/service";
import "../../App.css";
import CheckDocsTab from "../../components/CheckDocsTab";
import JSONContent from "../../components/JSONContent";
import { TabPanel, a11yProps } from "../../components/TabPanel";
import { AppContext, AppContextProps } from "../../context/AppContext";
import {
  fetchAtlasCohortDefinition,
  fetchAtlasCohortDefinitions,
} from "../../service/AtlasService";
import { saveCohortDefinition } from "../../service/CohortDefinitionService";
import { filterOptions } from "../../service/FilterService";
import { fetchPhenotypes } from "../../service/PhenotypeService";

let jsonminify = require("jsonminify");

interface CreateCohortDefinitionProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  version?: boolean;
  parentId?: number;
}

export default function CreateCohortDefinition(
  props: CreateCohortDefinitionProps,
) {
  const { open, setOpen, version, parentId } = props;
  const { doAlert, deckClient, atlasClient, enabledModules } = useContext(
    AppContext,
  ) as AppContextProps;
  const [filename, setFilename] = useState<string | undefined>(undefined);
  const [selectedTab, setSelectedTab] = useState(0);
  const [jsonObject, setJsonObject] = useState<Object | undefined>(undefined);
  const [phenotypes, setPhenotypes] = useState<ClinicalDefinition[]>([]);
  const [linkedPhenotypes, setLinkedPhenotypes] = useState<string[]>([]);
  const [atlasOptions, setAtlasOptions] = useState<AtlasListing[]>([]);
  const emptyRequest = {
    parentName: "",
    name: "initial version",
    source: "DECK",
    cohortExpression: "",
    humanReadable: "",
  };
  const [cohortDefinition, setCohortDefinition] =
    useState<CohortDefinition>(emptyRequest);

  useEffect(() => {
    if (enabledModules.includes(PortalComponentType.ATLAS) && atlasClient) {
      fetchAtlasCohortDefinitions(atlasClient)
        .then((r) => setAtlasOptions(r))
        .catch();
    }
    if (deckClient) {
      fetchPhenotypes(deckClient)
        .then((r) => {
          setPhenotypes(r);
        })
        .catch(() =>
          doAlert("error", "Failed to load available clinical definitions"),
        );
    }
  }, []);

  const switchTab = (event: SyntheticEvent, newValue: number) => {
    setCohortDefinition(emptyRequest);
    setSelectedTab(newValue);
    setJsonObject(undefined);
  };

  function selectAtlasOption(selected: AtlasListing | null) {
    if (selected && atlasClient) {
      fetchAtlasCohortDefinition(selected.id, atlasClient)
        .then((r) => {
          const cd = {
            parentName: r.name,
            name: "initial version",
            source: "Atlas",
            humanReadable: "",
            cohortExpression: r.expression,
          };
          setCohortDefinition(cd);
          setJsonObject(JSON.parse(r.expression));
        })
        .catch(() =>
          doAlert("error", `Failed to retrieve ${selected.name} from Atlas`),
        );
    } else {
      setCohortDefinition(emptyRequest);
      setJsonObject(undefined);
    }
  }

  function handleClose() {
    setFilename(undefined);
    setJsonObject(undefined);
    setCohortDefinition(emptyRequest);
    setOpen(false);
  }

  function updateCDDescription(description: string) {
    cohortDefinition.description = description;
  }

  function updateCDName(name: string) {
    cohortDefinition.parentName = name;
  }

  function updateCDVersionName(name: string) {
    cohortDefinition.name = name;
  }

  function updateCDSource(source: string) {
    cohortDefinition.source = source;
  }

  function updateCDURL(url: string) {
    cohortDefinition.url = url;
  }

  function save() {
    if (cohortDefinition.name && deckClient) {
      saveCohortDefinition(cohortDefinition, deckClient, version, parentId)
        .then(() => {
          doAlert(
            "success",
            `Imported cohort definition ${cohortDefinition.name}`,
          );
          handleClose();
        })
        .catch((err) => {
          const r = err.response;
          doAlert(
            "error",
            r.status === 400
              ? r.data.message
              : "Failed to import cohort definition",
          );
        });
    } else {
      doAlert("error", "Cohort definition name is required");
    }
  }

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files || !e.target.files[0]) {
      return;
    }
    const file = e.target.files[0];
    const { name } = file;
    setFilename(name);

    const reader = new FileReader();
    reader.onload = (evt) => {
      if (!evt?.target?.result) {
        return;
      }
      try {
        const expression = evt.target.result;
        cohortDefinition.cohortExpression = jsonminify(expression);
        const records = JSON.parse(expression as string);
        setJsonObject(records);
      } catch (e) {
        alert("Selected file does not contain valid JSON");
        cohortDefinition.cohortExpression = "";
        setFilename(undefined);
        setJsonObject(undefined);
      }
    };
    reader.readAsBinaryString(file);
  };

  return (
    <div>
      <div>
        <Dialog
          open={open}
          onClose={handleClose}
          PaperProps={{ sx: { minHeight: "78vh" } }}
          fullWidth
          maxWidth={"sm"}
        >
          <DialogTitle>Import Cohort Definition</DialogTitle>
          <Tabs
            value={selectedTab}
            onChange={switchTab}
            aria-label="basic tabs"
          >
            <Tab label="JSON" {...a11yProps(0)} />
            <Tab label="ATLAS" {...a11yProps(1)} />
            <Tab label="API" {...a11yProps(2)} />
          </Tabs>
          <DialogContent>
            <TabPanel value={selectedTab} index={0}>
              <TextField
                style={{ marginBottom: "1em" }}
                variant="outlined"
                fullWidth
                label="Name"
                onChange={(event) => updateCDName(event.target.value)}
              />
              <TextField
                style={{ marginBottom: "1em" }}
                variant="outlined"
                fullWidth
                label="Version name"
                onChange={(event) => updateCDVersionName(event.target.value)}
              />
              <TextField
                style={{ marginBottom: "1em" }}
                multiline
                label={"Description"}
                variant="outlined"
                fullWidth
                onChange={(e) => updateCDDescription(e.target.value)}
              />
              <TextField
                style={{ marginBottom: "1em" }}
                variant={"outlined"}
                fullWidth
                label="Source"
                onChange={(event) => updateCDSource(event.target.value)}
              />
              <TextField
                style={{ marginBottom: "1em" }}
                id="url"
                label="External URL"
                type="url"
                fullWidth
                variant="outlined"
                onChange={(event) => updateCDURL(event.target.value)}
              />
              <Autocomplete
                hidden
                multiple
                options={phenotypes.map((p) => p.name)}
                value={linkedPhenotypes}
                onChange={(e, items) => {
                  if (items) {
                    setLinkedPhenotypes([...items]);
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Phenotype" />
                )}
              ></Autocomplete>
              <Button component="label" variant={"outlined"}>
                Select {jsonObject && " other "} File
                <input type="file" hidden onChange={handleFileUpload} />
              </Button>
              {jsonObject && (
                <JSONContent filename={filename} data={jsonObject} />
              )}
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              {enabledModules.includes(PortalComponentType.ATLAS) ? (
                <div>
                  <Autocomplete
                    filterOptions={filterOptions}
                    options={atlasOptions}
                    style={{ marginBottom: "1em" }}
                    getOptionLabel={(o) => o.name}
                    onChange={(e, item) => {
                      selectAtlasOption(item);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Search Atlas" />
                    )}
                  ></Autocomplete>
                  <TextField
                    style={{ marginBottom: "1em" }}
                    variant="outlined"
                    fullWidth
                    label="Version name"
                    onChange={(event) =>
                      updateCDVersionName(event.target.value)
                    }
                  />
                  <TextField
                    style={{ marginBottom: "1em" }}
                    multiline
                    label={"Description"}
                    variant="outlined"
                    fullWidth
                    onChange={(e) => updateCDDescription(e.target.value)}
                  />
                  <TextField
                    style={{ marginBottom: "1em" }}
                    id="url"
                    label="External URL"
                    type="url"
                    fullWidth
                    variant="outlined"
                    onChange={(event) => updateCDURL(event.target.value)}
                  />
                  {jsonObject && <JSONContent data={jsonObject} />}
                </div>
              ) : (
                <div>Enable Atlas in settings to import from Atlas</div>
              )}
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
              <CheckDocsTab />
            </TabPanel>
          </DialogContent>
          <DialogActions
            style={{ justifyContent: "space-between", paddingLeft: "1em" }}
          >
            <Button onClick={handleClose}>Close</Button>
            {selectedTab !== 2 && <Button onClick={save}>Save</Button>}
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
}
