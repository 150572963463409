import axios from "axios";

import { SemanticServiceConcept } from "../@types/semantic-service";

const base = "https://emc-mi-notebooks.nl/v2";

const client = axios.create({ baseURL: base });

export const doMedDRALookup = async (
  value: string,
  vocab: string,
): Promise<string[]> => {
  return client
    .get<{ terms: string[] }>(
      `/concepts/lookup?vocabularies=${vocab}&query=${value}`,
    )
    .then((resp) => {
      return resp.data?.terms ? resp.data.terms : [""];
    })
    .catch((err) => {
      throw err;
    });
};

export const normalize = async (
  value: string,
  vocabulary: string,
): Promise<SemanticServiceConcept[]> => {
  return client
    .get<{ concepts: SemanticServiceConcept[] }>(
      `/concepts/normalize?vocabularies=${vocabulary}&term=${value}`,
    )
    .then((resp) => {
      return resp.data.concepts;
    })
    .catch((err) => {
      throw err;
    });
};
