import WarningIcon from "@mui/icons-material/Warning";
import * as React from "react";
import { useContext, useEffect, useState } from "react";

import { CohortDefinition, ConceptSet } from "../../../../@types/service";
import { EditableConceptSetTable } from "../../../../components/EditableConceptSetTable";
import { AppContext, AppContextProps } from "../../../../context/AppContext";
import {
  assignConceptSetVersion,
  fetchConceptSetsForCohortDefinition,
} from "../../../../service/CohortDefinitionService";

export default function ConceptSets(props: {
  cohortDefinition: CohortDefinition;
}) {
  const { cohortDefinition } = props;
  const { doAlert, deckClient } = useContext(AppContext) as AppContextProps;
  const [conceptSets, setConceptSets] = useState<ConceptSet[]>([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (deckClient) {
      fetchConceptSetsForCohortDefinition(cohortDefinition.id, deckClient)
        .then((r) => setConceptSets(r))
        .catch(() => doAlert("warning", "concept sets"));
    }
  }, [refresh]);

  function setConceptSetVersion(oldId: number, newId: number | undefined) {
    if (cohortDefinition.id && newId && deckClient) {
      assignConceptSetVersion(cohortDefinition.id, oldId, newId, deckClient)
        .then(() => setRefresh(!refresh))
        .catch(() => doAlert("error", "Failed to update concept set version"));
    }
  }

  return (
    <div className={"DataGrids-wrapper DataGrids-wrapper__tab"}>
      <div style={{ padding: "1em" }}>
        {conceptSets.filter((cs) => cs.type === "MASTER").length > 0 && (
          <div className={"Icons-wrapper__align"}>
            <WarningIcon color={"error"} style={{ marginRight: "1em" }} />
            <b>
              {conceptSets.filter((cs) => cs.type === "MASTER").length} concept
              set
              {conceptSets.filter((cs) => cs.type === "MASTER").length === 1
                ? " has "
                : "s have "}
              no specific version assigned to this cohort definition
            </b>
          </div>
        )}
      </div>
      <EditableConceptSetTable
        conceptSets={conceptSets}
        saveFunction={setConceptSetVersion}
      />
    </div>
  );
}
