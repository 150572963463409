import { AxiosInstance } from "axios";

import { HomePageData, Tag, Task, User } from "../@types/service";

export const fetchUser = async (client: AxiosInstance): Promise<User> => {
  return client
    .get<User>(`/users/me`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const fetchAllUser = async (client: AxiosInstance): Promise<User[]> => {
  return client
    .get<User[]>(`/users`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const fetchTasks = async (
  client: AxiosInstance,
): Promise<HomePageData> => {
  return client
    .get<HomePageData>(`/users/tasks`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const postTask = async (tasks: Task[], client: AxiosInstance) => {
  return client
    .post<void>(`/users/tasks`, tasks)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const fetchTags = async (
  client: AxiosInstance,
): Promise<Tag[]> => {
  return client
    .get<Tag[]>(`/users/tags`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};