import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useParams } from "react-router-dom";

import { CohortDefinition } from "../../../@types/service";
import PageTitle from "../../../components/PageTitle";
import { TabPanel, a11yProps } from "../../../components/TabPanel";
import { AppContext, AppContextProps } from "../../../context/AppContext";
import { fetchCohortDefinitionVersion } from "../../../service/CohortDefinitionService";
import ConceptSets from "./tabs/ConceptSets";
import Overview from "./tabs/Overview";
import Results from "./tabs/Results";

export const CohortDefinitionVersionDetail = () => {
  const { parentId, versionId } = useParams();
  const { doAlert, setTitle, deckClient } = useContext(
    AppContext,
  ) as AppContextProps;
  const [value, setValue] = useState(0);
  const [cohortDefinition, setCohortDefinition] = useState<
    CohortDefinition | undefined
  >(undefined);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const client = deckClient;

  useEffect(() => {
    loadVersion();
  }, []);

  function loadVersion() {
    if (client) {
      fetchCohortDefinitionVersion(Number(parentId), Number(versionId), client)
        .then((r) => {
          setCohortDefinition(r);
          setTitle(
            <PageTitle
              parentPath={"/cohort-definitions"}
              parent={"Cohort Definitions"}
              childPath={`/cohort-definitions/${parentId}`}
              child={r.parentName ? r.parentName : r.name}
              grandChild={r.name}
            />,
          );
        })
        .catch(() => {
          doAlert("error", "Failed to load concept sets");
        });
    }
  }

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      {cohortDefinition && (
        <Box sx={{ width: "100%", paddingTop: 0 }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs">
              <Tab label="Overview" {...a11yProps(0)} />
              <Tab label="Details" {...a11yProps(1)} />
              <Tab label="Concept Sets" {...a11yProps(2)} />
              <Tab label="Results" {...a11yProps(3)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Overview cd={cohortDefinition} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ReactMarkdown>{cohortDefinition.humanReadable}</ReactMarkdown>
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ConceptSets cohortDefinition={cohortDefinition} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <Results cohortDefinition={cohortDefinition} />
          </TabPanel>
        </Box>
      )}
    </Box>
  );
};

export default CohortDefinitionVersionDetail;
