import axios, { AxiosInstance } from "axios";
import React, {
  PropsWithChildren,
  ReactElement,
  createContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useAuth } from "react-oidc-context";

import {
  PortalComponent,
  PortalComponentType,
} from "../@types/portal-component";
import { User } from "../@types/service";
import { fetchSettings, updateModule } from "../service/SettingsService";
import { fetchUser } from "../service/UserService";

const base = window._env_.BACKEND_BASE_URL;

export type AppContextProps = {
  doAlert(severity: "success" | "error" | "warning" | "info", message: string): void;
  severity: "success" | "error" | "warning" | "info";
  message: string;
  alertOpen: boolean;
  title: ReactElement;
  setTitle(title: ReactElement): void;
  closeAlert: () => void;
  client: AxiosInstance;
  deckClient: AxiosInstance | undefined;
  atlasClient: AxiosInstance | undefined;
  codeListGeneratorClient: AxiosInstance | undefined;
  currentUser: User;
  enabledModules: PortalComponentType[];
  setEnabledModules(updatedModules: PortalComponentType[]): void;
  modules: PortalComponent[];
  setModules(updatedModules: PortalComponent[]): void;
};

export const AppContext = createContext<AppContextProps | null>(null);

const AppProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const auth = useAuth();
  const [token, setToken] = useState("");
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [severity, setSeverity] = useState<"success" | "error" | "info" | "warning">("success");
  const [message, setMessage] = useState<string>("");
  const [title, setTitle] = useState<ReactElement>(<div>Home</div>);
  const [modules, setModules] = useState<PortalComponent[]>([]);
  const [enabledModules, setEnabledModules] = useState<PortalComponentType[]>(
    [],
  );
  const [currentUser, setCurrentUser] = useState<User>({ name: "", email: "" });

  const doAlert = (
    severity: "success" | "error" | "info" | "warning",
    message: string,
  ) => {
    message = severity === "warning" ? `Failed to load ${message}` : message;
    setMessage(message);
    setSeverity(severity);
    setAlertOpen(true);
  };

  function closeAlert() {
    setAlertOpen(false);
  }

  const client = axios.create({
    baseURL: base,
    headers: { Authorization: `Bearer ${auth.user?.access_token}` },
  });

  const createClient = (
    component: PortalComponentType,
  ): AxiosInstance | undefined => {
    const isEnabled = enabledModules.includes(component);
    if (isEnabled) {
      const baseUrl = getEndpoint(component);
      if (baseUrl && token) {
        return axios.create({
          baseURL: baseUrl,
          headers: { Authorization: `Bearer ${token}` },
        });
      }
    }
  };

  function refreshModules() {
    if (token) {
      fetchSettings(client).then((r) => {
        setModules(r);
      });
    }
  }

  useEffect(() => {
    const _token = auth.user?.access_token;
    if (_token && _token !== token) {
      setToken(_token);
    }
  }, [auth.user]);

  useEffect(() => {
    // modules must be loaded
    if (modules.length > 0) {
      modules.forEach((m) => {
        const status = enabledModules.includes(m.name);
        if (status !== m.enabled) {
          m.enabled = status;
          updateModule(m, client)
            .then(() => {
              refreshModules();
            })
            .catch(() => doAlert("error", "Something went wrong..."));
        }
      });
    } else {
      refreshModules();
    }
  }, [enabledModules]);

  useEffect(() => {
    if (token) {
      fetchUser(client)
        .then((r) => {
          setCurrentUser(r);
        })
        .catch(() => {
          // do nothing
        });
      fetchSettings(client).then((r) => {
        setModules(r);
      });
    }
  }, [token]);

  function getEndpoint(component: PortalComponentType) {
    return modules.filter((c) => c.name === component)[0]?.endpoint;
  }

  const deckClient = createClient(PortalComponentType.PHENOTYPES);
  const atlasClient = createClient(PortalComponentType.ATLAS);
  const codeListGeneratorClient = createClient(
    PortalComponentType.CODELIST_GENERATOR,
  );

  useEffect(() => {
    if (modules.length > 0) {
      const enabled = modules.filter((m) => m.enabled).map((m) => m.name);
      setEnabledModules(enabled);
    } else {
      refreshModules();
    }
  }, [modules, token]);

  const foo = useMemo(
    () => ({
      modules,
      setModules,
      enabledModules,
      setEnabledModules,
      doAlert,
      severity,
      message,
      alertOpen,
      setTitle,
      closeAlert,
      title,
      client,
      deckClient,
      atlasClient,
      codeListGeneratorClient,
      currentUser,
    }),
    [enabledModules, title, token, message, alertOpen],
  );

  return <AppContext.Provider value={foo}>{children}</AppContext.Provider>;
};

export default AppProvider;
