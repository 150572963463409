import { AxiosInstance } from "axios";

import { SearchOptions } from "../@types/service";

export const fetch = async (
  client: AxiosInstance,
): Promise<SearchOptions[]> => {
  return client
    .get<SearchOptions[]>(`/search`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};
