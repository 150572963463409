import { User } from "./service";

export type PortalComponent = {
  id: number;
  name: PortalComponentType;
  endpoint?: string;
  enabled: boolean;
  global: boolean;
  owner: User;
  sharedWith?: User[];
};

export enum PortalComponentType {
  ATLAS = "ATLAS",
  PHENOTYPES = "PHENOTYPES",
  CODELIST_GENERATOR = "CODELIST_GENERATOR",
}
