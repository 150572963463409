import React from "react";

export const DateBlock = (props: { label: string; date?: Date }) => {
  const { label, date } = props;
  if (date) {
    return (
      <div style={{ padding: "1em" }}>
        <b>{label}</b>
        <div style={{ paddingTop: "0.5em" }}>
          {new Date(date).toLocaleString()}
        </div>
      </div>
    );
  } else {
    return <div />;
  }
};

export default DateBlock;
