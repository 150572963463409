import { Autocomplete, Checkbox, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import React, { Dispatch, SetStateAction, useContext, useState } from "react";

import { SemanticServiceConcept } from "../../../@types/semantic-service";
import { AppContext, AppContextProps } from "../../../context/AppContext";
import { addItemsToSet } from "../../../service/ConceptSetService";
import { filterOptions } from "../../../service/FilterService";
import { doMedDRALookup, normalize } from "../../../service/SemanticService";

interface AddConceptProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  conceptSetId: number;
  refresh: boolean;
  setRefresh: Dispatch<SetStateAction<boolean>>;
}

export default function AddConcept(props: AddConceptProps) {
  const { setOpen, open, conceptSetId, refresh, setRefresh } = props;
  const { doAlert, deckClient } = useContext(AppContext) as AppContextProps;
  const [searchOptions, setSearchOptions] = useState<{ name: string }[]>([]);
  const [selected, setSelected] = useState<SemanticServiceConcept[]>([]);
  const [vocab, setVocab] = useState<string | undefined>(undefined);
  const [checkedBoxes, setCheckedBoxes] = useState<number[]>([]);

  function handleClose() {
    setOpen(false);
    setVocab(undefined);
    setCheckedBoxes([]);
    setSelected([]);
  }

  const searchMedDRA = (value: string) => {
    if (value && vocab) {
      doMedDRALookup(value, vocab).then((r) => {
        const arr: { name: string }[] = [];
        r.forEach((str) => arr.push({ name: str }));
        setSearchOptions(arr);
      });
    }
  };

  function isChecked(id: number | undefined) {
    return id ? checkedBoxes.includes(id) : false;
  }

  function check(id: number) {
    const presentlyChecked = checkedBoxes.includes(id);
    if (presentlyChecked) {
      const copy = [...checkedBoxes].filter((i) => i !== id);
      setCheckedBoxes(copy);
    } else {
      const copy = [...checkedBoxes];
      copy.push(id);
      setCheckedBoxes(copy);
    }
  }

  const handleOptionSelect = (conceptName: string) => {
    if (conceptName && vocab) {
      normalize(conceptName, vocab).then((resp) => {
        setSelected(resp);
      });
    }
  };

  function addConceptsToSet() {
    if (deckClient) {
      addItemsToSet(conceptSetId, checkedBoxes, deckClient)
        .then(() => {
          doAlert("success", "added concepts");
          setRefresh(!refresh);
          handleClose();
        })
        .catch(() => {
          doAlert("error", "failed to add concepts phone Sicco");
          handleClose();
        });
    }
  }

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth={"sm"}
        PaperProps={{ sx: { minHeight: "80vh" } }}
      >
        <DialogTitle>Add Concept</DialogTitle>
        <DialogContent>
          <div style={{ paddingBottom: "1em" }}>
            <FormControl>
              <FormLabel id="demo-row-radio-buttons-group-label">
                Vocabulary
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                onChange={(event, value) => setVocab(value)}
              >
                <FormControlLabel
                  value="MEDDRA"
                  control={<Radio />}
                  label="MedDRA"
                />
                <FormControlLabel
                  disabled
                  value="RXNORM"
                  control={<Radio />}
                  label="RxNORM"
                />
                <FormControlLabel
                  value="SNOMED"
                  control={<Radio />}
                  label="SNOMED"
                />
              </RadioGroup>
            </FormControl>
          </div>
          <Autocomplete
            disablePortal={true}
            onInputChange={(_event, value) => {
              if (value && value.length > 3) {
                searchMedDRA(value);
              }
            }}
            onChange={(_event, value) => {
              if (value) {
                handleOptionSelect(value.name);
              }
            }}
            options={searchOptions}
            filterOptions={filterOptions}
            getOptionLabel={(o) => o.name}
            // placeholder="Search for a concept"
            renderInput={(params) => <TextField {...params} label="Concept" />}
          />
          {selected.map((c) => (
            <div style={{ paddingTop: "1em" }} key={c.id}>
              <Grid container>
                <Grid item xs={1}>
                  <Checkbox
                    checked={isChecked(c.id)}
                    onChange={() => c.id && check(c.id)}
                    className={"Icon"}
                  />{" "}
                </Grid>
                <Grid item xs={11}>
                  <b>{c.name}</b>
                  <Grid container sx={{ paddingTop: "0.5em" }}>
                    <Grid item xs={4}>
                      domain
                    </Grid>
                    <Grid item xs={8}>
                      {c.domain}
                    </Grid>
                    <Grid item xs={4}>
                      concept class
                    </Grid>
                    <Grid item xs={8}>
                      {c.concept_class}
                    </Grid>
                    <Grid item xs={4}>
                      concept id
                    </Grid>
                    <Grid item xs={8}>
                      {c.id}
                    </Grid>
                    <Grid item xs={4}>
                      concept code
                    </Grid>
                    <Grid item xs={8}>
                      {c.code}
                    </Grid>
                    {c.invalid_reason && (
                      <Grid item xs={4}>
                        invalid reason
                      </Grid>
                    )}
                    {c.invalid_reason && (
                      <Grid item xs={8}>
                        {c.invalid_reason}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </div>
          ))}
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "space-between", paddingLeft: "1em" }}
        >
          <Button onClick={handleClose}>Close</Button>
          <Button
            onClick={addConceptsToSet}
            disabled={checkedBoxes.length === 0}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
