import { AxiosInstance } from "axios";

import {
  ClinicalDefinition,
  CohortDefinition,
  Comment,
  ConceptSet,
  User,
} from "../@types/service";

const basePath = "/clinicalDefinitions";

export const fetchPhenotypes = async (
  client: AxiosInstance,
  conceptSetId?: number,
): Promise<ClinicalDefinition[]> => {
  return client
    .get<ClinicalDefinition[]>(`${basePath}`, {
      params: conceptSetId && { conceptSet: conceptSetId },
    })
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const fetchPhenotype = async (
  id: number,
  client: AxiosInstance,
): Promise<ClinicalDefinition> => {
  return client
    .get<ClinicalDefinition>(`${basePath}/${id}`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const fetchConceptSetsForPhenotype = async (
  id: number | undefined,
  client: AxiosInstance,
): Promise<ConceptSet[]> => {
  return client
    .get<ConceptSet[]>(`${basePath}/${id}/concept-sets`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const fetchCohortDefinitionsForClinicalDescription = async (
  id: number | undefined,
  client: AxiosInstance,
): Promise<CohortDefinition[]> => {
  return client
    .get<CohortDefinition[]>(`${basePath}/${id}/cohort-definitions`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const assignConceptSetVersion = async (
  phenotypeId: number,
  oldVersionId: number,
  newVersionId: number,
  client: AxiosInstance,
): Promise<void> => {
  return client
    .put<void>(
      `${basePath}/${phenotypeId}/concept-sets/${oldVersionId}/update/${newVersionId}`,
    )
    .then(() => {})
    .catch((err) => {
      throw err;
    });
};

export const assignCohortDefinitionVersion = async (
  phenotypeId: number,
  oldVersionId: number,
  newVersionId: number,
  client: AxiosInstance,
): Promise<void> => {
  return client
    .put<void>(
      `${basePath}/${phenotypeId}/cohort-definitions/${oldVersionId}/update/${newVersionId}`,
    )
    .then(() => {})
    .catch((err) => {
      throw err;
    });
};

export const updatePhenotype = async (
  p: ClinicalDefinition,
  client: AxiosInstance,
): Promise<ClinicalDefinition> => {
  return client
    .put(`${basePath}/${p.id}`, p)
    .then((r) => {
      return r.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const submitPhenotype = async (
  r: ClinicalDefinition,
  pis: User[],
  client: AxiosInstance,
) => {
  const descriptors = r.clinicalDescriptors;
  return client
    .post<void>(`${basePath}`, {
      name: r.name,
      principalInvestigators: pis,
      studyId: r.studyId,
      description: r.description,
      status: "Pending",
      deadline: r.deadline,
      clinicalDescriptors: descriptors,
    })
    .then(() => {})
    .catch((err) => {
      throw err;
    });
};

export const addConceptSet = async (
  phenotypeId: number,
  conceptSet: ConceptSet,
  client: AxiosInstance,
) => {
  return client
    .post<void>(`${basePath}/${phenotypeId}/add-concept-set`, conceptSet)
    .then(() => {})
    .catch((err) => {
      throw err;
    });
};

export const addCohortDefinition = async (
  phenotypeId: number,
  cohortDefinition: CohortDefinition,
  client: AxiosInstance,
) => {
  return client
    .post<void>(
      `${basePath}/${phenotypeId}/add-cohort-definition`,
      cohortDefinition,
    )
    .then(() => {})
    .catch((err) => {
      throw err;
    });
};

export const fetchComments = async (
  id: number,
  client: AxiosInstance,
): Promise<Comment[]> => {
  return client
    .get<Comment[]>(`${basePath}/${id}/comments`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const postComment = async (
  id: number,
  comment: string,
  client: AxiosInstance,
) => {
  return client
    .post<void>(`${basePath}/${id}/comments`, { comment })
    .then(() => {})
    .catch((err) => {
      throw err;
    });
};

export const fetchSuggestions = async (
  phenotype: string,
  client: AxiosInstance,
) => {
  return client
    .get<string>(`${basePath}/descriptor-suggestions`, {
      params: { phenotype: phenotype },
    })
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};
