import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";

import { ClinicalDefinition } from "../../@types/service";
import PageTitle from "../../components/PageTitle";
import { TabPanel, a11yProps } from "../../components/TabPanel";
import { AppContext, AppContextProps } from "../../context/AppContext";
import { fetchPhenotype } from "../../service/PhenotypeService";
import ClinicalDescriptors from "./tabs/ClinicalDescriptors";
import CohortDefinitions from "./tabs/CohortDefinitions";
import ConceptSets from "./tabs/ConceptSets";
import Discussion from "./tabs/Discussion";
import Overview from "./tabs/Overview";

export const RequestDetail = () => {
  const { id } = useParams();
  const conceptSetId = Number(id);
  const { doAlert, setTitle, deckClient } = useContext(
    AppContext,
  ) as AppContextProps;
  const [value, setValue] = useState(0);
  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [phenotype, setPhenotype] = useState<ClinicalDefinition | undefined>(
    undefined,
  );
  const client = deckClient;

  useEffect(() => {
    loadRequest();
  }, []);

  useEffect(() => {
    if (phenotype) {
      setTitle(
        <PageTitle
          parentPath={"/clinical-definitions"}
          parent={"Clinical Definitions"}
          child={phenotype.name}
        />,
      );
    }
  }, [phenotype]);

  function loadRequest() {
    if (client) {
      fetchPhenotype(conceptSetId, client)
        .then((r) => {
          setPhenotype(r);
        })
        .catch(() => {
          doAlert("error", "Failed to load concept sets");
        });
    }
  }

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      {phenotype?.id && (
        <Box sx={{ width: "100%", paddingTop: 0 }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs">
              <Tab label="Overview" {...a11yProps(0)} />
              <Tab label="Clinical Description" {...a11yProps(1)} />
              <Tab label="Concept Sets" {...a11yProps(2)} />
              <Tab label="Cohort Definitions" {...a11yProps(3)} />
              <Tab label="Discussion" {...a11yProps(4)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <Overview phenotype={phenotype} setPhenotype={setPhenotype} />
          </TabPanel>
          <TabPanel index={1} value={value}>
            <ClinicalDescriptors
              phenotype={phenotype}
              setPhenotype={setPhenotype}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ConceptSets phenotypeId={phenotype.id} />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <CohortDefinitions phenotypeId={phenotype.id} />
          </TabPanel>
          <TabPanel value={value} index={4}>
            <Discussion phenotypeId={phenotype.id} />
          </TabPanel>
        </Box>
      )}
    </Box>
  );
};

export default RequestDetail;
