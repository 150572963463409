import { AxiosInstance } from "axios";

import {
  CohortDefinition,
  CohortDefinitionResult,
  ConceptSet,
} from "../@types/service";

const basePath = "/cohorts";

const mapToObj = (m: Map<string, string>) => {
  return Array.from(m).reduce((obj: any, [key, value]) => {
    obj[key] = value;
    return obj;
  }, {});
};

export const fetchCohortDefinitions = async (
  client: AxiosInstance,
): Promise<CohortDefinition[]> => {
  return client
    .get<CohortDefinition[]>(`${basePath}`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const fetchCohortDefinition = async (
  id: number | undefined,
  client: AxiosInstance,
): Promise<CohortDefinition> => {
  return client
    .get<CohortDefinition>(`${basePath}/${id}`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const fetchCohortDefinitionVersion = async (
  parentId: number | undefined,
  versionId: number | undefined,
  client: AxiosInstance,
): Promise<CohortDefinition> => {
  return client
    .get<CohortDefinition>(`${basePath}/${parentId}/versions/${versionId}`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const saveCohortDefinition = async (
  cohortDefinition: CohortDefinition,
  client: AxiosInstance,
  version?: boolean | undefined,
  parentId?: number,
) => {
  const params = version ? `?version=true&parentId=${parentId}` : "";
  return client
    .post<void>(`${basePath}${params}`, cohortDefinition)
    .then(() => {})
    .catch((err) => {
      throw err;
    });
};

export const saveCohortDefinitionResult = async (
  id: number,
  result: CohortDefinitionResult,
  client: AxiosInstance,
) => {
  const d = result.details ? mapToObj(result.details) : undefined;
  return client
    .post<void>(`${basePath}/${id}/results`, {
      database: result.database,
      details: d,
      runDate: result.runDate,
      reviewer: result.reviewer,
    })
    .then(() => {})
    .catch((err) => {
      throw err;
    });
};

export const fetchCohortDefinitionResults = async (
  id: number,
  client: AxiosInstance,
): Promise<CohortDefinitionResult[]> => {
  return client
    .get<CohortDefinitionResult[]>(`${basePath}/${id}/results`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const updateCohortDefinition = async (
  cohortDefinition: CohortDefinition,
  client: AxiosInstance,
) => {
  return client
    .put<void>(`${basePath}/${cohortDefinition.id}`, cohortDefinition)
    .then(() => {})
    .catch((err) => {
      throw err;
    });
};

export const deleteCohortDefinition = async (
  id: number,
  client: AxiosInstance,
) => {
  return client
    .delete<void>(`${basePath}/${id}`)
    .then(() => {})
    .catch((err) => {
      throw err;
    });
};

export const updateCohortDefinitionVersion = async (
  version: CohortDefinition,
  parentId: number | undefined,
  client: AxiosInstance,
) => {
  return client
    .put<void>(`${basePath}/${parentId}/versions/${version.id}`, version)
    .then(() => {})
    .catch((err) => {
      throw err;
    });
};

export const fetchConceptSetsForCohortDefinition = async (
  id: number | undefined,
  client: AxiosInstance,
): Promise<ConceptSet[]> => {
  return client
    .get<ConceptSet[]>(`${basePath}/${id}/concept-sets`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const assignConceptSetVersion = async (
  cohortId: number,
  oldVersionId: number,
  newVersionId: number,
  client: AxiosInstance,
): Promise<void> => {
  return client
    .put<void>(
      `${basePath}/${cohortId}/concept-sets/${oldVersionId}/update/${newVersionId}`,
    )
    .then(() => {})
    .catch((err) => {
      throw err;
    });
};
