import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ConceptSet } from "../../@types/service";
import ButtonWithIcon from "../../components/ButtonWithIcon";
import PageTitle from "../../components/PageTitle";
import SaveVersion from "../../components/SaveVersion";
import { TabPanel, a11yProps } from "../../components/TabPanel";
import { AppContext, AppContextProps } from "../../context/AppContext";
import {
  closeReview,
  fetchConceptSet,
  openReview,
} from "../../service/ConceptSetService";
import AddConcept from "./tabs/AddConcept";
import Concepts from "./tabs/Concepts";
import Logs from "./tabs/Logs";
import ConceptSetOverview from "./tabs/Overview";
import Reviews from "./tabs/Reviews";
import Versions from "./tabs/Versions";

export const ConceptSetsDetail = () => {
  const { id } = useParams();
  const { doAlert, setTitle, deckClient } = useContext(
    AppContext,
  ) as AppContextProps;
  const [selectedTab, setSelectedTab] = useState(0);
  const [conceptSet, setConceptSet] = useState<ConceptSet | undefined>(
    undefined,
  );
  const [versionDialogOpen, setVersionDialogOpen] = useState(false);
  const [refreshVersions, setRefreshVersions] = useState(false);
  const [addingConcept, setAddingConcept] = useState<boolean>(false);
  const [refreshConcepts, setRefreshConcepts] = useState<boolean>(false);

  useEffect(() => {
    setSelectedTab(0);
    loadConceptSet();
  }, [id]);

  const openVersionSaveDialog = () => {
    setVersionDialogOpen(true);
  };

  const switchTab = (event: SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const loadConceptSet = () => {
    if (deckClient) {
      fetchConceptSet(Number(id), deckClient)
        .then((r) => {
          setConceptSet(r);
          setTitle(
            <PageTitle
              parentPath={"/concept-sets"}
              parent={"Concept Sets"}
              childPath={r.version && `/concept-sets/${r.version?.parentId}`}
              child={r.name}
              grandChild={r.version?.version}
            />,
          );
        })
        .catch(() => {
          doAlert("error", "Failed to load concept sets");
        });
    }
  };

  const close = () => {
    if (conceptSet && deckClient) {
      closeReview(conceptSet?.id, deckClient)
        .then(() => {
          doAlert("success", "Concept set review marked as done");
          loadConceptSet();
        })
        .catch(() =>
          doAlert("error", "Failed to mark concept set review as done"),
        );
    }
  };

  const open = () => {
    if (conceptSet && deckClient) {
      openReview(conceptSet.id, deckClient)
        .then(() => {
          doAlert("success", "Concept set review opened");
          loadConceptSet();
        })
        .catch(() => doAlert("error", "Failed to re-open concept set review"));
    }
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      {conceptSet && (
        <div>
          <Box sx={{ width: "100%", paddingTop: 0 }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={selectedTab}
                onChange={switchTab}
                aria-label="basic tabs"
              >
                <Tab label="Overview" {...a11yProps(0)} />
                <Tab label="Concepts" {...a11yProps(1)} />
                {conceptSet.mutable && <Tab label="Log" {...a11yProps(2)} />}
                {conceptSet.type === "MASTER" && (
                  <Tab label="Versions" {...a11yProps(3)} />
                )}
                {conceptSet.type === "MASTER" && (
                  <Tab label="Reviews" {...a11yProps(4)} />
                )}
              </Tabs>
            </Box>
            <TabPanel value={selectedTab} index={0}>
              <ConceptSetOverview
                id={id ? id : "oops..."}
                conceptSet={conceptSet}
                setConceptSet={setConceptSet}
              />
            </TabPanel>
            <TabPanel value={selectedTab} index={1}>
              <Concepts
                conceptSet={conceptSet}
                closed={!conceptSet.mutable}
                refresh={refreshConcepts}
                setRefresh={setRefreshConcepts}
              />
            </TabPanel>
            <TabPanel value={selectedTab} index={2}>
              <Logs conceptSetId={conceptSet.id} />
            </TabPanel>
            <TabPanel value={selectedTab} index={3}>
              <Versions
                id={conceptSet.id}
                refreshVersions={refreshVersions}
                setRefreshVersions={setRefreshVersions}
              />
            </TabPanel>
            <TabPanel value={selectedTab} index={4}>
              <Reviews conceptSet={conceptSet} />
            </TabPanel>
          </Box>
          <div
            style={{
              width: "100%",
              marginTop: "auto",
              paddingTop: "1em",
            }}
          >
            {(selectedTab === 0 || selectedTab === 1 || selectedTab === 2) &&
              (conceptSet.type === "MASTER" ||
                conceptSet.type === "BRANCH") && (
                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      justifyContent: "flex-start",
                      verticalAlign: "middle",
                      width: "100%",
                    }}
                  >
                    {selectedTab === 1 && (
                      <ButtonWithIcon
                        label={"Add Concept"}
                        icon={<AddIcon />}
                        onClick={() => setAddingConcept(true)}
                      />
                    )}
                  </div>
                  <div
                    style={{
                      justifyContent: "flex-end",
                      marginLeft: "auto",
                    }}
                  >
                    <ButtonWithIcon
                      label={"Save as specific version"}
                      icon={<SaveIcon />}
                      onClick={openVersionSaveDialog}
                    />
                  </div>
                  {versionDialogOpen && (
                    <SaveVersion
                      open={versionDialogOpen}
                      setOpen={setVersionDialogOpen}
                      id={conceptSet.id}
                      name={conceptSet.name}
                      refreshVersions={refreshVersions}
                      setRefreshVersions={setRefreshVersions}
                    />
                  )}

                  <AddConcept
                    open={addingConcept}
                    setOpen={setAddingConcept}
                    conceptSetId={conceptSet.id}
                    refresh={refreshConcepts}
                    setRefresh={setRefreshConcepts}
                  />
                </div>
              )}
            {conceptSet.type === "REVIEW_COPY" && (
              <div>
                {" "}
                {conceptSet.mutable ? (
                  <Button variant={"outlined"} onClick={close}>
                    Finish Review
                  </Button>
                ) : (
                  <Button variant={"outlined"} onClick={open}>
                    Reopen Review
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </Box>
  );
};

export default ConceptSetsDetail;
