import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import DeleteIcon from "@mui/icons-material/Delete";
import TagIcon from '@mui/icons-material/LocalOffer';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Autocomplete, Divider, Menu, MenuItem } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { DataGrid } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
import { CSVLink } from "react-csv";

import { ConceptSet, SearchOptions, Version } from "../../@types/service";
import "../../App.css";
import { AppContext, AppContextProps } from "../../context/AppContext";
import {
  deleteConceptSet,
  fetchAtlasVersion,
  fetchConceptSets,
} from "../../service/ConceptSetService";
import { filterOptions } from "../../service/FilterService";
import { fetch } from "../../service/SearchService";
import New from "./New";
import ReviewRequest from "./ReviewRequest";
import copy from 'copy-to-clipboard';
import Tags from "../../components/Tags";
import { fetchTags, fetchTagsForConceptSet, saveTagsForConceptSet } from "../../service/TagService";

export const ConceptSetsOverview = () => {
  const { doAlert, setTitle, deckClient } = useContext(
    AppContext,
  ) as AppContextProps;
  const localStoragekey = "ConceptSetsOverviewTable";
  const [rows, setRows] = useState<ConceptSet[]>([]);
  const [reviewRequestOpen, setReviewRequestOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  const [selectedSet, setSelectedSet] = useState<ConceptSet | undefined>(undefined);
  const [open, setOpen] = useState(false);
  const [availableTags, setAvailableTags] = useState<any[]>([]);
  const [conceptSetTags, setConceptSetTags] = useState<any[]>([]);
  const [tagging, setTagging] = useState(false);
  const [searchOptions, setSearchOptions] = useState<SearchOptions[]>([]);
  const [conceptSets, setConceptSets] = useState<ConceptSet[]>([]);
  const [currentConceptSetId, setCurrentConceptSetId] = useState<number | null>(null);

  let navigate = useNavigate();
  
  const [columns, setColumns] = useState([
    {
      width: 70,
      field: " ",
      align: "center",
      headerName: "",
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <IconButton
          component={Link}
          to={String(params.row.id)}
          key={params.row.id + "icon-button"}
        >
          <VisibilityIcon
            key={params.row.id + "visibility-icon"}
            style={{ color: "#004494" }}
          />
        </IconButton>
      ),
    },
    {
      width: 250,
      field: "name",
      headerName: "name",
      type: "string",
      //flex: 3,
    },
    {
      width: 250,
      field: "createdBy",
      headerName: "author",
      type: "string",
      // flex: 3,
      valueGetter: (params) => params.row.createdBy?.name ?? "N/A",
    },
    {
      field: "created",
      headerName: "created",
      type: "date",
      // flex: 1,
      valueGetter: (params) => new Date(params.row.created),
    },
    {
      field: "modified",
      headerName: "modified",
      type: "date",
      // flex: 1,
      valueGetter: (params) => new Date(params.row.modified),
    },
    {
      minWidth: 150,
      maxWidth: 150,
      field: "requestReview",
      headerName: "request review",
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <IconButton onClick={() => doReviewRequest(params.row)}>
          <AssignmentIndIcon color={"primary"} />
        </IconButton>
      ),
    },
    {
      width: 125,
      field: "tags",
      headerName: "tags",
      type: "string",
      // flex: 3,
      valueGetter: (params) => params.row.conceptSetTags?.map(row => row?.tag.name).join(', ') ?? "N/A",
      renderCell: (params) => {
        const title = params.row.conceptSetTags?.map(row => row?.tag.name).join(', ') ?? "N/A";
        return (
          <Tooltip title={title}>
            <IconButton 
              className="darwin-yellow-darker"
              onClick={() => tag(params.row.id)}
            >
              <TagIcon />
            </IconButton>
          </Tooltip>
        )
      },
    },
    {
      minWidth: 75,
      maxWidth: 75,
      field: "delete",
      headerName: "delete",
      sortable: false,
      align: "center",
      headerAlign: "center",
      filterable: false,
      renderCell: (params) => (
        <IconButton
          color={"error"}
          onClick={() => doDeleteConceptSet(params.row.id)}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ]);

  useEffect(() => {
    if (deckClient) {
      loadConceptSets();
      fetch(deckClient).then((r) => {
        setSearchOptions(r);
      });
    }
    // eslint-disable-next-line
  }, [open]);

  function loadConceptSets() {
    if (deckClient) {
      fetchConceptSets(deckClient)
        .then((r) => {
          r = r.sort((a, b) => new Date(b.modified).getTime() - new Date(a.modified).getTime());
          setRows(r);
          setConceptSets(r);
          setLoading(false);
          setTitle(<div>Concept Sets</div>);
        })
        .then(() => {
          document.querySelectorAll(".MuiDataGrid-main .MuiDataGrid-columnSeparator").forEach((o) => {
            (o as HTMLElement).classList.add("resizableColumn");
            (o as HTMLElement).onmousedown = (e) => {
              handleMouseDown(o, (e as any))
            }
          });

          const data = JSON.parse(localStorage.getItem(localStoragekey) || '{}')
          if (data) {
            for (const key in data) {
              const col = columns.map((cold) => {
                if (cold.field === key) {
                  cold.width = data[key];
                }
                return cold;
              });
              setColumns(col);
            };
          }
        })
        .catch(() => {
          doAlert("error", "Failed to load concept sets");
          setLoading(false);
        });
    }
  }

  function doReviewRequest(set: ConceptSet) {
    setSelectedSet(set);
    setReviewRequestOpen(true);
  }

  function doDeleteConceptSet(id: number) {
    if (deckClient) {
      deleteConceptSet(id, deckClient)
        .then(() => {
          doAlert("success", "Deleted");
          loadConceptSets();
        })
        .catch(() => {
          doAlert("error", "Failed to delete");
        });
    }
  }

  function exportCSV() {
    if (rows)
      return [
        ["ID", "Name", "Author", "Created", "Modified"],
        ...rows.map(({ id, name, createdBy, created, modified }) => [
          id,
          name,
          createdBy?.name,
          created,
          modified,
        ]),
      ];
    
      return false
  }
  
  const [selectedRow, setSelectedRow] = React.useState(0);
  const [contextMenu, setContextMenu] = React.useState(null as any);

  const handleContextMenu = (event) => {
    event.preventDefault();
    setSelectedRow(Number(event.currentTarget.getAttribute("data-id")));
    setContextMenu(
      contextMenu === null
        ? { mouseX: event.clientX - 2, mouseY: event.clientY - 4 }
        : null
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const handleMouseDown = (para: any, e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    let obj = JSON.parse(localStorage.getItem(localStoragekey) || "{}")
    let start = e.clientX;
    let oldWidth = 0

    document.onmousemove = (e: any) => {
      e.stopPropagation();
      if (start !== null) {
        let differenceX = 0;
        let end = start;
        end = e.clientX;
        const col = columns.map((cold) => {
          if (cold.field === para.parentElement.getAttribute("data-field")) {
            if (oldWidth === 0) {
              oldWidth = para.parentElement.clientWidth
            }
            differenceX = end - start;
            cold.width = oldWidth + differenceX;
          }
          obj = {
            ...obj,
            [cold.field]: cold.width
          }
          return cold;
        });

        setColumns(col);
      }
    };
    document.onmouseup = (e: any) => {
      e.stopPropagation();
      localStorage.setItem(localStoragekey, JSON.stringify(obj))
      document.onmousemove = (e: any) => {};
      document.onmouseup = (e: any) => {};
    };
  };

  function downloadVersion(version: any, format: "atlas" | "darwin") {
    handleClose();
    if (format === "darwin") {
      downloadJson(version, version);
    } else {
      getAtlasJson(version);
    }
  }

  function getAtlasJson(v: Version) {
    if (deckClient) {
      fetchAtlasVersion(v.id, deckClient)
        .then((r) => {
          downloadJson(v, { items: r });
        })
        .catch(() => {
          doAlert("error", "something went wrong getting atlas download");
        });
    }
  }
  
  function downloadJson(version: Version, data: object) {
    const url = window.URL.createObjectURL(new Blob([JSON.stringify(data)]));
    const fileName = `${version.name}_${version.created}.json`;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
  }

  function copyToClipboard(v: any, format: "atlas" | "darwin"): void {
    handleClose();
    if (deckClient) {
      fetchAtlasVersion(v.id, deckClient)
        .then((r) => {
          copy(JSON.stringify({ items: r}));
          doAlert(
            "success",
            `Successfully copied to clipboard 🥳`,
          );
        })
        .catch(() => {
          doAlert("error", "something went wrong getting atlas download");
        });
    }
  }

  const tag = (id: number) => {
    handleClose();
    if (deckClient) {
      setTagging(false);
      setAvailableTags([]);
      setConceptSetTags([]);
      setCurrentConceptSetId(id);
      fetchTagsForConceptSet(deckClient, id).then((res) => {
        setConceptSetTags(res);
        fetchTags(deckClient).then((res) => {
          setAvailableTags(res);
          setTagging(true);
        });
      });
    }
  }

  const save = (tags: any[], conceptSetId: number): void => {
    if (deckClient) {
      if (conceptSetId) {
        saveTagsForConceptSet(tags, conceptSetId, deckClient).then((res) =>  {
          setConceptSetTags(res);
          doAlert(
            "success", 
            `Successfully updated 🏷️`
          );
        })
      }
    }
  }

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <Tags 
        isEditable={true}
        popup={true}
        persist={(tags, id) => save(tags, id)}
        label="Enter some tags..."
        open={tagging} 
        setOpen={setTagging}
        originalValues={() => { return conceptSetTags; }}
        availableValues={() => { return availableTags; }}
        id={currentConceptSetId ?? 0}
      />
      <div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div
            style={{
              width: "30vw",
              marginTop: "auto",
              marginBottom: "auto",
              height: "100%",
            }}
          >
            <Autocomplete
              size={"small"}
              fullWidth
              disablePortal={true}
              onChange={(_event, value) => {
                if (value) {
                  const filtered = conceptSets.filter((cs) =>
                    value.ids.includes(cs.id),
                  );
                  setRows(filtered);
                } else {
                  setRows(conceptSets);
                }
              }}
              options={searchOptions}
              filterOptions={filterOptions}
              getOptionLabel={(o) => o.name}
              // placeholder="Search for a concept"
              renderInput={(params) => (
                <TextField {...params} label="Search..." />
              )}
            />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "10px",
            }}
          >
            <div className={"Buttons-wrapper__new"}>
              <Button
                variant="contained"
                size="small"
                className={"Button__new"}
                onClick={() => setOpen(true)}
              >
                NEW CONCEPT SET
              </Button>
              {open && <New open={open} setOpen={setOpen} />}
            </div>
            <div className={"Buttons-wrapper__new"}>
              <CSVLink
                className="csvLink"
                filename="concept-sets.csv"
                data={exportCSV()}
              >
                <Button
                  variant="contained"
                  size="small"
                  className={"csvLinkBtn"}
                  color="secondary"
                >
                  Export to CSV
                </Button>
              </CSVLink>
            </div>
          </div>
        </div>

        {reviewRequestOpen && selectedSet && (
          <ReviewRequest
            open={reviewRequestOpen}
            setOpen={setReviewRequestOpen}
            conceptSet={selectedSet}
          />
        )}
        <div className={"DataGrids-wrapper"}>
          <DataGrid
            onRowDoubleClick={(obj) => navigate(`${obj.row.id}`)}
            rows={rows}
            slotProps={{
              row: {
                onContextMenu: handleContextMenu,
                style: { cursor: "context-menu" },
              },
            }}
            columns={
              columns.map((column) => ({
                ...column,
                renderHeader: (params) => (
                  <>
                    <div>{params.colDef.field}</div>
                  </>
                ),
              })) as any
            }
            loading={loading}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row'
            }
          />

          <Menu
            hideBackdrop={true}
            open={contextMenu !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
              contextMenu !== null
                ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
                : undefined
            }
            componentsProps={{
              root: {
                onMouseUp: (e) => {
                  handleClose();
                },
                onContextMenu: (e) => {
                  e.preventDefault();
                  handleClose();
                },
              },
            }}
          >
            <MenuItem onClick={() => downloadVersion(rows.filter(({id}) => id === selectedRow)[0], "atlas")}>Download JSON</MenuItem>
            <MenuItem onClick={() => copyToClipboard(rows.filter(({id}) => id === selectedRow)[0], "atlas")}>Copy to clipboard</MenuItem>
            <Divider />
            <MenuItem onClick={() => tag(selectedRow)}>Tags...</MenuItem>
          </Menu>
        </div>
      </div>
    </Box>
  );
};

export default ConceptSetsOverview;
