import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

import { ClinicalDefinition, ConceptSet } from "../../../@types/service";
import DateBlock from "../../../components/DateBlock";
import EditableListOverviewField from "../../../components/EditableListOverviewField";
import EditableOverviewField from "../../../components/EditableOverviewField";
import { AppContext, AppContextProps } from "../../../context/AppContext";
import { saveClinicalDefinition, updateConceptSet, } from "../../../service/ConceptSetService";
import { fetchPhenotypes } from "../../../service/PhenotypeService";
import Tags from "../../../components/Tags";

import EditableMultiSelectList from "../../../components/EditableMultiSelectList";
import { fetchTags, fetchTagsForConceptSet, saveTagsForConceptSet } from "../../../service/TagService";

interface ExtendedClinicalDefinition extends ClinicalDefinition {
  all?: boolean;
}
export default function Overview(props: {
  id: string;
  conceptSet: ConceptSet;
  setConceptSet: Dispatch<SetStateAction<ConceptSet | undefined>>;
}) {
  
  const { doAlert, deckClient } = useContext(AppContext) as AppContextProps;
  const { id, conceptSet, setConceptSet } = props;
  const [phenotypes, setPhenotypes] = useState<ClinicalDefinition[]>([]);
  const [tagging, setTagging] = useState(false);
  const [availableTags, setAvailableTags] = useState<any[]>([]);
  const [conceptSetTags, setConceptSetTags] = useState<any[]>([]);
  const [allPhenotypes, setAllPhenotypes] = useState<ExtendedClinicalDefinition[]>([]);

  useEffect(() => {
    if (deckClient) {
      loadConceptSet(conceptSet);
      setAvailableTags([]);
      setConceptSetTags([]);
      fetchTagsForConceptSet(deckClient, parseInt(id)).then((res) => {
        setConceptSetTags(res);
        fetchTags(deckClient).then((res) => {
          setAvailableTags(res);
          setTagging(true);
        });
      });
    }
  }, [conceptSet]);

  function deepEqual(obj1, obj2) {
    if (obj1 === obj2) return true;
  
    if (typeof obj1 !== "object" || obj1 === null || typeof obj2 !== "object" || obj2 === null)
      return false;
  
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
    if (keys1.length !== keys2.length) 
      return false;
  
    for (let key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }
  
    return true;
  }

  function loadConceptSet(conceptSet: ConceptSet) {
    if (deckClient) {
      fetchPhenotypes(deckClient, conceptSet.id)
          .then((resp) => {
            setPhenotypes(resp)
          })
          .catch(() => doAlert("error", "Failed to load associated phenotypes"));

      fetchPhenotypes(deckClient)
        .then((resp) => setAllPhenotypes(resp))
        .catch(() => doAlert("error", "Failed to load all phenotypes"));
    }
  }

  function saveName(value: string | undefined) {
    saveField("name", value);
  }

  function saveDescription(value: string | undefined) {
    saveField("description", value);
  }

  function saveVocabVersion(value: string | undefined) {
    saveField("vocabularyVersion", value);
  }

  function saveFlavours(value: string[] | undefined) {
    const copy = conceptSet;
    copy.flavours = value ? value : [];
    doUpdateCall(copy, "flavours");
  }

  function saveClinicalDefinitions(value: ClinicalDefinition[] | undefined) {
    if (
      deepEqual(
        value?.sort((a, b) => {
          if (a.id === undefined && b.id === undefined) return 0;
          if (a.id === undefined) return 1;
          if (b.id === undefined) return -1;
          return a.id - b.id;
        }),
        conceptSet.clinicalDefinitions?.sort((a, b) => {
          if (a.id === undefined && b.id === undefined) return 0;
          if (a.id === undefined) return 1;
          if (b.id === undefined) return -1;
          return a.id - b.id;
        }),
      )
    ) return;

    if (value && deckClient) {
      conceptSet.clinicalDefinitions = value;
      saveClinicalDefinition(value, conceptSet, deckClient)
        .then(() => {
          loadConceptSet(conceptSet);
          doAlert("success", `Concept set successfully updated 👍`);
        })
        .catch(() =>
          doAlert("error", "Failed to updated clinical definitions for conceptset"),
        );
    }
  }

  function saveField(
    field: "name" | "description" | "vocabularyVersion",
    newValue: string | undefined,
  ) {
    const copy = conceptSet;
    copy[field] = newValue ? newValue : "";
    doUpdateCall(copy, field);
  }

  function doUpdateCall(copy: ConceptSet, field: string | ClinicalDefinition[]) {
    if (deckClient) {
      updateConceptSet(copy, deckClient)
        .then(() => {
          doAlert("success", `Updated ${field}`);
          setConceptSet(copy);
        })
        .catch(() => doAlert("error", `Failed to update ${field}`));
    }
  }

  const save = (tags: any[], conceptSetId: number): void => {
    if (deckClient) {
      if (conceptSetId) {
        saveTagsForConceptSet(tags, conceptSetId, deckClient).then((res) =>  {
          setConceptSetTags(res);
          doAlert(
            "success", 
            `Successfully updated tags 🏷️`
          );
        })
      }
    }
  }

  return (
      <div>
        <EditableOverviewField
          label={"ID"}
          originalValue={id}
          persist={() => {}}
          isEditable={false}
        />
        <EditableOverviewField
          label={"Name"}
          originalValue={conceptSet.name}
          persist={saveName}
          isEditable={conceptSet.mutable}
        />
        <EditableOverviewField
          label={"Description"}
          originalValue={conceptSet.description}
          persist={saveDescription}
          isEditable={conceptSet.mutable}
        />
        <EditableOverviewField
          label={"Vocabulary version"}
          originalValue={conceptSet.vocabularyVersion}
          persist={saveVocabVersion}
          isEditable={conceptSet.mutable}
        />
        <EditableListOverviewField
          label={"Available flavours"}
          originalValue={conceptSet.flavours}
          persist={saveFlavours}
          isEditable={conceptSet.mutable}
        />
        <EditableOverviewField
          label={"CodeListGenerator Query"}
          originalValue={JSON.stringify(conceptSet.codeListGeneratorQuery)}
          persist={() => {}}
          isEditable={false}
        />
        <EditableMultiSelectList
          label={"Clinical definitions"}
          originalValue={phenotypes}
          availableValues={allPhenotypes}
          isEditable={conceptSet.mutable}
          persist={saveClinicalDefinitions}
        />
        <Tags
          popup={false}
          isEditable={conceptSet.mutable}
          persist={(tags, id) => save(tags, id)}
          label="Tags"
          open={tagging}
          setOpen={setTagging}
          originalValues={() => { return conceptSetTags; }}
          availableValues={() => { return availableTags; }}
          id={parseInt(id)}
        />
        <div style={{ padding: "1em" }}>
          <b>Created by</b>
          <div style={{ paddingTop: "0.5em" }}>{conceptSet.createdBy.name}</div>
        </div>
        <DateBlock label={"Created on"} date={conceptSet.created} />
        <DateBlock label={"Last modified"} date={conceptSet.modified} />
      </div>
  );
}