import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { CohortDefinition } from "../../@types/service";
import ButtonWithIcon from "../../components/ButtonWithIcon";
import PageTitle from "../../components/PageTitle";
import { TabPanel, a11yProps } from "../../components/TabPanel";
import { AppContext, AppContextProps } from "../../context/AppContext";
import { fetchCohortDefinition } from "../../service/CohortDefinitionService";
import New from "./New";
import Overview from "./tabs/Overview";
import { CohortDefinitionVersions } from "./tabs/Versions";

export const RequestDetail = () => {
  const { id } = useParams();
  const cohortDefinitionId = Number(id);
  const { doAlert, setTitle, deckClient } = useContext(
    AppContext,
  ) as AppContextProps;
  const [value, setValue] = useState(0);
  const [cohortDefinition, setCohortDefinition] = useState<
    CohortDefinition | undefined
  >(undefined);
  const [creatConceptSetOpen, setCreatConceptSetOpen] = useState(false);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const client = deckClient;

  useEffect(() => {
    loadRequest();
  }, []);

  function loadRequest() {
    if (client) {
      fetchCohortDefinition(cohortDefinitionId, client)
        .then((r) => {
          setCohortDefinition(r);
          setTitle(
            <PageTitle
              parentPath={"/cohort-definitions"}
              parent={"Cohort Definitions"}
              child={r.name}
            />,
          );
        })
        .catch(() => {
          doAlert("error", "Failed to load concept sets");
        });
    }
  }

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      {cohortDefinition && (
        <div>
          <Box sx={{ width: "100%", paddingTop: 0 }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs"
              >
                <Tab label="Overview" {...a11yProps(0)} />
                <Tab label="Versions" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Overview cd={cohortDefinition} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              {cohortDefinition.versions && (
                <CohortDefinitionVersions rows={cohortDefinition.versions} />
              )}
            </TabPanel>
          </Box>
          <div
            style={{
              width: "100%",
              marginTop: "auto",
              paddingTop: "1em",
            }}
          >
            <ButtonWithIcon
              label={"ADD VERSION"}
              icon={<div />}
              onClick={() => setCreatConceptSetOpen(true)}
            />
            {creatConceptSetOpen && (
              <New
                open={creatConceptSetOpen}
                setOpen={setCreatConceptSetOpen}
                version={true}
                parentId={cohortDefinition.id}
              />
            )}
          </div>
        </div>
      )}
    </Box>
  );
};

export default RequestDetail;
