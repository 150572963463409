import TelegramIcon from "@mui/icons-material/Telegram";
import { Autocomplete } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { CohortDefinitionResult } from "../../../../@types/service";
import { AppContext, AppContextProps } from "../../../../context/AppContext";
import { saveCohortDefinitionResult } from "../../../../service/CohortDefinitionService";
import { fetchPhenotypeSettings } from "../../../../service/SettingsService";

export const CohortDefinitionResultsUpload = () => {
  const { parentId, versionId } = useParams();
  const { doAlert, deckClient } = useContext(AppContext) as AppContextProps;
  const [deadline, setDeadline] = useState<Dayjs | null>(null);
  const [review] = useState<CohortDefinitionResult>({
    database: "",
    reviewer: "",
    runDate: new Date(),
    details: new Map(),
  });
  const [fields, setFields] = useState<string[]>([]);

  let navigate = useNavigate();

  useEffect(() => {
    if (deckClient) {
      fetchPhenotypeSettings(deckClient).then((s) => {
        if (s.cdResultFields) {
          setFields(s.cdResultFields);
        }
      });
    }
  }, []);

  function updateDatabase(database: string[]) {
    review.database = database.toString();
  }

  function updateReviewer(reviewer: string) {
    review.reviewer = reviewer;
  }

  function updateDetails(key: string, value: string) {
    if (!review.details) {
      review.details = new Map();
    }
    review.details.set(key, value);
  }

  function updateRunDate(deadline: Dayjs | null) {
    if (deadline) {
      review.runDate = deadline.toDate();
      setDeadline(deadline);
    }
  }

  function submitRequest() {
    const id = Number(versionId);
    if (id && deckClient) {
      saveCohortDefinitionResult(id, review, deckClient)
        .then(() =>
          navigate(`/cohort-definitions/${parentId}/versions/${versionId}`),
        )
        .catch(() => doAlert("error", "fail..."));
    }
  }

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 3, background: "white", color: "black" }}
    >
      <h3 style={{ paddingLeft: "1em" }}>Cohort Definition Review Results</h3>
      <div style={{ padding: "1em" }}>
        <TextField
          label="Reviewer"
          required
          sx={{ width: "60vw" }}
          onChange={(e) => updateReviewer(e.target.value)}
        />
      </div>
      <div style={{ padding: "1em", display: "flex", width: "60vw" }}>
        <Autocomplete
          multiple
          sx={{ width: "40vw", marginRight: "2em" }}
          options={[
            "ACI",
            "CDW Bordeaux",
            "CPRD GOLD",
            "EBB",
            "IMASIS",
            "IPCI",
            "IQVIA DA Germany",
            "IQVIA LDP Belgium",
            "NCR",
            "SIDIAP",
          ]}
          onChange={(e, items) => {
            if (items) {
              updateDatabase([...items]);
            }
          }}
          renderInput={(params) => <TextField {...params} label="Database" />}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            label="Run date"
            value={deadline}
            onChange={(newValue) => updateRunDate(newValue)}
          />
        </LocalizationProvider>
      </div>
      <div>
        {fields.map((descriptor) => {
          return (
            <div key={descriptor + "-container"} style={{ padding: "1em" }}>
              <TextField
                multiline
                key={descriptor}
                label={descriptor}
                variant="outlined"
                sx={{ width: "60vw" }}
                onChange={(e) => updateDetails(descriptor, e.target.value)}
              />
            </div>
          );
        })}
      </div>
      <div style={{ padding: "1em", display: "flex", width: "60vw" }}>
        <div style={{ justifyContent: "flex-end", marginLeft: "auto" }}>
          <Button
            variant="contained"
            size="small"
            style={{ background: "#467A39" }}
            onClick={submitRequest}
          >
            SUBMIT&emsp;
            <TelegramIcon />
          </Button>
        </div>
      </div>
    </Box>
  );
};
