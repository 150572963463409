import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import { DataGrid } from "@mui/x-data-grid";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { ClinicalDefinition } from "../../@types/service";
import "../../App.css";
import { AppContext, AppContextProps } from "../../context/AppContext";
import { fetchPhenotypes } from "../../service/PhenotypeService";

export const PhenotypesOverview = () => {
  const { doAlert, setTitle, deckClient } = useContext(
    AppContext,
  ) as AppContextProps;
  let navigate = useNavigate();
  const localStoragekey = "ClinicalDefinitionsOverviewTable";
  const [rows, setRows] = useState<ClinicalDefinition[]>([]);
  const [loading, setLoading] = useState(true);
  const client = deckClient;
  const [columns, setColumns] = useState([
    {
      width: 70,
      field: " ",
      align: "center",
      headerName: "",
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <IconButton
          component={Link}
          to={String(params.row.id)}
          key={params.row.id + "icon-button"}
        >
          <VisibilityIcon
            key={params.row.id + "visibility-icon"}
            style={{ color: "#004494" }}
          />
        </IconButton>
      ),
    },
    {
      //flex: 2,
      minWidth: 250,
      field: "name",
      headerName: "name",
      type: "string",
    },
    {
      //flex: 1,
      minWidth: 100,
      field: "studyId",
      headerName: "study id",
      type: "string",
    },
    {
      //flex: 1,
      minWidth: 100,
      field: "deadline",
      headerName: "deadline",
      type: "date",
      valueGetter: (params) =>
        params.row.deadline
          ? new Date(params.row.deadline)
          : undefined,
    },
    {
      minWidth: 75,
      maxWidth: 75,
      field: "delete",
      headerName: "delete",
      sortable: false,
      align: "center",
      headerAlign: "center",
      filterable: false,
      renderCell: () => (
        <IconButton disabled color={"error"}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ]);

  useEffect(() => {
    if (client) {
      fetchPhenotypes(client)
        .then((r) => {
          setRows(r);
          setLoading(false);
          setTitle(<div>Clinical Definitions</div>);
        })
        .then(() => {
          document.querySelectorAll(".MuiDataGrid-main .MuiDataGrid-columnSeparator").forEach((o) => {
            (o as HTMLElement).classList.add("resizableColumn");
            (o as HTMLElement).onmousedown = (e) => {
              handleMouseDown(o, (e as any))
            }
          });

          const data = JSON.parse(localStorage.getItem(localStoragekey) || '{}')
          if (data) {
            for (const key in data) {
              const col = columns.map((cold) => {
                if (cold.field === key) {
                  cold.width = data[key];
                }
                return cold;
              });
              setColumns(col);
            };
          }
        })
        .catch(() => {
          doAlert("error", "Failed to load clinical definitions");
          setLoading(false);
        });
    }
  }, []);
  
  const handleMouseDown = (para: any, e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    let obj = JSON.parse(localStorage.getItem(localStoragekey) || "{}")
    let start = e.clientX;
    let oldWidth = 0

    document.onmousemove = (e: any) => {
      e.stopPropagation();
      if (start !== null) {
        let differenceX = 0;
        let end = start;
        end = e.clientX;
        const col = columns.map((cold) => {
          if (cold.field === para.parentElement.getAttribute("data-field")) {
            if (oldWidth === 0) {
              oldWidth = para.parentElement.clientWidth
            }
            differenceX = end - start;
            cold.width = oldWidth + differenceX;
          }
          obj = {
            ...obj,
            [cold.field]: cold.width
          }
          return cold;
        });

        setColumns(col);
      }
    };
    document.onmouseup = (e: any) => {
      e.stopPropagation();
      localStorage.setItem(localStoragekey, JSON.stringify(obj))
      document.onmousemove = (e: any) => {};
      document.onmouseup = (e: any) => {};
    };
  };

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
      <div>
        <div className={"Buttons-wrapper__new"}>
          <div>
            <Button
              variant="contained"
              size="small"
              className={"Button__new"}
              component={Link}
              to={"/clinical-definitions/new"}
            >
              NEW CLINICAL DEFINITION
            </Button>
          </div>
        </div>
        <div className={"DataGrids-wrapper"}>
          <DataGrid
            onRowDoubleClick={(obj) => navigate(`${obj.row.id}`)}
            rows={rows}
            columns={
              columns.map((column) => ({
                ...column,
                renderHeader: (params) => (
                  <>
                    <div>
                      {params.colDef.field}
                    </div>
                  </>
                ),
              })) as any
            }
            loading={loading}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row'
            }
          />
        </div>
      </div>
    </Box>
  );
};
