import { Description } from "@mui/icons-material";
import DatasetIcon from "@mui/icons-material/Dataset";
import GroupsIcon from "@mui/icons-material/Groups";
import HomeIcon from "@mui/icons-material/Home";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsIcon from "@mui/icons-material/Settings";
import { ListItemButton, ListItemIcon } from "@mui/material";
import MuiAppBar from "@mui/material/AppBar";
import { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import { CSSObject, Theme, styled } from "@mui/material/styles";
import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { PortalComponentType } from "../@types/portal-component";
import { AppContext, AppContextProps } from "../context/AppContext";
import { DrawerHeader } from "./DrawerHeader";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const PhenotypesTiles = [
  {
    title: "Home",
    link: "",
    icon: <HomeIcon />,
  },
  {
    title: "Clinical Definitions",
    link: "clinical-definitions",
    icon: <Description />,
  },
  {
    title: "Concept Sets",
    link: "concept-sets",
    icon: <DatasetIcon />,
  },
  {
    title: "Cohort Definitions",
    link: "cohort-definitions",
    icon: <GroupsIcon />,
  },
];

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  background: "white",
  color: "#004494",
  width: `calc(100% - calc(${theme.spacing(7)} + 1px))`,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    background: "white",
    color: "#004494",
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SideBar() {
  const { title, setTitle, enabledModules } = useContext(
    AppContext,
  ) as AppContextProps;
  const [open, setOpen] = useState(true);
  const [tiles, setTiles] = useState<
    { title: string; link: string; icon: ReactElement }[]
  >([]);

  useEffect(() => {
    const _tiles: { title: string; link: string; icon: ReactElement }[] = [];
    if (enabledModules.includes(PortalComponentType.PHENOTYPES)) {
      PhenotypesTiles.forEach((t) => _tiles.push(t));
    }
    _tiles.push({
      title: "Settings",
      link: "settings",
      icon: <SettingsIcon />,
    });
    _tiles.push({
      title: "FAQ",
      link: "faq",
      icon: <LiveHelpIcon />,
    });
    _tiles.push({ title: "Sign out", link: "logout", icon: <LogoutIcon /> });
    setTiles(_tiles);
  }, [enabledModules]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex", background: "white", width: "100%" }}>
      <AppBar position="fixed" open={open}>
        <Toolbar style={{ display: "flex", verticalAlign: "middle" }}>
          <div
            style={{
              justifyContent: "flex-start",
              verticalAlign: "middle",
              width: "100%",
            }}
          >
            <h3>{title}</h3>
          </div>
          <a href={"https://darwin-eu.org/"} target={"_blank"} rel="noreferrer">
            <img
              style={{ maxHeight: "44px" }}
              className={"right"}
              src={require("../img/darwin-eu-logo.png")}
              alt={"Erasmus MC logo"}
            />
          </a>
        </Toolbar>
      </AppBar>
      <Drawer
        className="sidebar"
        variant="permanent"
        open={open}
        PaperProps={{ sx: { background: "#004494", color: "#FFFFFF" } }}
      >
        <DrawerHeader className="header">
          {open && (
            <ListItem
              key={"Phenotype Manager"}
              disablePadding
              sx={{ display: "block", textAlign: "center", fontWeight: 900 }}
            >
              <ListItemText
                primary={"DECK Portal"}
                sx={{
                  opacity: open ? 1 : 0,
                  color: "white",
                  textAlign: "center",
                  fontWeight: "thin",
                }}
              />
            </ListItem>
          )}
          {false && (
            <>
              {open ? (
                <IconButton onClick={handleDrawerClose}>
                  <KeyboardDoubleArrowLeftIcon sx={{ color: "white" }} />
                </IconButton>
              ) : (
                <IconButton onClick={handleDrawerOpen}>
                  <KeyboardDoubleArrowRightIcon sx={{ color: "white" }} />
                </IconButton>
              )}
            </>
          )
        }
        </DrawerHeader>
        <List>
          {tiles.map((text) => (
            <ListItem
              component={Link}
              to={text.link}
              key={text.link}
              disablePadding
              sx={{
                display: "block",
                textDecoration: "none",
                color: "#000",
              }}
              onClick={() => setTitle(<div>{text.title}</div>)}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                    color: "var(--darwin-blue)",
                  }}
                >
                  {text.icon}
                </ListItemIcon>
                <ListItemText
                  primary={text.title}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>

        <Box
          sx={{
            display: "grid",
            width: "100%",
            color: "#00000066",
            height: "100%",
            justifyItems: "center",
            alignItems: "end",
            verticalAlign: "bottom",
            paddingBottom: "20px"
          }}
        >
          <div style={{textAlign: "center",}}>
            DECK Portal<br />
            {window._env_.VERSION === "merge" || window._env_.VERSION === "main" ? `${new Date(window._env_.BUILD_DATE).getUTCFullYear()}.${String(new Date(window._env_.BUILD_DATE).getUTCMonth() + 1).padStart(2, '0')}.${String(new Date(window._env_.BUILD_DATE).getUTCDate()).padStart(2, '0')}` : `${window._env_.VERSION}`}
          </div>
        </Box>
      </Drawer>
    </Box>
  );
}
