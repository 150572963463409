import { Box, Typography } from "@mui/material";
import React, { ReactNode } from "react";

export function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface TabPanelProps {
  children?: ReactNode | ReactNode[];
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {/* false because of (click, mousedown etc) event managemant different tabs needs to be fixed! */}
      {(index === value || false) && <Box>{children}</Box>}
    </Typography>
  );
}
