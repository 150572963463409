import * as React from "react";
import { useContext } from "react";

import { AppContext, AppContextProps } from "../context/AppContext";

export default function CheckDocsTab() {
  const { modules } = useContext(AppContext) as AppContextProps;

  const base = modules
    .filter((m) => m.name === "PHENOTYPES")[0]
    .endpoint?.replace(/\/$/, "");
  return (
    <div>
      Check the API docs <a href={base + "/swagger-ui/index.html#/"}>here</a>
    </div>
  );
}
