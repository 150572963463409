import { AxiosInstance } from "axios";

import { CodeListGeneratorQuery, Concept } from "../@types/code-list-generator";

let qs = require("qs");

export const fetchVocabVersion = async (client: AxiosInstance) => {
  return client
    .get<string[]>("/vocab/version")
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const fetchVocabularies = async (client: AxiosInstance) => {
  return client
    .get<string[]>(`/vocabularies`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const fetchDomains = async (client: AxiosInstance) => {
  return client
    .get<string[]>(`/domains`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const fetchCodes = async (
  request: CodeListGeneratorQuery,
  client: AxiosInstance,
) => {
  return client
    .get<Concept[]>(`/codes`, {
      params: {
        keywords: request.keywords,
        domains: request.domains,
        vocabularies: request.vocabs,
        exclude: request.exclude,
        synonyms: request.synonyms,
      },
      paramsSerializer: (p) => {
        return qs.stringify(p, { arrayFormat: "repeat" });
      },
    })
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};
