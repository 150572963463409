import { AxiosInstance } from "axios";

import {
  ClinicalDefinition,
  Comment,
  ConceptSet,
  ConceptSetItem,
  ExpandResponse,
  LogEntry,
  ReviewConflict,
  Task,
  Version,
} from "../@types/service";
import { CodeListGeneratorQuery } from "../@types/code-list-generator";

const base = "/concept-sets";

export const fetchConceptSets = async (
  client: AxiosInstance,
): Promise<ConceptSet[]> => {
  return client
    .get<ConceptSet[]>(base)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const fetchConceptSet = async (
  id: number,
  client: AxiosInstance,
): Promise<ConceptSet> => {
  return client
    .get<ConceptSet>(`${base}/${id}`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const deleteConceptSet = async (id: number, client: AxiosInstance) => {
  return client
    .delete<ConceptSet>(`${base}/${id}`)
    .then(() => {})
    .catch((err) => {
      throw err;
    });
};

export const deleteItem = async (
  setId: number,
  itemId: number,
  client: AxiosInstance,
) => {
  return client
    .delete<ConceptSet>(`${base}/${setId}/items/${itemId}`)
    .then(() => {})
    .catch((err) => {
      throw err;
    });
};

export const saveConceptSet = async (
  metadata: {
    version: undefined | string;
    name: string;
    description: string;
    vocabularyVersion: string;
    codeListGenerator: CodeListGeneratorQuery | undefined;
  },
  set: string,
  client: AxiosInstance,
  parent?: number,
) => {
  const path = parent ? `/${parent}/newVersion` : "";
  return client
    .post<void>(`${base}${path}`, {
      versionName: metadata.version,
      name: metadata.name,
      description: metadata.description,
      vocabularyVersion: metadata.vocabularyVersion,
      codeListGeneratorQuery: metadata.codeListGenerator,
      conceptSet: JSON.parse(set),
    })
    .then(() => {})
    .catch((err) => {
      throw err;
    });
};

export const updateConceptSet = async (
  conceptSet: ConceptSet,
  client: AxiosInstance,
) => {
  return client
    .put<void>(`${base}/${conceptSet.id}`, conceptSet)
    .then(() => {})
    .catch((err) => {
      throw err;
    });
};

export const addItemsToSet = async (
  conceptSetId: number,
  concepts: number[],
  client: AxiosInstance,
) => {
  return client
    .post<void>(`${base}/${conceptSetId}/items`, concepts)
    .then(() => {})
    .catch((err) => {
      throw err;
    });
};

export const fetchReviewComparison = async (
  masterId: number,
  ids: number[],
  client: AxiosInstance,
): Promise<ReviewConflict[]> => {
  return client
    .get<ReviewConflict[]>(`${base}/${masterId}/compare-reviews`, {
      params: { ids: ids.join(",") },
    })
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const mergeVersionIntoCurrent = async (
  masterId: number,
  versionId: number,
  client: AxiosInstance,
): Promise<void> => {
  return client
    .put(`${base}/${masterId}/versions/${versionId}/to-master`)
    .then(() => {})
    .catch((err) => {
      throw err;
    });
};

export const mergeReviewIntoCurrent = async (
  masterId: number,
  versionId: number,
  client: AxiosInstance,
): Promise<void> => {
  return client
    .put(`${base}/${masterId}/reviews/${versionId}/to-master`)
    .then(() => {})
    .catch((err) => {
      throw err;
    });
};

export const fetchConceptSetItems = async (
  id: number,
  client: AxiosInstance,
): Promise<ConceptSetItem[]> => {
  return client
    .get<ConceptSetItem[]>(`${base}/${id}/items`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const fetchConceptSetItemComments = async (
  setId: number,
  itemId: number,
  client: AxiosInstance,
): Promise<Comment[]> => {
  return client
    .get<Comment[]>(`${base}/${setId}/items/${itemId}/comments`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const postComment = async (
  setId: number,
  itemId: number,
  comment: string,
  client: AxiosInstance,
) => {
  return client
    .post<void>(`${base}/${setId}/items/${itemId}/comments`, { comment })
    .then(() => {
      // do nothing;
    })
    .catch((err) => {
      throw err;
    });
};

export const fetchVersions = async (
  setId: number,
  client: AxiosInstance,
): Promise<Version[]> => {
  return client
    .get<Version[]>(`${base}/${setId}/versions`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const persistVersion = async (
  setId: number,
  version: string,
  description: string,
  mutable: boolean,
  client: AxiosInstance,
) => {
  return client
    .post<void>(`${base}/${setId}/versions`, {
      version: version,
      description: description,
      mutable: mutable,
      id: setId,
    })
    .then(() => {
      // do nothing;
    })
    .catch((err) => {
      throw err;
    });
};

export const updateField = async (
  setId: number,
  itemId: number,
  flag: string,
  value: boolean,
  kind: "flags" | "flavours" | "tags",
  client: AxiosInstance,
) => {
  return client
    .post<void>(`${base}/${setId}/items/${itemId}/${kind}`, {
      [flag]: value,
    })
    .then(() => {
      // do nothing;
    })
    .catch((err) => {
      throw err;
    });
};

export const fetchConceptSetLogs = async (
  id: number,
  client: AxiosInstance,
): Promise<LogEntry[]> => {
  return client
    .get<LogEntry[]>(`${base}/${id}/logs`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const fetchReviews = async (
  id: number,
  client: AxiosInstance,
): Promise<{ tasks: Task[]; conflicts: Map<number, ConceptSetItem[]> }> => {
  return client
    .get<{ tasks: Task[]; conflicts: Map<number, ConceptSetItem[]> }>(
      `${base}/${id}/reviews`,
    )
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const closeReview = async (id: number, client: AxiosInstance) => {
  return client
    .post<void>(`${base}/${id}/close-review`)
    .then(() => {
      // do nothing;
    })
    .catch((err) => {
      throw err;
    });
};

export const openReview = async (id: number, client: AxiosInstance) => {
  return client
    .post<void>(`${base}/${id}/open-review`)
    .then(() => {
      // do nothing;
    })
    .catch((err) => {
      throw err;
    });
};

export const expand = async (
  id: number,
  client: AxiosInstance,
): Promise<ExpandResponse> => {
  return client
    .get<ExpandResponse>(`/concepts/${id}/expand?childlevels=10&parentlevels=0`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const fetchAtlasVersion = async (
  id: number,
  client: AxiosInstance,
): Promise<[]> => {
  return client
    .get<[]>(`${base}/${id}/atlas`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const saveClinicalDefinition = async (
  clinicalDefinitions: ClinicalDefinition[],
  conceptSet: ConceptSet,
  client: AxiosInstance,
) => {
  return client
    .post<void>(`${base}/${conceptSet.id}/save-clinical-definition`, clinicalDefinitions)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};