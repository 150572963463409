import { ContentCopy } from "@mui/icons-material";
import DownloadIcon from "@mui/icons-material/Download";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import MergeIcon from "@mui/icons-material/Merge";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  ListItemIcon,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import * as React from "react";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { Link } from "react-router-dom";

import { Version } from "../../../@types/service";
import ButtonWithIcon from "../../../components/ButtonWithIcon";
import { AppContext, AppContextProps } from "../../../context/AppContext";
import {
  fetchAtlasVersion,
  fetchVersions,
  mergeVersionIntoCurrent,
  persistVersion,
} from "../../../service/ConceptSetService";
import New from "../New";
import { CSVLink } from "react-csv";
import Button from "@mui/material/Button";

export default function Versions(props: {
  id: number;
  refreshVersions: boolean;
  setRefreshVersions: Dispatch<SetStateAction<boolean>>;
}) {
  const { doAlert, deckClient } = useContext(AppContext) as AppContextProps;
  const { id, refreshVersions, setRefreshVersions } = props;
  const [openNew, setOpenNew] = useState(false);
  const [rows, setRows] = useState<Version[]>([]);
  const [anchorEl, setAnchorEl] = useState<Map<number, null | HTMLElement>>(
    new Map(),
  );

  const handleClick = (event: React.MouseEvent<HTMLElement>, rowId: number) => {
    const _m = new Map();
    _m.set(rowId, event.currentTarget);
    setAnchorEl(_m);
  };
  const handleClose = () => {
    setAnchorEl(new Map());
  };

  useEffect(() => {
    if (deckClient) {
      fetchVersions(id, deckClient)
        .then((r) => {
          setRows(r);
        })
        .catch(() => doAlert("error", "Failed to load versions"));
    }
  }, [refreshVersions]);

  function makeCopy(toCopy: Version) {
    if (deckClient) {
      persistVersion(
        toCopy.id,
        "Copy of " + toCopy.version,
        "Copy of " + toCopy.versionDescription,
        true,
        deckClient,
      )
        .then(() => {
          setRefreshVersions(!refreshVersions);
        })
        .catch(() => doAlert("error", "Something went wrong, not your fault"));
    }
  }

  function merge(versionId: number) {
    if (deckClient) {
      mergeVersionIntoCurrent(id, versionId, deckClient)
        .then(() => {
          doAlert("success", "Successfully merged selected version into main");
          handleClose();
        })
        .catch(() => doAlert("error", "Something went wrong merging"));
    }
  }

  function getAtlasJson(v: Version) {
    if (deckClient) {
      fetchAtlasVersion(v.id, deckClient)
        .then((r) => {
          downloadJson(v, { items: r });
        })
        .catch(() => {
          doAlert("error", "something went wrong getting atlas download");
        });
    }
  }

  function downloadVersion(version: Version, format: "atlas" | "darwin") {
    if (format === "darwin") {
      downloadJson(version, version);
    } else {
      getAtlasJson(version);
    }
  }

  function downloadJson(version: Version, data: object) {
    const url = window.URL.createObjectURL(new Blob([JSON.stringify(data)]));
    const fileName = version.version + ".json";
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName); //or any other extension
    document.body.appendChild(link);
    link.click();
  }

  function exportCSV() {
    if (rows)
      return [
        ["ID", "Version", "Description", "Created By", "Created"],
        ...rows.map(({ id, version, versionDescription, createdBy, created }) => [
          id,
          version,
          versionDescription,
          createdBy,
          created,
        ]),
      ];
    
      return false
  }

  return (
    <div className={"DataGrids-wrapper DataGrids-wrapper__tab"}>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <div className={"Buttons-wrapper__new"}>
          <CSVLink className="csvLink" filename="concept-sets-versions.csv" data={exportCSV()}>
            <Button variant="contained" size="small" className={"csvLinkBtn"} color="secondary">Export to CSV</Button>
          </CSVLink>
        </div>
      </div>
      <Table
        sx={{
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "rgba(224, 224, 224, 1)",
          borderRadius: 1,
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>version</TableCell>
            <TableCell>description</TableCell>
            <TableCell>created by</TableCell>
            <TableCell>date</TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child": { border: 0 } }}
            >
              <TableCell align="center" padding={"checkbox"}>
                <IconButton
                  component={Link}
                  to={`/concept-sets/${row.id}`}
                  key={row.id + "icon-button"}
                >
                  <VisibilityIcon
                    key={row.id + "visibility-icon"}
                    style={{ color: "#004494" }}
                  />
                </IconButton>
              </TableCell>
              <TableCell align="left">{row.version}</TableCell>
              <TableCell align="left">{row.versionDescription}</TableCell>
              <TableCell align="left">{row.createdBy}</TableCell>
              <TableCell align="left">
                {new Date(row.created).toLocaleDateString()}
              </TableCell>
              <TableCell>
                <IconButton onClick={(event) => handleClick(event, row.id)}>
                  <MoreHorizIcon color={"primary"} />
                </IconButton>
                <Menu
                  anchorEl={anchorEl.get(row.id)}
                  open={Boolean(anchorEl.get(row.id))}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <MenuItem onClick={() => makeCopy(row)}>
                    <ListItemIcon>
                      <ContentCopy fontSize="small" color={"primary"} />
                    </ListItemIcon>
                    <ListItemText>Copy</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => downloadVersion(row, "darwin")}>
                    <ListItemIcon>
                      <DownloadIcon fontSize="small" color={"primary"} />
                    </ListItemIcon>
                    <ListItemText>Download Darwin</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => downloadVersion(row, "atlas")}>
                    <ListItemIcon>
                      <DownloadIcon fontSize="small" color={"primary"} />
                    </ListItemIcon>
                    <ListItemText>Download Atlas</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => merge(row.id)}>
                    <ListItemIcon>
                      <MergeIcon fontSize="small" color={"primary"} />
                    </ListItemIcon>
                    <ListItemText>Merge</ListItemText>
                  </MenuItem>
                </Menu>
              </TableCell>
              <TableCell>
                {row.mutable ? (
                  <LockOpenIcon color={"primary"} />
                ) : (
                  <LockIcon color={"primary"} />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div style={{ paddingTop: "1em", paddingBottom: "2em" }}>
        <ButtonWithIcon
          label={"ADD VERSION"}
          icon={<div />}
          onClick={() => setOpenNew(true)}
        />
      </div>
      {openNew && <New open={openNew} setOpen={setOpenNew} parent={id} />}
    </div>
  );
}
