import { InfoOutlined } from "@mui/icons-material";
import { Autocomplete, Grid, Switch, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import React, { useContext, useEffect, useState } from "react";

import {
  PortalComponent,
  PortalComponentType,
} from "../../@types/portal-component";
import { DeckSettings } from "../../@types/service";
import "../../App.css";
import { AppContext, AppContextProps } from "../../context/AppContext";
import {
  fetchPhenotypeSettings,
  fetchSettings,
  saveSettings,
  updateModule,
} from "../../service/SettingsService";

const ModuleToggle = (props: {
  component: PortalComponentType;
  enabledModules: PortalComponentType[];
  doToggle: (component: PortalComponentType) => void;
}) => {
  const { component, enabledModules, doToggle } = props;

  const tooltipText = () => {
    let text: string;
    switch (component) {
      case PortalComponentType.ATLAS: {
        text = "Import cohort definitions and concepts sets from Atlas";
        break;
      }
      case PortalComponentType.PHENOTYPES: {
        text =
          "Manage clinical definitions, cohort definitions and concept sets";
        break;
      }
      case PortalComponentType.CODELIST_GENERATOR: {
        text = "Generate concepts sets using the CodeListGenerator R package";
        break;
      }
    }
    return <div style={{ fontSize: "medium" }}>{text}</div>;
  };

  return (
    <Grid container className={"Grid Grid__indented"}>
      <Grid item xs={4}>
        <div className={"Icons-wrapper__align"}>
          <h4>{component}</h4>
          <Tooltip
            title={tooltipText()}
            style={{ fontSize: "large" }}
            placement={"top"}
          >
            <InfoOutlined
              style={{
                fontSize: 13,
                marginLeft: "0.4em",
                marginBottom: "0.6em",
              }}
            />
          </Tooltip>
        </div>
      </Grid>
      <Grid item xs={2}>
        <Switch
          checked={enabledModules.includes(component)}
          onClick={() => doToggle(component)}
        />
      </Grid>
      <Grid item xs={6} />
    </Grid>
  );
};

export const Settings = () => {
  const {
    doAlert,
    client,
    deckClient,
    enabledModules,
    setEnabledModules,
    modules,
    setModules,
  } = useContext(AppContext) as AppContextProps;
  const [settings, setSettings] = useState<DeckSettings | undefined>(undefined);
  const [initialFlavours, setInitialFlavours] = useState<string[]>([""]);
  const [newDescriptions, setNewDescriptions] = useState("");
  const [initialCdResultFields, setInitialCdResultFields] = useState<string[]>([
    "",
  ]);
  const [newCdResultFields, setNewCdResultFields] = useState<string[]>([]);
  const [newFlavours, setNewFlavours] = useState<string[]>([]);
  const [showPhenotype, setShowPhenotype] = useState(true);
  const [showAtlas, setShowAtlas] = useState(true);
  const [showCCG, setShowCCG] = useState(true);
  const [showModules, setShowModules] = useState(true);
  const phenotypeModule = modules.filter(
    (m) => m.name === PortalComponentType.PHENOTYPES,
  )[0];
  const atlasModule = modules.filter(
    (m) => m.name === PortalComponentType.ATLAS,
  )[0];
  const ccgModule = modules.filter(
    (m) => m.name === PortalComponentType.CODELIST_GENERATOR,
  )[0];
  const [phenotypeEndpoint, setPhenotypeEndpoint] = useState("");
  const [atlasEndpoint, setAtlasEndpoint] = useState("");
  const [ccgEndpoint, setCcgEndpoint] = useState("");

  useEffect(() => {
    if (deckClient) {
      fetchPhenotypeSettings(deckClient).then((s) => {
        setSettings(s);
        if (s.descriptors) {
          setNewDescriptions(s.descriptors);
        }
        if (s.defaultFlavours) {
          setNewFlavours(s.defaultFlavours);
          setInitialFlavours(s.defaultFlavours);
        }
        if (s.cdResultFields) {
          setNewCdResultFields(s.cdResultFields);
          setInitialCdResultFields(s.cdResultFields);
        }
      });
    }
  }, [deckClient]);

  function saveEndpoint(value: string, module: PortalComponent) {
    module.endpoint = value;
    updateModule(module, client)
      .then(() => {
        doAlert("success", "Saved endpoint");
        fetchSettings(client).then((r) => {
          setModules(r);
        });
      })
      .catch(() => doAlert("error", "Failed to save endpoint"));
  }

  const save = () => {
    if (settings && deckClient) {
      saveSettings(
        {
          id: settings.id,
          descriptors: newDescriptions,
          defaultFlavours: newFlavours,
          cdResultFields: newCdResultFields,
        },
        deckClient,
      )
        .then(() => doAlert("success", "Settings were successfully updated!"))
        .catch(() =>
          doAlert(
            "error",
            "Something went wrong and it was not your fault but Sicco's",
          ),
        );
    }
  };

  function togglePhenotype() {
    setShowPhenotype(!showPhenotype);
  }

  function toggleModules() {
    setShowModules(!showModules);
  }

  function toggleModule(mod: PortalComponentType) {
    if (enabledModules.includes(mod)) {
      const removed = [...enabledModules].filter((s) => s !== mod);
      setEnabledModules(removed);
    } else {
      const copy = [...enabledModules];
      copy.push(mod);
      setEnabledModules(copy);
    }
  }

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 3, background: "white", color: "black" }}
    >
      <div style={{ width: "50vw" }}>
        <hr />
        <h3 className={"unfold"} onClick={toggleModules}>
          Modules
        </h3>
        {showModules && (
          <div>
            {(
              Object.keys(PortalComponentType) as Array<PortalComponentType>
            ).map((c) => {
              return (
                <ModuleToggle
                  key={c}
                  component={c}
                  enabledModules={enabledModules}
                  doToggle={toggleModule}
                />
              );
            })}
          </div>
        )}
        {enabledModules.includes(PortalComponentType.ATLAS) && (
          <div>
            <hr />
            <h3 className={"unfold"} onClick={() => setShowAtlas(!showAtlas)}>
              Atlas
            </h3>

            {showAtlas && (
              <div style={{ paddingLeft: "1em" }}>
                At present only Atlas instances which allow unauthenticated
                requests are supported
                <TextField
                  label="WebAPI Endpoint"
                  sx={{ width: "100%", marginTop: "1em" }}
                  value={atlasEndpoint ? atlasEndpoint : atlasModule.endpoint}
                  onChange={(e) => setAtlasEndpoint(e.target.value)}
                />
                <div
                  style={{ padding: "1em", display: "flex", paddingTop: "1em" }}
                >
                  <div
                    style={{ justifyContent: "flex-end", marginLeft: "auto" }}
                  >
                    <Button
                      variant="contained"
                      size="small"
                      style={{ background: "#467A39" }}
                      onClick={() => saveEndpoint(atlasEndpoint, atlasModule)}
                    >
                      SAVE
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {enabledModules.includes(PortalComponentType.PHENOTYPES) && (
          <div>
            <hr />
            <h3 className={"unfold"} onClick={togglePhenotype}>
              Phenotypes
            </h3>
            {showPhenotype && (
              <div style={{ paddingLeft: "1em" }}>
                <TextField
                  label="API Endpoint"
                  sx={{ width: "100%", marginTop: "1em" }}
                  value={
                    phenotypeEndpoint
                      ? phenotypeEndpoint
                      : phenotypeModule.endpoint
                  }
                  onChange={(e) => setPhenotypeEndpoint(e.target.value)}
                />
                <div
                  style={{ padding: "1em", display: "flex", paddingTop: "1em" }}
                >
                  <div
                    style={{ justifyContent: "flex-end", marginLeft: "auto" }}
                  >
                    <Button
                      variant="contained"
                      size="small"
                      style={{ background: "#467A39" }}
                      onClick={() =>
                        saveEndpoint(phenotypeEndpoint, phenotypeModule)
                      }
                    >
                      SAVE
                    </Button>
                  </div>
                </div>
                <h4 style={{ paddingBottom: "0.5em" }}>Default Flavours</h4>
                <Autocomplete
                  multiple
                  options={initialFlavours}
                  freeSolo
                  value={newFlavours}
                  onChange={(e, items) => {
                    if (items) {
                      setNewFlavours([...items]);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Default Flavours" />
                  )}
                />
                <div
                  style={{ padding: "1em", display: "flex", paddingTop: "2em" }}
                >
                  <div
                    style={{ justifyContent: "flex-end", marginLeft: "auto" }}
                  >
                    <Button
                      variant="contained"
                      size="small"
                      style={{ background: "#467A39" }}
                      onClick={save}
                    >
                      SAVE
                    </Button>
                  </div>
                </div>
                <h4 style={{ paddingBottom: "0.5em" }}>
                  Clinical Description Markdown Template
                </h4>
                <TextField
                  label="Template"
                  multiline
                  sx={{ width: "100%", marginTop: "1em" }}
                  value={newDescriptions}
                  onChange={(e) => setNewDescriptions(e.target.value)}
                />
                <div
                  style={{ padding: "1em", display: "flex", paddingTop: "2em" }}
                >
                  <div
                    style={{ justifyContent: "flex-end", marginLeft: "auto" }}
                  >
                    <Button
                      variant="contained"
                      size="small"
                      style={{ background: "#467A39" }}
                      onClick={save}
                    >
                      SAVE
                    </Button>
                  </div>
                </div>
                <h4 style={{ paddingBottom: "0.5em" }}>
                  Cohort Definition Review Results Fields
                </h4>
                <Autocomplete
                  multiple
                  options={initialCdResultFields}
                  freeSolo
                  value={newCdResultFields}
                  onChange={(e, items) => {
                    if (items) {
                      setNewCdResultFields([...items]);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Fields" />
                  )}
                />
                <div
                  style={{ padding: "1em", display: "flex", paddingTop: "2em" }}
                >
                  <div
                    style={{ justifyContent: "flex-end", marginLeft: "auto" }}
                  >
                    <Button
                      variant="contained"
                      size="small"
                      style={{ background: "#467A39" }}
                      onClick={save}
                    >
                      SAVE
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {enabledModules.includes(PortalComponentType.CODELIST_GENERATOR) && (
          <div>
            <hr />
            <h3 className={"unfold"} onClick={() => setShowCCG(!showCCG)}>
              CodeListGenerator
            </h3>
            {showCCG && (
              <div style={{ paddingLeft: "1em" }}>
                <TextField
                  label="CodeListGenerator Endpoint"
                  sx={{ width: "100%", marginTop: "1em" }}
                  value={ccgEndpoint ? ccgEndpoint : ccgModule.endpoint}
                  onChange={(e) => setCcgEndpoint(e.target.value)}
                />
                <div
                  style={{ padding: "1em", display: "flex", paddingTop: "1em" }}
                >
                  <div
                    style={{ justifyContent: "flex-end", marginLeft: "auto" }}
                  >
                    <Button
                      variant="contained"
                      size="small"
                      style={{ background: "#467A39" }}
                      onClick={() => saveEndpoint(ccgEndpoint, ccgModule)}
                    >
                      SAVE
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </Box>
  );
};
