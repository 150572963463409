import { ExpandLess, ExpandMore } from "@mui/icons-material";
import * as React from "react";
import { useState } from "react";

interface JSONContentProps {
  filename?: string | undefined;
  data: Object;
}

function space(text: string) {
  if (text.includes("_")) {
    const result = text.replace(/([/_])/g, " ");
    return result.toLowerCase();
  }
  const result = text.replace(/([A-Z])/g, " $1");
  return result.toLowerCase();
}

const Display = (props: { item: Object; isOpen: boolean }) => {
  const { item, isOpen } = props;
  const [open, setOpen] = useState<boolean[]>(
    new Array(Object.entries(item).length).fill(false),
  );

  function toggle(i: number) {
    const copy = [...open];
    copy[i] = !copy[i];
    setOpen(copy);
  }

  function expandIcon(index: number) {
    return open[index] ? (
      <ExpandLess width={"24px"} onClick={() => toggle(index)} />
    ) : (
      <ExpandMore width={"24px"} onClick={() => toggle(index)} />
    );
  }

  if (isOpen) {
    if (item instanceof Array) {
      return (
        <div style={{ padding: "4px" }}>
          {item.map((item, index) => (
            <Display
              item={item}
              key={item.name + item.id + "-" + index}
              isOpen={true}
            />
          ))}
        </div>
      );
    } else if (typeof item !== "object") {
      return <div>{item}</div>;
    } else {
      return (
        <div>
          {Object.entries(item)
            .filter(
              (e) =>
                !["isExcluded", "includeMapped", "includeDescendants"].includes(
                  e[0],
                ),
            )
            .map((entry, index) => {
              return (
                <div key={entry[0] + index}>
                  <div className={"Icons-wrapper__align"}>
                    {typeof entry[1] === "object" && expandIcon(index)}
                    <div
                      style={{
                        paddingLeft: typeof entry[1] === "object" ? 0 : "24px",
                      }}
                    >
                      {entry[0] === "concept"
                        ? entry[1].CONCEPT_NAME
                        : space(entry[0]) + " : "}
                      {typeof entry[1] !== "object" && entry[1]}
                    </div>
                  </div>
                  {typeof entry[1] === "object" && (
                    <div style={{ paddingLeft: "24px" }}>
                      <Display item={entry[1]} isOpen={open[index]} />
                    </div>
                  )}
                </div>
              );
            })}
        </div>
      );
    }
  } else {
    return <div />;
  }
};

function JSONContent(props: JSONContentProps) {
  const { data, filename } = props;

  return (
    <div>
      {filename && <div style={{ paddingTop: "1em" }}>{filename}</div>}
      <div style={{ paddingTop: "1em" }}>
        <Display item={data} isOpen={true} />
      </div>
    </div>
  );
}

export default JSONContent;
