import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import React, { Dispatch, SetStateAction, useContext, useState } from "react";

import { AppContext, AppContextProps } from "../context/AppContext";
import { persistVersion } from "../service/ConceptSetService";

interface PersistVersionProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  id: number;
  name: string;
  refreshVersions: boolean;
  setRefreshVersions: Dispatch<SetStateAction<boolean>>;
}

export default function SaveVersion(props: PersistVersionProps) {
  const { doAlert, deckClient } = useContext(AppContext) as AppContextProps;
  const { setOpen, open, id, name, refreshVersions, setRefreshVersions } =
    props;
  const [version, setVersion] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [mutable, setMutable] = useState(false);
  const client = deckClient;

  const handleClose = () => {
    setRefreshVersions(!refreshVersions);
    setOpen(false);
  };

  const post = () => {
    if (version && description && client) {
      persistVersion(id, version, description, mutable, client)
        .then(() => {
          doAlert("success", `Version ${version} saved`);
          handleClose();
        })
        .catch(() => doAlert("error", "Something went wrong, not your fault"));
    } else {
      doAlert("error", "Version and description are required");
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth={"sm"}>
        <DialogTitle>Save current version of {name}</DialogTitle>
        <DialogContent>
          <TextField
            style={{ marginBottom: "1em" }}
            value={version}
            fullWidth
            required
            multiline
            label="Version"
            onChange={(e) => setVersion(e.target.value)}
          />
          <TextField
            style={{ marginBottom: "1em" }}
            value={description}
            required
            fullWidth
            multiline
            label="Description"
            onChange={(e) => setDescription(e.target.value)}
          />
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={mutable}
                  onClick={() => setMutable(!mutable)}
                />
              }
              label="Allow changes"
            />
          </FormGroup>
        </DialogContent>
        <DialogActions
          style={{ justifyContent: "space-between", paddingLeft: "1em" }}
        >
          <Button onClick={handleClose}>Close</Button>
          <Button onClick={post}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
