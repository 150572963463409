import EditIcon from "@mui/icons-material/Edit";
import { Autocomplete, Button, IconButton, Tooltip, createFilterOptions } from "@mui/material";

import React, { useEffect, useState } from "react";

import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export default function EditableMultiSelectList(props: {
  isEditable: boolean;
  label: string;
  originalValue: any[];
  availableValues: any[];
  persist: (value: any[]) => void;
}) {
  const { label, persist, originalValue, availableValues, isEditable } = props;
  const [value, setValue] = useState(originalValue);
  const [editing, setEditing] = useState(false);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  function save() {
    persist(value);
    setEditing(false);
  }

  function cancel() {
    persist(originalValue);
    setEditing(false);
  }

  return (
    <div style={{ margin: "1em" }}>
      <div className={"Icons-wrapper__align"}>
        <b style={{ minWidth: "9%" }}>{label}</b>&emsp;
        {isEditable && (
          <Tooltip title={`edit ${label.toLowerCase()}`} placement={"top"}>
            <IconButton size={"small"} onClick={() => {
              setValue(originalValue)
              setEditing(true)
            }}>
            <EditIcon fontSize={"small"} color={"primary"} />
            </IconButton>
          </Tooltip>
        )}
      </div>
      {editing ? (
        <div>
          <Autocomplete
            multiple
            disableCloseOnSelect
            style={{ width: 500 }}
            value={value}
            options={availableValues}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, val) => option.name === val.name}
            filterOptions={(options, params) => {
              const filter = createFilterOptions()
              const filtered = filter(options, params)
              const selectAllOption = { name: 'Select All...', all: true };
              return [selectAllOption , ...filtered]
            }}
            onChange={(_event, newValue) => {
              if (newValue.find(option => option.all)) {
                return setValue(value.length === availableValues.length ? [] : availableValues)
              }
              setValue([...newValue])
            }}
            renderOption={(props, option, { selected }) => {
              return (
                <li {...props} key={props.id}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={option.all ? !!(value.length === availableValues.length) : selected}
                  />
                  {option.name}
                </li>
              );
            }}
            renderInput={params => (
              <TextField
                {...params}
                variant="outlined"
                label="Clinical definitions"
                placeholder="Add clinical definitions"
                fullWidth
              />
            )}
          />
          <div>
            <Button
              variant={"outlined"}
              style={{ marginRight: "2em" }}
              onClick={save}
            >
              SAVE
            </Button>
            <Button
              style={{ margin: "1em" }}
              variant={"outlined"}
              onClick={cancel}
            >
              CANCEL
            </Button>
          </div>
        </div>
      ) : (
        <div style={{ paddingTop: "0.5em" }}>{originalValue?.map(item => item.name).join(", ")}</div>
      )}
    </div>
  );
}
