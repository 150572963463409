import AssignmentIcon from "@mui/icons-material/Assignment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  CohortDefinition,
  CohortDefinitionResult,
} from "../../../../@types/service";
import { AppContext, AppContextProps } from "../../../../context/AppContext";
import { fetchCohortDefinitionResults } from "../../../../service/CohortDefinitionService";

export default function Results(props: { cohortDefinition: CohortDefinition }) {
  const { cohortDefinition } = props;
  const { doAlert, deckClient } = useContext(AppContext) as AppContextProps;
  const [results, setResults] = useState<CohortDefinitionResult[]>([]);
  const [toShow, setToShow] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (deckClient && cohortDefinition.id) {
      fetchCohortDefinitionResults(cohortDefinition.id, deckClient)
        .then((r) => setResults(r))
        .catch(() => doAlert("error", "failed to get results"));
    }
  }, []);

  function unMap(map: Map<string, string> | undefined): [string, any][] {
    return map ? Array.from(Object.entries(map)) : [];
  }

  return (
    <div style={{ padding: "1em" }}>
      <div
        style={{
          paddingTop: "1em",
          paddingLeft: "0.5em",
          paddingBottom: "1.5em",
        }}
      >
        <Button
          variant={"outlined"}
          style={{ whiteSpace: "nowrap", textAlign: "center" }}
          component={Link}
          to={`/cohort-definitions/${cohortDefinition.parentId}/versions/${cohortDefinition.id}/results`}
        >
          <AssignmentIcon />
          &emsp;Upload Results&emsp;
        </Button>
      </div>
      <hr />
      <div className={"Icons-wrapper__align"} style={{ fontWeight: "bold" }}>
        <div style={{ width: "40px" }} />
        <div style={{ width: "20vw" }}>Database</div>
        <div style={{ width: "20vw" }}>Run date</div>
        <div>Reviewer</div>
      </div>
      {results.map((r) => (
        <div
          key={r.id + "-summary"}
          className={"Icons-wrapper__align"}
          style={{ fontWeight: r.id === toShow ? "bold" : "inherit" }}
        >
          <IconButton onClick={() => setToShow(r.id)}>
            <VisibilityIcon color={"primary"} />
          </IconButton>
          <div style={{ width: "20vw" }}>{r.database}</div>
          <div style={{ width: "20vw" }}>
            {" "}
            {new Date(r.runDate).toLocaleDateString()}
          </div>
          <div>{r.reviewer}</div>
        </div>
      ))}
      <hr />
      {results
        .filter((r) => r.id === toShow)
        .map((r) => (
          <div key={r.id + "-detail"}>
            {unMap(r.details).map((arr) => (
              <div style={{ paddingTop: "1em" }} key={arr[0] + r.id}>
                <b>{arr[0]}</b>
                <div style={{ paddingTop: "0.5em", whiteSpace: "pre-wrap" }}>
                  {arr[1]}
                </div>
              </div>
            ))}
          </div>
        ))}
    </div>
  );
}
