import AddIcon from "@mui/icons-material/Add";
import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import CommentIcon from "@mui/icons-material/Comment";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Checkbox,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  LinearProgress,
  Tooltip,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

import {
  Concept,
  ConceptSetItem,
  ExpandResponse,
} from "../../../@types/service";
import "../../../App.css";
import { AppContext, AppContextProps } from "../../../context/AppContext";
import {
  addItemsToSet,
  deleteItem,
  updateField,
  expand,
} from "../../../service/ConceptSetService";
import ConceptComment from "../tabs/ConceptComment";

const TooltipContent = (props: {
  c: Concept;
  existing: ConceptSetItem | undefined;
}) => {
  const { c, existing } = props;
  return (
    <Grid container>
      <Grid item xs={5}>
        Domain
      </Grid>
      <Grid item xs={5}>
        {c.DOMAIN_ID}
      </Grid>
      <Grid item xs={5}>
        Concept Class
      </Grid>
      <Grid item xs={5}>
        {c.CONCEPT_CLASS_ID}
      </Grid>
      <Grid item xs={2} />
      <Grid item xs={5}>
        Concept Id
      </Grid>
      <Grid item xs={5}>
        {c.CONCEPT_ID}
      </Grid>
      <Grid item xs={2} />
      <Grid item xs={5}>
        Concept Code
      </Grid>
      <Grid item xs={5}>
        {c.CONCEPT_CODE}
      </Grid>
      <Grid item xs={2} />
      {c.INVALID_REASON && (
        <Grid container>
          <Grid item xs={5}>
            Invalid Reason
          </Grid>
          <Grid item xs={5}>
            {c.INVALID_REASON}
          </Grid>
          <Grid item xs={2} />
        </Grid>
      )}
      {existing && (
        <Grid container marginTop={"8px"}>
          <Grid item xs={5}>
            excluded
          </Grid>
          <Grid item xs={5}>
            {existing.isExcluded ? "True" : "False"}
          </Grid>
          {Object.entries(existing.flavours).map((arr) => (
            <Grid container key={arr[0]}>
              <Grid item xs={5}>
                {arr[0]}
              </Grid>
              <Grid item xs={5}>
                {arr[1] ? "True" : "False"}
              </Grid>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

const Nested = (props: {
  concepts: Concept[];
  items: ConceptSetItem[];
  conceptSetId: number;
  refresh: boolean;
  setRefresh: Dispatch<SetStateAction<boolean>>;
  closed: boolean;
}) => {
  const { concepts, items, conceptSetId, refresh, setRefresh, closed } = props;
  const [open, setOpen] = useState<boolean[]>(
    new Array(concepts.length).fill(false),
  );
  const [addRow, setAddRow] = useState<boolean[]>(
    new Array(concepts.length).fill(false),
  );
  const [writingComment, setWritingComment] = useState<boolean>(false);
  const [selectedConcept, setSelectedConcept] = useState<
    ConceptSetItem | undefined
  >(undefined);
  const { doAlert, deckClient } = useContext(AppContext) as AppContextProps;

  useEffect(() => {
    setRefresh(!refresh);
  }, [writingComment]);

  function toggle(i: number) {
    const copy = [...open];
    copy[i] = !copy[i];
    setOpen(copy);
  }

  function showAddRow(i: number) {
    const copy = [...addRow];
    copy[i] = !copy[i];
    setAddRow(copy);
  }

  const createComment = (item: ConceptSetItem | undefined) => {
    if (item) {
      setSelectedConcept(item);
      setWritingComment(true);
    }
  };

  const setFlag = (item: ConceptSetItem, flavour: string, value: boolean) => {
    setField(item, flavour, value, "flags");
  };

  const setFlavour = (
    item: ConceptSetItem,
    flavour: string,
    value: boolean,
  ) => {
    setField(item, flavour, value, "flavours");
  };

  const setTag = (
    item: ConceptSetItem,
    tag: string,
    value: boolean,
  ) => {
    setField(item, tag, value, "tags");
  };

  const setField = (
    item: ConceptSetItem,
    flavour: string,
    value: boolean,
    kind: "flags" | "flavours" | "tags",
  ) => {
    if (deckClient) {
      updateField(conceptSetId, item.id, flavour, value, kind, deckClient)
        .then(() => {
          doAlert(
            "success",
            `Successfully updated ${flavour} of ${item.concept.CONCEPT_NAME} to ${value}`,
          );
        })
        .catch(() => {
          doAlert("error", `Failed to update ${item.concept.CONCEPT_NAME}`);
        });
    }
  };

  function addConceptToSet(concept: Concept) {
    if (deckClient) {
      addItemsToSet(conceptSetId, [concept.CONCEPT_ID], deckClient)
        .then(() => {
          doAlert("success", `Added ${concept.CONCEPT_NAME}`);
          setRefresh(!refresh);
        })
        .catch(() => {
          doAlert("error", `Failed to add ${concept.CONCEPT_NAME}`);
        });
    }
  }

  function removeItem(id: number | undefined) {
    if (id && deckClient) {
      deleteItem(conceptSetId, id, deckClient)
        .then(() => {
          setRefresh(!refresh);
        })
        .catch(() =>
          doAlert("error", "Something went wrong removing concept set item"),
        );
    }
  }

  return (
    <div>
      {concepts
        .sort((a, b) => {
          if (a.CONCEPT_NAME.toLowerCase() < b.CONCEPT_NAME.toLowerCase())
            return -1;
          if (a.CONCEPT_NAME.toLowerCase() > b.CONCEPT_NAME.toLowerCase())
            return 1;
          return 0;
        })
        .map((c, i) => {
          let includedItem: ConceptSetItem | undefined = undefined;
          if (items.some((i) => i.concept.CONCEPT_ID === c.CONCEPT_ID)) {
            includedItem = items.filter(
              (i) => i.concept.CONCEPT_ID === c.CONCEPT_ID,
            )[0];
          }

          return (
            <div
              style={{
                paddingLeft: "0.5em",
                color: includedItem
                  ? includedItem?.isExcluded
                    ? "gray"
                    : "black"
                  : "inherit",
              }}
              key={"item-" + c.CONCEPT_ID}
            >
              <div className={"Icons-wrapper__align"}>
                <IconButton
                  onClick={() => toggle(i)}
                  disabled={c.children === undefined}
                >
                  {c.children && c.children.length > 0 ? (
                    open[i] ? (
                      <ExpandLessIcon color={"primary"} />
                    ) : (
                      <ExpandMoreIcon color={"primary"} />
                    )
                  ) : (
                    <RemoveIcon color={"disabled"} />
                  )}
                </IconButton>
                <Tooltip
                  title={<TooltipContent c={c} existing={includedItem} />}
                  placement={"right-start"}
                >
                  <div
                    style={{
                      minWidth: "50%",
                      maxWidth: "80%",
                      color: includedItem?.isExcluded ? "gray" : "inherit",
                      fontStyle: includedItem ? "italic" : "inherit",
                    }}
                  >
                    {c.CONCEPT_NAME}
                  </div>
                </Tooltip>
                {!closed && (
                  <IconButton
                    style={{ marginLeft: "1em" }}
                    size={"small"}
                    onClick={() => showAddRow(i)}
                  >
                    {addRow[i] ? (
                      <EditIcon style={{ fontSize: 15 }} color={"primary"} />
                    ) : includedItem ? (
                      <EditIcon style={{ fontSize: 15 }} color={"primary"} />
                    ) : (
                      <AddIcon
                        style={{ fontSize: 15 }}
                        color={"success"}
                        onClick={() => {
                          addConceptToSet(c);
                        }}
                      />
                    )}
                  </IconButton>
                )}
              </div>
              {addRow[i] && (
                <div
                  className={"Icons-wrapper__align"}
                  style={{ color: "black" }}
                >
                  <div style={{ minWidth: "50%" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) => {
                            if (includedItem) {
                              setFlag(
                                includedItem,
                                "isExcluded",
                                e.target.checked,
                              );
                              includedItem.isExcluded = e.target.checked;
                            }
                          }}
                          style={{ marginLeft: "2em" }}
                          size={"small"}
                          checked={
                            includedItem ? includedItem.isExcluded : false
                          }
                        />
                      }
                      label={"exclude"}
                    />
                    {includedItem &&
                      Object.entries(includedItem.flavours).map((flavour) => (
                        <FormControlLabel
                          key={"from-control" + flavour[0]}
                          control={
                            <Checkbox
                              onChange={(e) => {
                                if (includedItem) {
                                  setFlavour(
                                    includedItem,
                                    flavour[0],
                                    e.target.checked,
                                  );
                                  includedItem.flavours[flavour[0]] =
                                    e.target.checked;
                                }
                              }}
                              size={"small"}
                              checked={flavour[1]}
                            />
                          }
                          label={flavour[0]}
                        />
                      ))}
                  </div>
                  <IconButton
                    size={"small"}
                    color={"primary"}
                    style={{ marginLeft: "1em" }}
                  >
                    {includedItem?.hasComments ? (
                      <CommentIcon
                        onClick={() => createComment(includedItem)}
                        style={{ fontSize: 19 }}
                      />
                    ) : (
                      <AddCommentOutlinedIcon
                        onClick={() => createComment(includedItem)}
                        style={{ fontSize: 19 }}
                      />
                    )}
                  </IconButton>
                  <IconButton
                    size={"small"}
                    color={"error"}
                    style={{ marginLeft: "1em" }}
                  >
                    <DeleteIcon
                      style={{ fontSize: 18 }}
                      onClick={() => {
                        removeItem(includedItem?.id);
                        includedItem = undefined;
                        showAddRow(i);
                      }}
                    />
                  </IconButton>
                </div>
              )}
              {open[i] && c.children && c.children.length > 0 ? (
                <div
                  style={{ paddingLeft: "1.5em" }}
                  key={"open-child-" + c.CONCEPT_ID}
                >
                  <Nested
                    closed={closed}
                    concepts={c.children}
                    items={items}
                    conceptSetId={conceptSetId}
                    refresh={refresh}
                    setRefresh={setRefresh}
                  />
                </div>
              ) : (
                <div />
              )}
            </div>
          );
        })}
      {writingComment && selectedConcept && (
        <ConceptComment
          open={writingComment}
          setOpen={setWritingComment}
          conceptSetId={conceptSetId}
          item={selectedConcept}
        />
      )}
    </div>
  );
};

export default function ChildrenModal(props: {
  parentConcept: ConceptSetItem;
  conceptSetId: number;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  items: ConceptSetItem[];
  refresh: boolean;
  setRefresh: Dispatch<SetStateAction<boolean>>;
  closed: boolean;
}) {
  const {
    parentConcept,
    open,
    setOpen,
    items,
    conceptSetId,
    refresh,
    setRefresh,
    closed,
  } = props;
  const [expandResp, setExpandResp] = useState<ExpandResponse | undefined>(
    undefined,
  );
  const { doAlert, deckClient } = useContext(AppContext) as AppContextProps;

  useEffect(() => {
    if (deckClient) {
      expand(parentConcept.concept.CONCEPT_ID, deckClient)
        .then((r) => {
          setExpandResp(r);
        })
        .catch(() => {
          doAlert("error", "Failed to load descendants");
          setOpen(false);
        });
    }
  }, []);

  function close() {
    setOpen(false);
  }

  return (
    <Dialog
      open={open}
      onClose={close}
      PaperProps={{ sx: { minHeight: "70vh", minWidth: "65vw" } }}
      fullWidth
      maxWidth={"sm"}
    >
      <DialogTitle>{parentConcept.concept.CONCEPT_NAME}</DialogTitle>
      <DialogContent>
        {expandResp ? (
          expandResp.count === 1 ? (
            <div
              style={{
                maxWidth: "90%",
                padding: "0.5em",
                textAlign: "justify",
              }}
            >
              {parentConcept.concept.CONCEPT_NAME} does not have any descendents
            </div>
          ) : (
            <div>
              <div
                style={{
                  maxWidth: "90%",
                  padding: "0.5em",
                  textAlign: "justify",
                }}
              >
                If descendants is checked all descendents are included in the
                concept set and inherit the parent concepts flavours. In order
                to exclude a concept it needs to be added to the concept set and
                explicitly marked as excluded. To have a different flavour for a
                concept it also needs to be added to the concept set. Concepts
                that are currently explicitly included are in <i>italic</i>,
                excluded concepts are{" "}
                <span style={{ color: "gray" }}>greyed out</span>.
              </div>
              {expandResp.concepts
                .sort((a, b) => {
                  if (
                    a.CONCEPT_NAME.toLowerCase() < b.CONCEPT_NAME.toLowerCase()
                  )
                    return -1;
                  if (
                    a.CONCEPT_NAME.toLowerCase() > b.CONCEPT_NAME.toLowerCase()
                  )
                    return 1;
                  return 0;
                })
                .map((c) => {
                  return (
                    <div key={c.CONCEPT_NAME + c.CONCEPT_ID}>
                      {c.children && c.children.length > 0 ? (
                        <Nested
                          concepts={c.children}
                          key={c.CONCEPT_NAME + c.CONCEPT_ID + "nested"}
                          items={items}
                          conceptSetId={conceptSetId}
                          refresh={refresh}
                          setRefresh={setRefresh}
                          closed={closed}
                        />
                      ) : (
                        <div style={{ paddingBottom: "1em" }} />
                      )}
                    </div>
                  );
                })}
            </div>
          )
        ) : (
          <LinearProgress />
        )}
      </DialogContent>
    </Dialog>
  );
}
