import React from "react";
import { Link } from "react-router-dom";

import "./Components.css";

interface DetailHeaderProps {
  parentPath: string;
  parent: string;
  childPath?: string;
  child: string;
  grandChild?: string;
}

function PageTitle(props: DetailHeaderProps) {
  const { parentPath, parent, childPath, child, grandChild } = props;
  return (
    <div className={"PageTitle"}>
      <Link to={parentPath} className={"PageTitle__link"}>
        {parent}
      </Link>
      <div className={"PageTitle__child"}>&rarr;</div>
      {childPath ? (
        <Link to={childPath} className={"PageTitle__link"}>
          <div className={"PageTitle__child"}>{child}</div>
        </Link>
      ) : (
        <div className={"PageTitle__child"}>{child}</div>
      )}
      {grandChild && <div className={"PageTitle__child"}>&rarr;</div>}
      {grandChild && <div className={"PageTitle__child"}>{grandChild}</div>}
    </div>
  );
}

export default PageTitle;
