import { ThemeProvider, createTheme } from "@mui/material";
import React, { useContext } from "react";
import { useAuth } from "react-oidc-context";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import AlertMessage from "./components/AlertMessage";
import { DrawerHeader } from "./components/DrawerHeader";
import { Logout } from "./components/Logout";
import SideBar from "./components/SideBar";
import { AppContext, AppContextProps } from "./context/AppContext";
import PhenotypesDetail from "./pages/clinical-definitions/Detail";
import { PhenotypesNew } from "./pages/clinical-definitions/New";
import { PhenotypesOverview } from "./pages/clinical-definitions/Overview";
import CohortDefinitionsDetail from "./pages/cohort-definitions/Detail";
import { CohortDefinitionsOverview } from "./pages/cohort-definitions/Overview";
import CohortDefinitionVersionDetail from "./pages/cohort-definitions/version/Detail";
import { CohortDefinitionResultsUpload } from "./pages/cohort-definitions/version/results/New";
import { ConceptSetsDetail } from "./pages/concept-sets/Detail";
import { ConceptSetsOverview } from "./pages/concept-sets/Overview";
import { Faq } from "./pages/faq/Faq";
import { Home } from "./pages/home/Home";
import { Settings } from "./pages/settings/Settings";

import './scss/styles.scss';

const THEME = createTheme({
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: "unset",
        },
      },
    },
  },
  shape: { borderRadius: 4 },
  typography: {
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: {
    primary: {
      main: "#004494",
    },
  },
});

function App() {
  const { message, alertOpen, closeAlert, severity } = useContext(
    AppContext,
  ) as AppContextProps;

  const auth = useAuth();

  if (auth.isLoading) {
    return <div />;
  } else if (auth.isAuthenticated) {
    return (
      <ThemeProvider theme={THEME}>
        <BrowserRouter>
          <div
            style={{
              minHeight: "100vh",
              display: "flex",
            }}
          >
            <div>
              <SideBar />
            </div>
            <div style={{ width: "95%" }}>
              <DrawerHeader />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route
                  path="/clinical-definitions"
                  element={<PhenotypesOverview />}
                />
                <Route
                  path="/clinical-definitions/:id"
                  element={<PhenotypesDetail />}
                />
                <Route
                  path="/clinical-definitions/new"
                  element={<PhenotypesNew />}
                />
                <Route path="/concept-sets" element={<ConceptSetsOverview />} />
                <Route
                  path="/concept-sets/:id"
                  element={<ConceptSetsDetail />}
                />
                <Route
                  path="/cohort-definitions"
                  element={<CohortDefinitionsOverview />}
                />
                <Route
                  path="/cohort-definitions/:id"
                  element={<CohortDefinitionsDetail />}
                />
                <Route
                  path="/cohort-definitions/:parentId/versions/:versionId"
                  element={<CohortDefinitionVersionDetail />}
                />
                <Route
                  path="/cohort-definitions/:parentId/versions/:versionId/results"
                  element={<CohortDefinitionResultsUpload />}
                />
                <Route path="/logout" element={<Logout />} />
                <Route path="/settings" element={<Settings />} />
                <Route path="/faq" element={<Faq />} />
              </Routes>
              <AlertMessage
                message={message}
                onClose={closeAlert}
                open={alertOpen}
                severity={severity}
              />
            </div>
          </div>
        </BrowserRouter>
      </ThemeProvider>
    );
  } else {
    auth
      .signinRedirect({ extraQueryParams: { domain_hint: "darwin-eu.org" } })
      .then(() => {});
  }
  return <div />;
}

export default App;
