import RemoveIcon from "@mui/icons-material/Remove";
import TelegramIcon from "@mui/icons-material/Telegram";
import { Autocomplete } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Dayjs } from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ClinicalDefinition, User } from "../../@types/service";
import LoadingButton from "../../components/LoadingButton";
import { AppContext, AppContextProps } from "../../context/AppContext";
import {
  fetchSuggestions,
  submitPhenotype,
} from "../../service/PhenotypeService";
import { fetchPhenotypeSettings } from "../../service/SettingsService";
import { fetchAllUser } from "../../service/UserService";

export const PhenotypesNew = () => {
  const { doAlert, deckClient } = useContext(AppContext) as AppContextProps;
  const [deadline, setDeadline] = useState<Dayjs | null>(null);
  const [loading, setLoading] = useState(false);

  const [pis, setPis] = useState<User[]>([{ name: "", email: "" }]);
  const [descriptors, setDescriptors] = useState<string>("");
  const [request] = useState<ClinicalDefinition>({
    name: "",
    clinicalDescriptors: descriptors,
    principalInvestigators: [],
  });
  let navigate = useNavigate();
  const [allUsers, setAllUsers] = useState<User[]>([]);
  const client = deckClient;

  useEffect(() => {
    if (client) {
      fetchPhenotypeSettings(client).then((s) => {
        setDescriptors(s.descriptors);
      });
      fetchAllUser(client).then((s) => {
        setAllUsers(s);
      });
    }
  }, []);

  function generateSuggestions() {
    if (client) {
      setLoading(true);
      fetchSuggestions(request.name, client)
        .then((gpt) => {
          setLoading(false);
          setDescriptors(gpt);
        })
        .catch(() => {
          setLoading(false);
          doAlert("error", "Failed to load suggestions");
        });
    }
  }

  function updateName(name: string) {
    request.name = name;
  }

  function updateDescription(value: string) {
    request.description = value;
  }

  function updateStudyId(id: string) {
    request.studyId = id;
  }

  function updateDeadline(deadline: Dayjs | null) {
    if (deadline) {
      request.deadline = deadline.toDate();
      setDeadline(deadline);
    }
  }

  function isRequestValid() {
    return request.name && request.principalInvestigators;
  }

  function addPIRow() {
    let _pis = [...pis];
    _pis.push({ name: "", email: "" });
    setPis(_pis);
  }

  function removeRow(index: number) {
    let _pis = [...pis];
    _pis = _pis.filter((_, i) => i !== index);
    setPis(_pis);
  }

  function setEmail(index: number, email: string) {
    setPiField("email", index, email);
  }

  function setDisplayName(index: number, name: string) {
    setPiField("name", index, name);
  }

  function setPiField(field: "name" | "email", index: number, value: string) {
    const _pis = pis.map((p, i) => {
      if (i === index) {
        p[field] = value;
      }
      return p;
    });
    setPis(_pis);
  }

  function submitRequest() {
    if (isRequestValid() && client) {
      request.clinicalDescriptors = descriptors;
      submitPhenotype(request, pis, client)
        .then(() => {
          navigate("/clinical-definitions");
        })
        .catch((err) => {
          const r = err.response;
          doAlert(
            "error",
            r.status === 400
              ? r.data.message
              : "Something went wrong, please get in touch",
          );
        });
    } else {
      doAlert("error", "Not all required fields have been entered");
    }
  }

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 3, background: "white", color: "black" }}
    >
      <h3 style={{ paddingLeft: "1em" }}>New Clinical Definition</h3>
      <div style={{ padding: "1em" }}>
        <TextField
          label="Name"
          required
          sx={{ width: "60vw" }}
          onChange={(e) => updateName(e.target.value)}
        />
      </div>
      <div style={{ padding: "1em" }}>
        <TextField
          label="Description"
          multiline
          sx={{ width: "60vw" }}
          onChange={(e) => updateDescription(e.target.value)}
        />
      </div>
      <div style={{ padding: "1em" }}>
        <TextField
          label="Study ID"
          sx={{ width: "60vw" }}
          onChange={(e) => updateStudyId(e.target.value)}
        />
      </div>
      {pis.map((pi, i) => (
        <div
          style={{ padding: "1em", display: "flex" }}
          key={pi.email + "-" + pi.name}
        >
          <Autocomplete
            options={allUsers
              .filter((u) => u.name !== undefined)
              .map((u) => u.name)}
            freeSolo
            inputMode={"text"}
            sx={{ width: "28vw", paddingRight: "3vw" }}
            value={pi.name}
            onInputChange={(e, value) => {
              pis.forEach((p, index) => {
                if (i === index) {
                  p.name = value;
                }
              });
            }}
            onChange={(e, value) => {
              if (value) {
                setDisplayName(i, value);
                const u = allUsers.filter((u) => u.name === value);
                if (u && u[0] && u[0].email) {
                  setEmail(i, u[0].email);
                }
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="Principal Investigator" />
            )}
          />
          <Autocomplete
            options={allUsers.map((u) => u.email)}
            freeSolo
            inputMode={"email"}
            sx={{ width: "28vw", paddingRight: "2vw" }}
            value={pi.email}
            onInputChange={(e, value) => {
              pis.forEach((p, index) => {
                if (i === index) {
                  p.email = value;
                }
              });
            }}
            onChange={(e, value) => {
              if (value) {
                setEmail(i, value);
                const u = allUsers.filter((u) => u.email === value);
                if (u && u[0] && u[0].name) {
                  setDisplayName(i, u[0].name);
                }
              }
            }}
            renderInput={(params) => <TextField {...params} label="PI Email" />}
          />
          {pis.length > 1 && (
            <IconButton color={"primary"} onClick={() => removeRow(i)}>
              <RemoveIcon />
            </IconButton>
          )}
        </div>
      ))}
      <div style={{ padding: "1em", display: "flex", width: "60vw" }}>
        <div>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Deadline / Milestone"
              value={deadline}
              onChange={(newValue) => updateDeadline(newValue)}
            />
          </LocalizationProvider>
        </div>
        <div style={{ justifyContent: "flex-end", marginLeft: "auto" }}>
          <Button
            size="small"
            variant={"outlined"}
            color={"primary"}
            onClick={addPIRow}
          >
            additional pi
          </Button>
        </div>
      </div>
      <div style={{ padding: "1em" }}>
        <TextField
          multiline
          label={"Clinical Description"}
          sx={{ width: "60vw" }}
          onChange={(e) => setDescriptors(e.target.value)}
          value={descriptors}
        />
      </div>
      <div style={{ padding: "1em", display: "flex", width: "60vw" }}>
        <div style={{ justifyContent: "left" }}>
          <LoadingButton
            onClick={generateSuggestions}
            loading={loading}
            label={"GENERATE SUGGESTIONS"}
          />
        </div>
        <div style={{ justifyContent: "flex-end", marginLeft: "auto" }}>
          <Button
            variant="contained"
            size="small"
            style={{ background: "#467A39" }}
            onClick={submitRequest}
          >
            SUBMIT&emsp;
            <TelegramIcon />
          </Button>
        </div>
      </div>
    </Box>
  );
};
