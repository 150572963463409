import React, { useContext } from "react";

import { ClinicalDefinition } from "../../../@types/service";
import EditableOverviewField from "../../../components/EditableOverviewField";
import { AppContext, AppContextProps } from "../../../context/AppContext";
import { updatePhenotype } from "../../../service/PhenotypeService";

export default function Overview(props: {
  phenotype: ClinicalDefinition;
  setPhenotype: (p: ClinicalDefinition) => void;
}) {
  const { phenotype, setPhenotype } = props;
  const { doAlert, deckClient } = useContext(AppContext) as AppContextProps;

  function update(ph: ClinicalDefinition) {
    if (deckClient) {
      updatePhenotype(ph, deckClient)
        .then((r) => setPhenotype(r))
        .catch((err) => doAlert("error", "failed to update phenotype"));
    }
  }

  function updateName(value: string | undefined) {
    updateField("name", value);
  }

  function updateDescription(value: string | undefined) {
    updateField("description", value);
  }

  function updateStudyID(value: string | undefined) {
    updateField("description", value);
  }

  function updateField(
    field: "name" | "description",
    value: string | undefined,
  ) {
    if (value) {
      const _p = phenotype;
      _p[field] = value;
      update(_p);
    }
  }

  return (
    <div>
      <EditableOverviewField
        label={"ID"}
        originalValue={phenotype.id ? phenotype.id : ""}
        persist={() => {}}
        isEditable={false}
      />
      <EditableOverviewField
        isEditable={true}
        label={"Name"}
        originalValue={phenotype.name}
        persist={updateName}
      />
      <EditableOverviewField
        isEditable={true}
        label={"Description"}
        originalValue={phenotype.description}
        persist={updateDescription}
      />
      <EditableOverviewField
        isEditable={true}
        label={"Study ID"}
        originalValue={phenotype.studyId}
        persist={updateStudyID}
      />
      <div style={{ margin: "1em" }}>
        <div className={"Icons-wrapper__align"}>
          <b style={{ minWidth: "9%" }}>Deadline / Milestone</b>&emsp;
        </div>
        <div style={{ paddingTop: "0.5em" }}>
          {phenotype.deadline
            ? new Date(phenotype.deadline).toLocaleDateString()
            : "NA"}
        </div>
      </div>
      <div style={{ margin: "1em" }}>
        <div className={"Icons-wrapper__align"}>
          <b style={{ minWidth: "9%" }}>Principal Investigators</b>&emsp;
        </div>
        {phenotype.principalInvestigators.map((pi) => (
          <div style={{ paddingTop: "0.5em" }} key={"pi-" + pi.email}>
            {pi.name}&emsp;{pi.email}
          </div>
        ))}
      </div>
    </div>
  );
}
