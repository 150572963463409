import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import { DataGrid } from "@mui/x-data-grid";
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";

import { CohortDefinition } from "../../../@types/service";
import "../../../App.css";
import { AppContext, AppContextProps } from "../../../context/AppContext";

export const CohortDefinitionVersions = (props: {
  rows: CohortDefinition[];
}) => {
  const { setTitle, deckClient } = useContext(AppContext) as AppContextProps;
  const { rows } = props;
  const client = deckClient;

  useEffect(() => {
    if (client) {
      setTitle(<div>Cohort Definitions</div>);
    }
  }, []);

  return (
    <div className={"DataGrids-wrapper DataGrids-wrapper__tab"}>
      <DataGrid
        rows={rows}
        getRowId={(row) => (row.id ? row.id : row.name)}
        columns={[
          {
            width: 70,
            field: "link",
            align: "center",
            headerName: "",
            sortable: false,
            filterable: false,
            renderCell: (params) => (
              <IconButton
                component={Link}
                to={`versions/${params.row.id}`}
                key={params.row.id + "icon-button"}
              >
                <VisibilityIcon
                  key={params.row.id + "visibility-icon"}
                  style={{ color: "#004494" }}
                />
              </IconButton>
            ),
          },
          {
            minWidth: 250,
            field: "name",
            headerName: "name",
            type: "string",
            flex: 3,
          },
          {
            field: "created",
            headerName: "created",
            type: "date",
            flex: 1,
            valueGetter: (params) =>
              params.row.created && new Date(params.row.created),
          },
          {
            field: "modified",
            headerName: "modified",
            type: "date",
            flex: 1,
            valueGetter: (params) =>
              params.row.modified && new Date(params.row.modified),
          },
          {
            width: 80,
            field: "delete",
            headerName: "delete",
            sortable: false,
            align: "center",
            headerAlign: "center",
            filterable: false,
            renderCell: () => (
              <IconButton disabled color={"error"}>
                <DeleteIcon />
              </IconButton>
            ),
          },
        ]}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row'
        }
      />
    </div>
  );
};
