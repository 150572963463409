import { Button } from "@mui/material";
import React, { ReactElement } from "react";

import "./Components.css";

interface ButtonWithIconProps {
  label: string;
  icon: ReactElement;
  onClick: () => void;
  marginLeft?: number | string;
}

function ButtonWithIcon(props: ButtonWithIconProps) {
  const { label, icon, onClick, marginLeft } = props;
  return (
    <Button
      variant={"outlined"}
      onClick={onClick}
      style={{
        whiteSpace: "nowrap",
        textAlign: "center",
        marginLeft: marginLeft ? marginLeft : 0,
      }}
    >
      {icon}&emsp;{label}&emsp;
    </Button>
  );
}

export default ButtonWithIcon;
