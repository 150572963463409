import { AxiosInstance } from "axios";

import { PortalComponent } from "../@types/portal-component";
import { DeckSettings } from "../@types/service";

export const fetchPhenotypeSettings = async (
  client: AxiosInstance,
): Promise<DeckSettings> => {
  return client
    .get<DeckSettings>(`/settings`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const fetchSettings = async (
  client: AxiosInstance,
): Promise<PortalComponent[]> => {
  return client
    .get<PortalComponent[]>(`/components`)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const updateModule = async (
  module: PortalComponent,
  client: AxiosInstance,
) => {
  return client
    .post<void>(`/components`, module)
    .then(() => {})
    .catch((err) => {
      throw err;
    });
};

export const saveSettings = async (
  body: DeckSettings,
  client: AxiosInstance,
) => {
  return client
    .post<void>("/settings", body)
    .then(() => {})
    .catch((err) => {
      throw err;
    });
};
