import { ExpandLess, ExpandMore } from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { ClinicalDefinition, HomePageData, Tag, Task } from "../../@types/service";
import "../../App.css";
import { AppContext, AppContextProps } from "../../context/AppContext";
import { fetchTasks, fetchTags } from "../../service/UserService";

export const Home = () => {
  const { deckClient, currentUser, doAlert } = useContext(AppContext) as AppContextProps;
  const [phenotypes, setPhenotypes] = useState<ClinicalDefinition[]>([]);
  const [tags, setTags] = useState<Tag[]>([]);
  const [showPhenotypes, setShowPhenotypes] = useState(false);
  const [showPending, setShowPending] = useState(true);
  const [showCompleted, setShowCompleted] = useState(false);
  const [showRequested, setShowRequested] = useState(false);
  const [showConceptSets, setShowConceptSets] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [openTasks, setOpenTasks] = useState<Task[]>([]);
  const [closedTasks, setClosedTasks] = useState<Task[]>([]);
  const [createdTasks, setCreatedTasks] = useState<
    Map<number, Task[]> | undefined
  >(undefined);

  useEffect(() => {
    if (deckClient) {
      fetchTasks(deckClient).then((r) => {
        setTasks(r);
      });

      fetchTags(deckClient).then((r) => {
        setTags(r);
      });
    }
  }, [deckClient]);

  function setTasks(data: HomePageData) {
    setOpenTasks(
      [...data.assignedToMe].filter(
        (t) =>
          (t.status === "OPEN" || t.status === "UNDER_REVIEW") &&
          t.assignedTo.email === currentUser.email,
      ),
    );
    setClosedTasks(
      [...data.assignedToMe].filter(
        (t) =>
          t.status === "CLOSED" && t.assignedTo.email === currentUser.email,
      ),
    );
    setCreatedTasks(data.assignedByMe);
    setPhenotypes(data.phenotypes);
  }

  function togglePhenotypes() {
    setShowPhenotypes(!showPhenotypes);
  }

  function togglePending() {
    setShowPending(!showPending);
  }

  function toggleCompleted() {
    setShowCompleted(!showCompleted);
  }

  function toggleRequested() {
    setShowRequested(!showRequested);
  }

  function toggleConceptSets() {
    setShowConceptSets(!showConceptSets);
  }

  function toggleTags() {
    setShowTags(!showTags)
  }

  const genKey = () => {
    return Math.random().toString(36).substr(2, 9);
  };
  

  return (
    <Box
      component="main"
      sx={{ flexGrow: 1, p: 3, background: "white", color: "black" }}
    >
      <h3 style={{ color: "black" }}>{currentUser.name}</h3>
      {phenotypes && phenotypes.length > 0 && (
        <div>
          <hr />
          <div className={"Icons-wrapper__align"} onClick={togglePhenotypes}>
            <IconButton>
              {showPhenotypes ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
            <h3 className={"unfold"}>My Clinical Definitions</h3>
          </div>
          {showPhenotypes &&
            phenotypes.map((p) => {
              return (
                <Grid container key={"phenotype-" + p.id} className={"Grid"}>
                  <Grid item xs={3} height={1}>
                    <IconButton
                      component={Link}
                      to={`../clinical-definitions/${p.id}`}
                      key={p.id + "icon-button"}
                    >
                      <VisibilityIcon color={"primary"} />
                    </IconButton>
                    {p.name}
                  </Grid>
                  <Grid item xs={3} height={1}>
                    Deadline:{" "}
                    {p.deadline
                      ? new Date(p.deadline).toLocaleDateString()
                      : "NA"}
                  </Grid>
                  <Grid item xs={2}>
                    {p.status}
                  </Grid>
                </Grid>
              );
            })}
        </div>
      )}
      <hr />
      <div className={"Icons-wrapper__align"} onClick={toggleConceptSets}>
        <IconButton>
          {showConceptSets ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
        <h3 className={"unfold"}>My Concept Sets</h3>
      </div>
      {showConceptSets && (
        <div>
          {openTasks && openTasks.length > 0 && (
            <div className={"div-left-indent"}>
              <hr />
              <div className={"Icons-wrapper__align"} onClick={togglePending}>
                <IconButton>
                  {showPending ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
                <h3 className={"unfold"}>Pending Reviews</h3>
              </div>
              <div>
                {showPending &&
                  openTasks.map((t) => {
                    return (
                      <div key={"open-" + t.conceptSetId + "-" + t.id}>
                        <IconButton
                          component={Link}
                          to={`../concept-sets/${t.conceptSetId}`}
                          key={t.conceptSetId + "icon-button"}
                        >
                          <FactCheckIcon color={"primary"} />
                        </IconButton>
                        {t.conceptSetName}
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
          {closedTasks && closedTasks.length > 0 && (
            <div className={"div-left-indent"}>
              <div className={"Icons-wrapper__align"} onClick={toggleCompleted}>
                <IconButton>
                  {showCompleted ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
                <h3 className={"unfold"}>Completed Reviews</h3>
              </div>
              <div>
                {showCompleted &&
                  closedTasks.map((t) => {
                    return (
                      <div key={"closed-" + t.conceptSetId}>
                        <IconButton
                          component={Link}
                          to={`../concept-sets/${t.conceptSetId}`}
                          key={t.conceptSetId + "icon-button"}
                        >
                          <VisibilityIcon color={"primary"} />
                        </IconButton>
                        {t.conceptSetName}
                      </div>
                    );
                  })}
              </div>
            </div>
          )}
          {createdTasks && Object.entries(createdTasks).length > 0 && (
            <div className={"div-left-indent"}>
              <div className={"Icons-wrapper__align"}>
                <IconButton onClick={toggleRequested}>
                  {showRequested ? <ExpandLess /> : <ExpandMore />}
                </IconButton>
                <h3 className={"unfold"} onClick={toggleRequested}>
                  Requested Reviews
                </h3>
              </div>
              <div>
                {showRequested &&
                  Object.entries(createdTasks).map(
                    (entry: [string, Task[]]) => {
                      return (
                        <div key={"requests-" + entry[0]}>
                          <div key={"requested-" + entry[0]}>
                            <IconButton
                              component={Link}
                              to={`../concept-sets/${entry[0]}`}
                              key={entry[0] + "request-icon-button"}
                            >
                              <VisibilityIcon color={"primary"} />
                            </IconButton>
                            {entry[1][0].conceptSetName}
                          </div>
                          {entry[1].map((t) => {
                            return (
                              <Grid
                                key={"entry-" + t.id}
                                container
                                spacing={2}
                                style={{ paddingTop: "1em" }}
                                className={"Grid Grid__indented"}
                              >
                                <Grid item xs={2} alignContent={"flex-start"}>
                                  {t.assignedTo.email}
                                </Grid>
                                <Grid item xs={1}>
                                  {t.status === "CLOSED" && (
                                    <CheckIcon color={"success"} />
                                  )}
                                </Grid>
                              </Grid>
                            );
                          })}
                        </div>
                      );
                    },
                  )}
              </div>
            </div>
          )}
        </div>
      )}
      {tags && tags.length > 0 && (
        <div>
          <hr />
          <div className={"Icons-wrapper__align"} onClick={toggleTags}>
            <IconButton>
              {showTags ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
            <h3 className={"unfold"}>My Tags</h3>
          </div>
          {showTags &&
            tags.map((p) => {
              return (
                <Grid container key={"tag-" + p.id  + genKey()} className={"Grid"}>
                  <Grid item xs={3} height={1}>
                    <IconButton
                      component={Link}
                      to={'#'} //{`../concept-sets/${p.id}`}
                      onClick={() => {
                          doAlert(
                            "info",
                            `This is not implemented yet 🫣`,
                          );
                          console.log(tags, phenotypes)
                        }
                      }
                      key={p.id + genKey() + "icon-button"}
                    >
                      <VisibilityIcon color={"primary"} />
                    </IconButton>
                    {p.name}
                  </Grid>
                  <Grid item xs={3} height={1}>
                    Created Date:{" "}
                    {p.created
                      ? new Date(p.created).toLocaleDateString()
                      : "NA"}
                  </Grid>
                </Grid>
              );
            })}
        </div>
      )}
      <hr />
    </Box>
  );
};
