import AddIcon from "@mui/icons-material/Add";
import { Autocomplete } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import React, { useContext, useEffect, useState } from "react";

import { CohortDefinition } from "../../../@types/service";
import { EditableConceptSetTable } from "../../../components/EditableConceptSetTable";
import { AppContext, AppContextProps } from "../../../context/AppContext";
import { fetchCohortDefinitions } from "../../../service/CohortDefinitionService";
import {
  addCohortDefinition,
  assignCohortDefinitionVersion,
  fetchCohortDefinitionsForClinicalDescription,
} from "../../../service/PhenotypeService";

export default function CohortDefinitions(props: { phenotypeId: number }) {
  const { phenotypeId } = props;
  const { doAlert, deckClient } = useContext(AppContext) as AppContextProps;
  const [cohortDefinitionOptions, setCohortDefinitionOptions] = useState<
    CohortDefinition[]
  >([]);
  const [associatedCohortDefinitions, setAssociatedCohortDefinitions] =
    useState<CohortDefinition[]>([]);
  const [newCohortDefinition, setNewCohortDefinition] =
    useState<CohortDefinition | null>(null);
  const client = deckClient;

  useEffect(() => {
    doRefreshData();
  }, []);

  function doRefreshData() {
    if (client) {
      fetchCohortDefinitionsForClinicalDescription(phenotypeId, client)
        .then((resp) => setAssociatedCohortDefinitions(resp))
        .catch(() => doAlert("error", "Failed to load cohort definitions"));
      fetchCohortDefinitions(client)
        .then((resp) => {
          const filtered = [...resp].filter(
            (c: CohortDefinition) =>
              ![...associatedCohortDefinitions]
                .map((a) => a.name)
                .includes(c.name),
          );
          setCohortDefinitionOptions(filtered);
        })
        .catch(() => doAlert("error", "Failed to load cohort definitions"));
    }
  }

  function saveVersion(oldId: number, newId: number | undefined) {
    if (client && newId) {
      assignCohortDefinitionVersion(phenotypeId, oldId, newId, client)
        .then(() => doRefreshData())
        .catch(() =>
          doAlert("error", "Failed to set cohort definitions version"),
        );
    }
  }

  function addCohortDefinitionToClinicalDescription() {
    if (newCohortDefinition && client) {
      addCohortDefinition(phenotypeId, newCohortDefinition, client)
        .then(() => {
          setNewCohortDefinition(null);
          doRefreshData();
        })
        .catch(() =>
          doAlert("error", "Failed to add cohort definition to phenotype"),
        );
    }
  }

  return (
    <div className={"DataGrids-wrapper DataGrids-wrapper__tab"}>
      <EditableConceptSetTable
        conceptSets={associatedCohortDefinitions}
        saveFunction={saveVersion}
      />
      <div className={"Icons-wrapper__align"}>
        <Autocomplete
          options={cohortDefinitionOptions}
          getOptionLabel={(option) => option?.name}
          value={newCohortDefinition}
          onChange={(e, value) => {
            setNewCohortDefinition(value);
          }}
          sx={{ width: "30vw" }}
          renderInput={(params) => (
            <TextField {...params} label="Add cohort definition set" />
          )}
        />
        <IconButton onClick={addCohortDefinitionToClinicalDescription}>
          <AddIcon color={"primary"} />
        </IconButton>
      </div>
    </div>
  );
}
