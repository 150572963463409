import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import CommentIcon from "@mui/icons-material/Comment";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  Checkbox,
  Menu,
  MenuItem,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

import { ConceptSet, ConceptSetItem } from "../../../@types/service";
import { AppContext, AppContextProps } from "../../../context/AppContext";
import {
  deleteItem,
  fetchConceptSetItems,
  updateField,
} from "../../../service/ConceptSetService";
import ChildrenModal from "./ChildrenModal";
import Comment from "./ConceptComment";
import LoadingOverlay from "../../../components/LoadingOverlay";

interface ConceptsProps {
  conceptSet: ConceptSet;
  closed: boolean;
  refresh: boolean;
  setRefresh: Dispatch<SetStateAction<boolean>>;
}

export default function Concepts(props: ConceptsProps) {
  const localStoragekey = "ConceptsOverviewTable";
  const { doAlert, deckClient } = useContext(AppContext) as AppContextProps;
  const { conceptSet, closed, refresh, setRefresh } = props;
  const [openChildrenModal, setOpenChildrenModal] = useState(false);
  const [rows, setRows] = useState<ConceptSetItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [toggle, setToggle] = useState(false);
  const [writingComment, setWritingComment] = useState<boolean>(false);
  const [selectedHeader, setSelectedHeader] = useState<GridColDef<ConceptSetItem> | undefined>(undefined);
  const [selectedType, setSelectedType] = useState<string | "flags" | "flavours">();
  const [columns, setColumns] = useState<GridColDef<ConceptSetItem>[]>([]);
  const [contextMenu, setContextMenu] = useState(null as any);
  const [selectedConcept, setSelectedConcept] = useState<
    ConceptSetItem | undefined
  >(undefined);
  const client = deckClient;

  useEffect(() => {
    loadConcepts().then(() => {
      document
        .querySelectorAll(".MuiDataGrid-main .MuiDataGrid-columnSeparator")
        .forEach((o) => {
          (o as HTMLElement).classList.add("resizableColumn");
          (o as HTMLElement).onmousedown = (e) => {
            handleMouseDown(o, e as any);
          };
        });

      const data = JSON.parse(localStorage.getItem(localStoragekey) || "{}");
      if (data) {
        for (const key in data) {
          const col = columns.map((cold) => {
            if (cold.field === key) {
              cold.width = data[key];
            }
            return cold;
          });
          setColumns(col);
        }
      }
    });
    // eslint-disable-next-line
  }, [writingComment, refresh]);

  const loadConcepts = () => {
    return (new Promise((resolve, reject) => {
      if (!client) {
        return reject(false);
      }

      setLoading(true);
      fetchConceptSetItems(conceptSet.id, client)
        .then((rowsA) => {
          setLoading(false);
          setRows(rowsA);
          resolve(true);
        })
        .catch(() => {
          setLoading(false);
          doAlert("error", "Failed to load concepts");
          reject(false);
        });
      })
    );
  };
  
  const handleMouseDown = (para: any, e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();

    let obj = JSON.parse(localStorage.getItem(localStoragekey) || "{}");
    let start = e.clientX;
    let oldWidth = 0;

    document.onmousemove = (e: any) => {
      e.stopPropagation();
      if (start !== null) {
        let differenceX = 0;
        let end = start;
        end = e.clientX;
        const col = columns.map((cold) => {
          if (cold.field === para.parentElement.getAttribute("data-field")) {
            if (oldWidth === 0) {
              oldWidth = para.parentElement.clientWidth;
            }
            differenceX = end - start;
            cold.width = oldWidth + differenceX;
          }
          obj = {
            ...obj,
            [cold.field]: cold.width,
          };
          return cold;
        });

        setColumns(col);
      }
    };
    document.onmouseup = (e: any) => {
      e.stopPropagation();
      localStorage.setItem(localStoragekey, JSON.stringify(obj));
      document.onmousemove = (e: any) => {};
      document.onmouseup = (e: any) => {};
    };
  };

  const createComment = (item: ConceptSetItem) => {
    setSelectedConcept(item);
    setWritingComment(true);
  };

  function showChildren(concept: ConceptSetItem) {
    setOpenChildrenModal(true);
    setSelectedConcept(concept);
  }

  function doDeleteItem(id: number) {
    if (deckClient) {
      deleteItem(conceptSet.id, id, deckClient)
        .then(() => {
          setRefresh(!refresh);
        })
        .catch(() =>
          doAlert("error", "Something went wrong removing concept set item"),
        );
    }
  }

  const setFlag = (item: ConceptSetItem, flavour: string, value: boolean) => {
    setField(item, flavour, value, "flags");
  };

  const setFlavour = (
    item: ConceptSetItem,
    flavour: string,
    value: boolean,
  ) => {
    setField(item, flavour, value, "flavours");
  };

  const setField = (
    item: ConceptSetItem,
    flavour: string,
    value: boolean,
    kind: "flags" | "flavours" | "tags",
  ) => {
    if (client) {
      updateField(conceptSet.id, item.id, flavour, value, kind, client)
        .then(() => {})
        .catch(() => {
          doAlert("error", `Failed to update ${item.concept.CONCEPT_NAME}`);
        });
    }
  };

  function setAll(field: string, checked: boolean) {
    let requests: any = [];
    if (!client) return;
    setToggle(true);
    for (var i = 0; i < rows.length; i++) {
      requests.push(
        client
          .post<void>(`/concept-sets/${conceptSet.id}/items/${rows[i].id}/${selectedType}`, {
            [field]: checked,
          })
          .then((res) => {
            return res;
          })
          .catch((err) => {
            return err;
          })
        )
    }

    Promise.all(requests).then(() => {
      setToggle(false);
      setTimeout(() => {
        setLoading(true);
        loadConcepts();
      }, 1000);
    });
  }

  const flavourColumns: GridColDef<ConceptSetItem>[] = conceptSet.flavours
    ? conceptSet.flavours.map((flavour: string) => {
        return {
          renderHeader: (params: any) => (
            <>
              <div>
                {params.colDef.type === "boolean" ? (
                  <div
                    onMouseUp={(e) => handleContextMenu(e, params.colDef, "flavours")}
                    className="MuiDataGrid-customCheckUncheckAll"
                  >
                    {params.colDef.field}
                  </div>
                ) : (
                  params.colDef.field
                )}
              </div>
            </>
          ),
          field: flavour,
          headerName: flavour,
          type: "boolean",
          flex: 2,
          minWidth: 125,
          maxWidth: 125,
          align: "center",
          valueGetter: (params: GridValueGetterParams<ConceptSetItem>) =>
            params.row.flavours[flavour],
          renderCell: (
            params: GridRenderCellParams<ConceptSetItem, any, any>,
          ) => (
            <Checkbox
              disabled={closed}
              defaultChecked={params.row.flavours[flavour]}
              onChange={(e) => {
                setFlavour(params.row, flavour, e.target.checked);
                rows.map((r) => {
                  if (r.id === params.row.id) {
                    r.flavours[flavour] = e.target.checked;
                  }
                  return r;
                });
              }}
            />
          ),
        };
      })
    : [];

  const fixedColumns: GridColDef<ConceptSetItem>[] = [
    {
      field: "conceptId",
      headerName: "id",
      type: "number",
      minWidth: 100,
      flex: 2,
      renderCell: (params: GridRenderCellParams<ConceptSetItem, any, any>) => (
        <div>{params.row.concept.CONCEPT_ID.toString()}</div>
      ),
      valueGetter: (params: GridValueGetterParams<ConceptSetItem>) =>
        params.row.concept.CONCEPT_ID,
    },
    {
      field: "conceptName",
      headerName: "name",
      type: "string",
      minWidth: 100,
      valueGetter: (params: GridValueGetterParams<ConceptSetItem>) =>
        params.row.concept.CONCEPT_NAME,
    },
    {
      field: "conceptDomain",
      headerName: "domain",
      type: "string",
      minWidth: 165,
      valueGetter: (params: GridValueGetterParams<ConceptSetItem>) =>
        params.row.concept.DOMAIN_ID,
    },
    {
      field: "conceptCode",
      headerName: "code",
      type: "string",
      flex: 2,
      minWidth: 135,
      valueGetter: (params: GridValueGetterParams<ConceptSetItem>) =>
        params.row.concept.CONCEPT_CODE,
    },
    {
      field: "conceptIsStandard",
      headerName: "standard",
      type: "boolean",
      flex: 2,
      minWidth: 165,
      maxWidth: 165,
      valueGetter: (params: GridValueGetterParams<ConceptSetItem>) =>
        params.row.concept.STANDARD_CONCEPT === "S",
    },
    {
      field: "isExcluded",
      headerName: "excluded",
      type: "boolean",
      flex: 2,
      minWidth: 125,
      maxWidth: 125,
      align: "center",
      renderHeader: (params: any) => (
        <>
          <div>
            {params.colDef.type === "boolean" ? (
              <div
                onMouseUp={(e) => handleContextMenu(e, params.colDef, "flags")}
                className="MuiDataGrid-customCheckUncheckAll"
              >
                {params.colDef.field}
              </div>
            ) : (
              params.colDef.field
            )}
          </div>
        </>
      ),
      renderCell: (params: GridRenderCellParams<ConceptSetItem, any, any>) => (
        <Checkbox
          disabled={closed}
          defaultChecked={params.row.isExcluded}
          onChange={(e) => {
            setFlag(params.row, params.field, e.target.checked);
            rows.map((r) => {
              if (r.id === params.row.id) {
                r.isExcluded = e.target.checked;
              }
              return r;
            });
          }}
        />
      ),
    },
    {
      field: "includeDescendants",
      headerName: "descendants",
      type: "boolean",
      flex: 2,
      minWidth: 165,
      maxWidth: 165,
      align: "center",
      renderHeader: (params: any) => (
        <>
          <div>
            {params.colDef.type === "boolean" ? (
              <div
                onMouseUp={(e) => handleContextMenu(e, params.colDef, "flags")}
                className="MuiDataGrid-customCheckUncheckAll"
              >
                {params.colDef.field}
              </div>
            ) : (
              params.colDef.field
            )}
          </div>
        </>
      ),
      renderCell: (params: GridRenderCellParams<ConceptSetItem, any, any>) => (
        <div>
          <Checkbox
            disabled={closed}
            defaultChecked={params.row.includeDescendants}
            onChange={(e) => {
              setFlag(params.row, params.field, e.target.checked);
              rows.map((r) => {
                if (r.id === params.row.id) {
                  r.includeDescendants = e.target.checked;
                }
                return r;
              });
            }}
          />
          <IconButton
            disabled={closed && !params.row.includeDescendants}
            size={"small"}
            style={{ marginLeft: "0.3em" }}
            onClick={() => showChildren(params.row)}
          >
            <VisibilityIcon
              visibility={
                closed && !params.row.includeDescendants ? "hidden" : "visible"
              }
              color={"primary"}
              style={{ fontSize: 18 }}
            />
          </IconButton>
        </div>
      ),
    },
    {
      field: "includeMapped",
      headerName: "mapped",
      type: "boolean",
      flex: 2,
      minWidth: 135,
      maxWidth: 135,
    },
    {
      field: "comments",
      headerName: "comments",
      type: "boolean",
      flex: 2,
      align: "center",
      minWidth: 85,
      maxWidth: 85,
      renderCell: (params: GridRenderCellParams<ConceptSetItem, any, any>) => {
        return (
          <IconButton onClick={() => createComment(params.row)}>
            {params.row.hasComments ? (
              <CommentIcon color={"primary"} />
            ) : (
              <AddCommentOutlinedIcon color={closed ? "disabled" : "primary"} />
            )}
          </IconButton>
        );
      },
    },
    {
      minWidth: 85,
      maxWidth: 85,
      field: "delete",
      headerName: "delete",
      sortable: false,
      align: "center",
      headerAlign: "center",
      filterable: false,
      renderCell: (params: GridRenderCellParams<ConceptSetItem, any, any>) => (
        <IconButton
          disabled={closed}
          onClick={() => doDeleteItem(params.row.id)}
        >
          <DeleteIcon color={closed ? "disabled" : "error"} />
        </IconButton>
      ),
    },
  ];

  const handleContextMenu = (event, colDef: GridColDef<ConceptSetItem>, type: "flags" | "flavours") => {
    event.preventDefault();
    setSelectedHeader(colDef);
    setSelectedType(type);
    if (event.button === 2) {
      setContextMenu(
        contextMenu === null
          ? {
              mouseX: event.clientX - 2,
              mouseY: event.clientY - 4,
              colDef: colDef,
            }
          : null,
      );
    }
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const toggleCheckedAll = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, checked: boolean) => {
    if (selectedHeader?.field) {
      setAll(selectedHeader.field, checked)
    }
  };

  if (columns.length === 0) {
    setColumns(() => {
      flavourColumns.forEach((c, i) => fixedColumns.splice(3 + i, 0, c));
      return fixedColumns;
    });
  }

  return (
    <>
      <LoadingOverlay isLoading={toggle} />
      <div className={"DataGrids-wrapper DataGrids-wrapper__tab"}>
        <DataGrid
          loading={loading}
          initialState={{
            columns: {
              columnVisibilityModel: {
                // Hide columns status and traderName, the other columns will remain visible
                conceptId: false,
                conceptIsStandard: false,
                conceptCode: false,
                includeMapped: false,
                isExcluded: conceptSet.type !== "REVIEW_COPY",
                includeDescendants: conceptSet.type !== "REVIEW_COPY",
                delete: !closed && conceptSet.type !== "REVIEW_COPY",
              },
            },
            sorting: {
              sortModel: [{ field: "conceptDomain", sort: "asc" }],
            },
          }}
          rows={rows}
          columns={
            columns.map((column) => ({
              ...column,
            })) as any
          }
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'even-row' : 'odd-row'
          }
        />

        <Menu
          hideBackdrop={true}
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
          componentsProps={{
            root: {
              onMouseUp: (e) => {
                if (e.currentTarget.classList.contains("MuiModal-root"))
                  handleClose();
              },
              onContextMenu: (e) => {
                e.preventDefault();
              },
            },
          }}
        >
          <MenuItem onClick={(e) => toggleCheckedAll(e, true)}>Check all</MenuItem>
          <MenuItem onClick={(e) => toggleCheckedAll(e, false)}>Uncheck all</MenuItem>
        </Menu>

        {writingComment && selectedConcept && (
          <Comment
            open={writingComment}
            setOpen={setWritingComment}
            conceptSetId={conceptSet.id}
            item={selectedConcept}
          />
        )}
        {openChildrenModal && selectedConcept && (
          <ChildrenModal
            items={rows}
            parentConcept={selectedConcept}
            conceptSetId={conceptSet.id}
            open={openChildrenModal}
            setOpen={setOpenChildrenModal}
            refresh={refresh}
            setRefresh={setRefresh}
            closed={closed}
          />
        )}
      </div>
    </>
  );
}
