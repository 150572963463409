import { colors, Typography } from '@mui/material';
import React, {useCallback, useMemo} from 'react';
import {useDropzone} from 'react-dropzone';
import { AiOutlineCloudUpload } from "react-icons/ai"

import "./styles/FileDrop.scss"

interface Props {

}

export const FileDrop = props => {
  const { setFieldValue } = props;
  const baseStyle = {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "20px",
    borderWidth: 2,
    borderRadius: 5,
    borderColor: "#999",
    borderStyle: "dashed",
    backgroundColor: "#00000010",
    color: "orange",
    outline: "none",
    transition: "border .24s ease-in-out",
  }
  
  const focusedStyle = {
    borderColor: "#000",
  }
  
  const acceptStyle = {
    borderColor: "#00AA00",
  }
  
  const rejectStyle = {
    borderColor: "#AA0000",
  }
  
  const onDrop = useCallback(acceptedFiles => {
    setFieldValue(
      "uploads",
      acceptedFiles
    )
  }, []);

  const setFiles = () => {
    return acceptedFiles
  }

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({onDrop: onDrop, accept:  {'application/json': ['.json'] }, multiple: false});

  const style: any = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);


  const files = acceptedFiles.map((file): any => (
    <li key={file['path']}>
      {file['path']} - {file.size} bytes
    </li>
  ));

  

  return (
    <div className="dropzone-container">
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <AiOutlineCloudUpload size={96} />
        <span style={{color: "black"}}>Drag 'n' drop some files here, or click to select files...</span>
        {props.errors && 
          <Typography
            variant="inherit"
            color={colors.red[500]}
            fontSize={16}
            fontWeight={"bold"}
            style={{ textDecoration: "underline" }}
          >
            {props.errors.file}
          </Typography>
        }
      </div>
      {files.length > 0 && (
      <aside>
        <span className='label'>File(s)</span>
        <ul>{files}</ul>
      </aside>)}
    </div >
  );
}